import clsx from 'clsx';
import { StyleType } from 'components/universal/button';
import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import cls from './../button/button.module.scss';

interface IProps extends LinkProps {
  styleType?: StyleType;
  className?: string;
}

export const CustomLink: FC<IProps> = ({
  styleType,
  className,
  children,
  ...props
}) => {
  const clsButton = clsx(cls.button, {
    [cls.buttonSecondary]: styleType === 'secondary',
    [cls.buttonTertiary]: styleType === 'tertiary',
    [cls.buttonInverted]: styleType === 'inverted',
    [className]: Boolean(className),
  });

  return (
    <Link {...props} className={clsButton}>
      {children}
    </Link>
  );
};

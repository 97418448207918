import React from 'react';
import cls from './about.module.scss';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import { CustomLink } from 'components/universal/custom-link';
import { PATHS } from 'constants/routes';
import { VideoEmbed } from 'components/universal/video-embed';
import { THUMBNAILS, VIDEOS } from 'constants/external-urls';
import { useTranslation } from 'react-i18next';
import { getMediaLanguage } from 'utils/language';

export const AboutSection = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className={cls.wrapper}>
      <div className={cls.container}>
        <div className={cls.videoWrapper}>
          <VideoEmbed
            src={VIDEOS[getMediaLanguage(i18n.language)].HOME_ABOUT_ACTION}
            controls
            poster={
              THUMBNAILS[getMediaLanguage(i18n.language)].HOME_ABOUT_ACTION
            }
          />
        </div>
        <div className={cls.content}>
          <Heading headingLevel="h2" isWhite className={cls.header}>
            {t('home.aboutAction.title')}
          </Heading>
          <Paragraph className={cls.text}>
            {t('home.aboutAction.text')}
          </Paragraph>
          <Paragraph className={cls.text}>
            <b>{t('home.aboutAction.textBold')}</b>
          </Paragraph>
          <CustomLink to={PATHS.ABOUT_ACTION} className={cls.btn}>
            {t('home.aboutAction.button')}
          </CustomLink>
        </div>
      </div>
    </div>
  );
};

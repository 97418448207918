import React from 'react';
import cls from './numbers.module.scss';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import { CustomLink } from 'components/universal/custom-link';
import { PATHS } from 'constants/routes';
import { useTranslation } from 'react-i18next';

export const NumbersSection = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.container}>
      <div className={cls.content}>
        <Heading headingLevel="h2" className={cls.header}>
          {t('home.numbers.title')}
        </Heading>
        <div className={cls.stats}>
          <div className={cls.entry}>
            <Paragraph className={cls.entryStat}>
              {t('home.numbers.hospitalInfectionsStat')}
            </Paragraph>
            <Paragraph className={cls.entryText}>
              {t('home.numbers.hospitalInfectionsText')}
            </Paragraph>
          </div>
          <div className={cls.middleEntry}>
            <Paragraph className={cls.entryStat}>
              {t('home.numbers.yearlyInfectionsStat')}
            </Paragraph>
            <Paragraph className={cls.entryText}>
              {t('home.numbers.yearlyInfectionsText')}
            </Paragraph>
          </div>
          <div className={cls.entry}>
            <Paragraph className={cls.entryStat}>
              {t('home.numbers.fatalCasesStat')}
            </Paragraph>
            <Paragraph className={cls.entryText}>
              {t('home.numbers.fatalCasesText')}
            </Paragraph>
          </div>
        </div>
        <CustomLink
          to={PATHS.REGISTRATION}
          className={cls.btn}
          styleType="inverted"
        >
          {t('home.numbers.button')}
        </CustomLink>
      </div>
    </div>
  );
};

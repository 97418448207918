/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import React, { FC, InputHTMLAttributes } from 'react';
import { UseFormRegister } from 'react-hook-form';
import cls from './textarea.module.scss';
interface IProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  error?: string;
  register: UseFormRegister<any>;
  className?: string;
}

export const Textarea: FC<IProps> = ({
  label,
  id,
  error,
  register,
  name,
  className,
  ...props
}) => {
  const clsInputWrapper = clsx(cls.inputWrapper, {
    [cls.inputWrapperError]: error,
    [className]: Boolean(className),
  });

  return (
    <div className={clsInputWrapper}>
      {label && (
        <label htmlFor={id} className={cls.label}>
          {label}
        </label>
      )}
      <textarea {...register(name)} className={cls.input} id={id} {...props} />
      {error && <p className={cls.errorBox}>{error}</p>}
    </div>
  );
};

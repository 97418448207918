import React from 'react';
import { useTranslation } from 'react-i18next';

export const InfoClause = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'submitYourOrder.accordion',
  });
  return (
    <p>
      {t('p1')}
      <br />
      <br />
      {t('p2')}
      <br />
      <br />
      {t('p3')}
      <br />
      <br />
      {t('p4')}
      <br />
      <br />
      {t('p5')}
      <br />
      <br />
      {t('p6')}
      <br />
      <br />
      {t('p7')}
      <br />
      <br />
      {t('p8')}
      <br />
      <br />
      {t('p9')}
      <br />
      <br />
      {t('p10')}
      <br />
      <br />
      {t('p11')}
      <br />
      <br />
      {t('p12')}
      <br />
      <br />
      {t('p13')}
      <br />
      <br />
      {t('p14')}
      <br />
      <br />
      {t('p15')}
      <br />
      <br />
      {t('p16')}
      <br />
      <br />
      {t('p17')}
      <br />
      <br />
      {t('p18')}
      <br />
      {t('p19')}
      <br />
      {t('p20')}{' '}
      <a href="mailto:aktualnosci@ecolab.com">aktualnosci@ecolab.com</a>
    </p>
  );
};

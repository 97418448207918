import { AxiosError, AxiosResponse } from 'axios';
import { Button } from 'components/universal/button';
import Paragraph from 'components/universal/paragraph';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { submitCertificateCourseForm } from 'services/certificate';
import { APIErrors } from 'types/api';
import cls from './certificate.module.scss';

export const Certificate = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'elearning.handHygiene.courseLayout.overview',
  });
  const downloadCert = () => certificateMutation.mutate();

  const certificateMutation = useMutation<
    AxiosResponse<string>,
    AxiosError<APIErrors>
  >(() => submitCertificateCourseForm(), {
    onSuccess: async ({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'certyfikat.pdf');
      link.click();
      toast.success(t('downloadCertificateSuccess'));
    },
    onError: async (err) => {
      toast.error(err.response.data.message);
    },
  });

  return (
    <div className={cls.certificate}>
      <Paragraph className={cls.text} fontWeight={700}>
        {t('claimedCertificate')}
      </Paragraph>
      <Button className={cls.btn} onClick={downloadCert}>
        {t('downloadCertificate')}
      </Button>
    </div>
  );
};

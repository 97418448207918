import React, { ButtonHTMLAttributes, FC } from 'react';
import cls from './button.module.scss';
import clsx from 'clsx';

export type StyleType = 'secondary' | 'tertiary' | 'inverted';
interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: StyleType;
  className?: string;
}

export const Button: FC<IProps> = ({
  styleType,
  className,
  children,
  ...props
}) => {
  const clsButton = clsx(cls.button, {
    [cls.buttonSecondary]: styleType === 'secondary',
    [cls.buttonTertiary]: styleType === 'tertiary',
    [cls.buttonInverted]: styleType === 'inverted',
    [className]: Boolean(className),
  });

  return (
    <button {...props} className={clsButton}>
      {children}
    </button>
  );
};

import { PATHS } from '../../constants/routes';

export const QUIZ_POINTS_REPLACEMENT = 'FINAL_RESULT';

export type QuizQuestions = {
  [key: string]: {
    question: string;
    answers: string[];
    correct: string;
  };
};

export type QuizData = {
  questions: QuizQuestions;
  results: {
    [key: string]: string;
  };
  button: {
    text: string;
    link: string;
  };
};

export const HYGIENE_QUIZ: QuizData = {
  questions: {
    one: {
      question: 'home.quiz.questions.one',
      answers: [
        'home.quiz.answers.one.one',
        'home.quiz.answers.one.two',
        'home.quiz.answers.one.three',
      ],
      correct: 'home.quiz.answers.one.two',
    },
    two: {
      question: 'home.quiz.questions.two',
      answers: [
        'home.quiz.answers.two.one',
        'home.quiz.answers.two.two',
        'home.quiz.answers.two.three',
      ],
      correct: 'home.quiz.answers.two.one',
    },
    three: {
      question: 'home.quiz.questions.three',
      answers: [
        'home.quiz.answers.three.one',
        'home.quiz.answers.three.two',
        'home.quiz.answers.three.three',
      ],
      correct: 'home.quiz.answers.three.two',
    },
    four: {
      question: 'home.quiz.questions.four',
      answers: [
        'home.quiz.answers.four.one',
        'home.quiz.answers.four.two',
        'home.quiz.answers.four.three',
      ],
      correct: 'home.quiz.answers.four.three',
    },
    five: {
      question: 'home.quiz.questions.five',
      answers: [
        'home.quiz.answers.five.one',
        'home.quiz.answers.five.two',
        'home.quiz.answers.five.three',
      ],
      correct: 'home.quiz.answers.five.three',
    },
  },
  results: {
    '0': 'home.quiz.results.zero',
    '1': 'home.quiz.results.one',
    '2': 'home.quiz.results.two',
    '3': 'home.quiz.results.three',
    '4': 'home.quiz.results.four',
    '5': 'home.quiz.results.five',
  },
  button: {
    text: 'home.quiz.button',
    link: PATHS.REGISTRATION,
  },
};

import { ReactComponent as ArrowIcon } from 'assets/images/navigation/user-menu-arrow.svg';
import clsx from 'clsx';
import { PATHS } from 'constants/routes';
import PrivateComponent from 'features/private-component';
import { useUser } from 'hooks/useUser';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../../button';
import cls from './user-menu.module.scss';

interface IProps {
  className?: string;
}

export const UserMenu: FC<IProps> = ({ className }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { logoutUser } = useUser();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const clsContiner = clsx(cls.continer, 'menu-toggle', {
    [className]: Boolean(className),
  });
  const clsArrowIcon = clsx(cls.arrowIcon, 'menu-toggle', {
    [cls.arrowIconRotate]: isMenuOpen,
  });
  const clsBtnText = clsx(cls.buttonText, 'menu-toggle');
  const clsList = clsx(cls.list, 'menu-toggle', {
    [cls.listOpen]: isMenuOpen,
  });
  const clsListItem = clsx(cls.listItem, 'menu-toggle');
  const clsListItemPadding = clsx(cls.listItemPadding, 'menu-toggle');

  const toogleMenu = () => setIsMenuOpen((prevState) => !prevState);

  useEffect(() => {
    const toggle = (e: MouseEvent) => {
      const el = e.target as HTMLElement;
      const tagName = el.tagName;
      if (tagName === 'path') {
        const parent = el.parentElement;
        if (parent.classList.contains('menu-toggle')) return;
      }
      if (el.classList.contains('menu-toggle')) return;
      setIsMenuOpen(false);
    };
    window.addEventListener('click', toggle);
    return () => {
      window.removeEventListener('click', toggle);
    };
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [pathname]);

  return (
    <div className={clsContiner} role="user-menu">
      <Button onClick={toogleMenu} className="menu-toggle">
        <span className={clsBtnText}>{t('navigation.userMenu.myAccount')}</span>{' '}
        <ArrowIcon className={clsArrowIcon} />
      </Button>
      <ul className={clsList}>
        <PrivateComponent permission="premium">
          <li className={cls.listItem}>
            <Link to={PATHS.SHOP}>{t('navigation.userMenu.shop')}</Link>
          </li>
          <li className={clsListItem}>
            <Link
              to={PATHS.GENERATE_CERTIFICATE}
              className={clsListItemPadding}
            >
              {t('navigation.userMenu.generateCertificate')}
            </Link>
          </li>
        </PrivateComponent>
        <li className={clsListItem}>
          <button onClick={logoutUser} className={clsListItemPadding}>
            {t('navigation.userMenu.logOut')}
          </button>
        </li>
      </ul>
    </div>
  );
};

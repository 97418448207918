import React from 'react';
import cls from './how-to-join.module.scss';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import manWorkingOnLaptopImg from 'assets/images/about-action/man-working-on-laptop.png';
import nurseWithNotebook from 'assets/images/about-action/nurse-with-notebook.png';
import { CustomLink } from 'components/universal/custom-link';
import { PATHS } from 'constants/routes';
import { useTranslation } from 'react-i18next';

export const HowToJoinSection = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.container}>
      <Heading headingLevel="h2" className={cls.header}>
        {t('aboutAction.howToJoin.title')}
      </Heading>
      <Paragraph className={cls.desc}>
        {t('aboutAction.howToJoin.desc')}
      </Paragraph>
      <div className={cls.topSection}>
        <div className={cls.topSectionText}>
          <Heading headingLevel="h3" className={cls.topHeader}>
            {t('aboutAction.howToJoin.topSection.row1.title')}
          </Heading>
          <Paragraph className={cls.topText}>
            {t('aboutAction.howToJoin.topSection.row1.text')}
          </Paragraph>
          <Heading headingLevel="h3" className={cls.topHeader}>
            {t('aboutAction.howToJoin.topSection.row2.title')}
          </Heading>
          <Paragraph className={cls.topText}>
            {t('aboutAction.howToJoin.topSection.row2.text')}
          </Paragraph>
          <Heading headingLevel="h3" className={cls.topHeader}>
            {t('aboutAction.howToJoin.topSection.row3.title')}
          </Heading>
          <Paragraph className={cls.topText}>
            {t('aboutAction.howToJoin.topSection.row3.text')}
          </Paragraph>
          <CustomLink to={PATHS.REGISTRATION} className={cls.btn}>
            {t('aboutAction.howToJoin.topSection.link')}
          </CustomLink>
        </div>
        <figure className={cls.topSectionImage}>
          <img src={manWorkingOnLaptopImg} alt="" />
        </figure>
      </div>
      <div className={cls.bottomSection}>
        <figure className={cls.bottomSectionImage}>
          <img src={nurseWithNotebook} alt="" />
        </figure>
        <div className={cls.bottomSectionText}>
          <Paragraph className={cls.bottomText}>
            {t('aboutAction.howToJoin.bottomSection.row1')}
          </Paragraph>
          <Paragraph className={cls.bottomText}>
            {t('aboutAction.howToJoin.bottomSection.row2')}
          </Paragraph>
          <Paragraph className={cls.bottomText}>
            {t('aboutAction.howToJoin.bottomSection.row3')}
          </Paragraph>
          <CustomLink to={PATHS.CONTACT} className={cls.btn}>
            {t('aboutAction.howToJoin.bottomSection.link')}
          </CustomLink>
        </div>
      </div>
    </div>
  );
};

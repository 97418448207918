import React, { FC } from 'react';
import { ImageDnd } from '../image-dnd';
import cls from './image-placeholder.module.scss';

interface IProps {
  id: string;
  image_url: string;
  isEmpty?: boolean;
}

export const ImagePlaceholder: FC<IProps> = ({ image_url, id, isEmpty }) => {
  return (
    <div className={cls.wrapper}>
      {isEmpty ? (
        <div className={cls.emptyBox} />
      ) : (
        <ImageDnd id={id} image_url={image_url} source="start-area" />
      )}
    </div>
  );
};

import courseLayout from './course-layout';
import microbiology from './microbiology';
import handDisinfectionAndWashing from './hand-disinfection-and-washing';
import momentsOfHandHygieneAnimations from './moments-of-hand-hygiene-animations';
import quiz from './quiz/index.json';

const handHygiene = {
  courseLayout,
  microbiology,
  handDisinfectionAndWashing,
  momentsOfHandHygieneAnimations,
  quiz,
};

export default handHygiene;

import {
  ERROR_MESSAGES,
  PASSWORD_REGEX,
} from 'constants/validation-error-messages';
import * as yup from 'yup';

export const schema = yup.object({
  first_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  last_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  email: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.EMAIL),
  phone: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(9, ERROR_MESSAGES.PHONE_LENGHT),
  password: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .matches(PASSWORD_REGEX, ERROR_MESSAGES.PASSWORD_STRENGTH),
  password_repeat: yup
    .string()
    .oneOf([yup.ref('password'), null], ERROR_MESSAGES.PASSWORD_MATCH),
  workplace_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  employee_position_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  workplace_address_street: yup.string().required(ERROR_MESSAGES.REQUIRED),
  workplace_address_number: yup.string().required(ERROR_MESSAGES.REQUIRED),
  workplace_address_city: yup.string().required(ERROR_MESSAGES.REQUIRED),
  workplace_address_postcode: yup.string().required(ERROR_MESSAGES.REQUIRED),
  agreement: yup.boolean().oneOf([true], ERROR_MESSAGES.REQUIRED),
});

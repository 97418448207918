import clsx from 'clsx';
import React, { FC } from 'react';
import cls from './paragraph.module.scss';

type IProps = React.HTMLAttributes<HTMLParagraphElement> & {
  isWhite?: boolean;
  fontWeight?: 300 | 400 | 500 | 700;
};

const Paragraph: FC<IProps> = ({
  isWhite = false,
  fontWeight,
  children,
  className,
  ...props
}) => {
  const clsParagraph = clsx(cls.paragraph, {
    [cls.paragraphWhite]: isWhite,
    [className]: Boolean(className),
  });

  return (
    <p style={{ fontWeight }} className={clsParagraph} {...props}>
      {children}
    </p>
  );
};

export default Paragraph;

// export const moveElementInArray = (
//   arr: string[],
//   oldPlaceValue: string,
//   newPlaceValue: string
// ) => {
//   const newArray = [...arr];
//   const oldIndex = newArray?.indexOf(oldPlaceValue);
//   const newIndex = newArray?.indexOf(newPlaceValue);

//   if (newIndex >= newArray?.length) {
//     let k = newIndex - newArray?.length + 1;
//     while (k--) {
//       newArray?.push?.(undefined);
//     }
//   }
//   newArray?.splice(newIndex, 0, newArray?.splice(oldIndex, 1)[0]);
//   return newArray;
// };

// function arraymove(arr, fromIndex, toIndex) {
//   const newArray = [...arr];
//     const oldIndex = newArray?.indexOf(oldPlaceValue);
//   const newIndex = newArray?.indexOf(newPlaceValue);
//   var element = arr[fromIndex];
//   arr.splice(fromIndex, 1);
//   arr.splice(toIndex, 0, element);
// }
export const moveElementInArray = (
  arr: string[],
  oldPlaceValue: string,
  newPlaceValue: string
) => {
  const newArray = [...arr];
  const fromIndex = newArray?.indexOf?.(oldPlaceValue);
  const toIndex = newArray?.indexOf?.(newPlaceValue);
  const element = newArray?.[fromIndex];
  newArray?.splice?.(fromIndex, 1);
  newArray?.splice?.(toIndex, 0, element);

  return newArray;
};

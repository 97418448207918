import { QuizQuestions } from 'copy/home/hygiene-quiz';

type Answers = { [key: string]: string };

export const finishQuiz = (answers: Answers, data: QuizQuestions) => {
  const correct = Object.keys(answers).reduce((acc: number, el) => {
    if (data[el].correct === answers[el]) return acc + 1;
    return acc;
  }, 0);
  return correct;
};

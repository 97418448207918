export const hospitalAreas = [
  '.green-area',
  '.pink-area',
  '.blue-area',
  '.yellow-area',
  '.brown-area',
];

export const hospitalMapData = {
  'green-area': {
    title: 'Arleta Stawinoga ',
    phone: '+48 501 385 738',
    email: 'arleta.stawinoga@ecolab.com',
  },
  'pink-area': {
    title: 'Rafał Młynarczyk',
    phone: '+48 501 266 363',
    email: 'rafal.mlynarczyk@ecolab.com',
  },
  'blue-area': {
    title: ' Dariusz Małczak',
    phone: '+48 501 363 129',
    email: 'dariusz.malczak@ecolab.com',
  },
  'yellow-area': {
    title: 'Magdalena Dubińska-Rejent',
    phone: '+48 508 238 861',
    email: 'magdalena.dubinska@ecolab.com',
  },
  'brown-area': {
    title: 'Marek Czubara',
    phone: '+48 501 527 980',
    email: 'marek.czubara@ecolab.com',
  },
};

export const defaultData = {
  title: '',
  phone: '',
  email: '',
  tooltip: 'contact.map.tooltipDefault',
};

import { ReactComponent as ArrowGoIcon } from 'assets/images/elearning/navigation/arrow_go_icon.svg';
import { ReactComponent as TickIcon } from 'assets/images/elearning/navigation/tick_icon.svg';
import clsx from 'clsx';
import { PATHS } from 'constants/routes';
import React, { FC } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Topic } from 'types/elearning';
import cls from './list-item.module.scss';

interface IProps {
  topic: Topic;
  path: string;
  isGreen?: boolean;
}

export const ListItem: FC<IProps> = ({ topic, path, isGreen }) => {
  return (
    <li className={cls.item}>
      <Link
        className={clsx(cls.itemLink, {
          [cls.itemLinkActive]: useMatch(
            `${PATHS.HAND_HYGIENE}/${path}/${topic?.path}`
          ),
          [cls.itemLinkBlocked]: !topic?.is_available,
        })}
        to={`${PATHS.HAND_HYGIENE}/${path}/${topic?.path}`}
      >
        <div className={cls.itemTopicWrapper}>
          <div
            className={clsx(cls.itemStatus, {
              [cls.itemStatusDone]: topic?.is_done,
              [cls.itemStatusGreen]: topic?.is_done && isGreen,
            })}
          >
            <TickIcon />
          </div>
          <span>{topic?.name}</span>
        </div>
        <ArrowGoIcon className={cls.itemArrow} />
      </Link>
    </li>
  );
};

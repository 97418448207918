import { useTopic } from 'hooks/useTopic';
import React, { FC, Fragment } from 'react';
import { Answer, Task } from 'types/elearning';
import { Question } from '..';

interface IProps {
  task: Task;
  isChecked: boolean;
  correctAnswer: Answer['correct_answer'];
}

export const QuestionsMultiple: FC<IProps> = ({
  task,
  isChecked,
  correctAnswer,
}) => {
  const { checkedAnswers, handleSetCheckedAnswersMultiple } = useTopic();

  return (
    <>
      {task?.content &&
        Object?.entries?.(task?.content)?.map?.(
          ([questionId, question], id) => (
            <Fragment key={questionId}>
              <Question
                questionNumber={id + 1}
                title={question?.question}
                answers={question?.answers}
                checkedAnswers={checkedAnswers?.[questionId]}
                correctAnswers={correctAnswer?.[questionId]}
                isChecked={isChecked}
                setAnswers={(newAnswerId) =>
                  handleSetCheckedAnswersMultiple(questionId, newAnswerId)
                }
              />
            </Fragment>
          )
        )}
    </>
  );
};

import sendButtonImg from 'assets/images/registration/send_button.png';
import Heading from 'components/universal/heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from './../login/login.module.scss';

export const EmailVeryficationErrorView = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.container}>
      <div className={cls.columnLeft}>
        <Heading className={cls.heading} headingLevel="h1" headingStyle="h2">
          {t('emailVerificationError.title')}
        </Heading>
      </div>
      <figure className={cls.figure}>
        <img src={sendButtonImg} className={cls.image} />
      </figure>
    </div>
  );
};

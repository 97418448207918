import React from 'react';
import cls from './trusted-us.module.scss';
import Heading from 'components/universal/heading';
import sesLogo from 'assets/images/patronage/ses.png';
import shlLogo from 'assets/images/patronage/shl.png';
import pspeLogo from 'assets/images/patronage/pspe.png';
import pssmLogo from 'assets/images/patronage/pssm.png';
import czysteLeczenieLogo from 'assets/images/patronage/czyste_leczenie_v2.png';
import { useTranslation } from 'react-i18next';
import { URLS } from 'constants/external-urls';

export const TrustedUsSection = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.container}>
      <Heading headingLevel="h2" className={cls.header}>
        {t('home.trustedUs.title')}
      </Heading>
      <div className={cls.logos}>
        <div className={cls.row}>
          <a href={URLS.SHL} target="_blank">
            <img
              src={shlLogo}
              alt="Stowarzyszenie Higieny Lecznictwa"
              className={cls.logo}
            />
          </a>
          <a href={URLS.SES} target="_blank">
            <img
              src={sesLogo}
              alt="Stowarzyszenie Epidemiologii Szpitalnej"
              className={cls.logo}
            />
          </a>
        </div>
        <div className={cls.row}>
          <a href={URLS.PSPE} target="_blank">
            <img
              src={pspeLogo}
              alt="Polskie Stowarzyszenie Pielęgniarek Epidemiologicznych"
              className={cls.logo}
            />
          </a>
          <a href={URLS.PSSM} target="_blank">
            <img
              src={pssmLogo}
              alt="Polskie Stowarzyszenie Sterylizacji Medycznej"
              className={cls.logo}
            />
          </a>
        </div>
        <div className={cls.row}>
          <a href={URLS.CZYSTE_LECZENIE} target="_blank">
            <img
              src={czysteLeczenieLogo}
              alt="Europejskie Stowarzyszenie Czyste Ręc"
              className={cls.logo}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import cls from './what-you-gain.module.scss';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import { ReactComponent as DoctorImg } from 'assets/images/what-you-gain/doctor.svg';
import { ReactComponent as PatientImg } from 'assets/images/what-you-gain/patient.svg';
import { ReactComponent as HospitalImg } from 'assets/images/what-you-gain/hospital.svg';
import { useTranslation } from 'react-i18next';

export const WhatYouGainSection = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.container}>
      <Heading headingLevel="h2" className={cls.header}>
        {t('aboutAction.whatYouGain.title')}
      </Heading>
      <Paragraph className={cls.desc}>
        {t('aboutAction.whatYouGain.text1')}
      </Paragraph>
      <Paragraph className={cls.desc}>
        {t('aboutAction.whatYouGain.text2')}
      </Paragraph>
      <div className={cls.cards}>
        <div className={cls.card}>
          <DoctorImg className={cls.image} />
          <Paragraph fontWeight={700}>
            {t('aboutAction.whatYouGain.card1')}
          </Paragraph>
        </div>
        <div className={cls.card}>
          <PatientImg className={cls.image} />
          <Paragraph fontWeight={700}>
            {t('aboutAction.whatYouGain.card2')}
          </Paragraph>
        </div>
        <div className={cls.card}>
          <HospitalImg className={cls.image} />
          <Paragraph fontWeight={700}>
            {t('aboutAction.whatYouGain.card3')}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

import clsx from 'clsx';
import { useTopic } from 'hooks/useTopic';
import React, { FC, Fragment } from 'react';
import { Answer, Task } from 'types/elearning';
import { DropZone } from './drop-zone';
import { ImagePlaceholder } from './image-placeholder';
import cls from './match-image-with-description.module.scss';

interface IProps {
  task: Task;
  isChecked: boolean;
  correctAnswer: Answer['correct_answer'];
}

export const MatchImageWithDescription: FC<IProps> = ({ task, isChecked }) => {
  const {
    matchTextWithDescriptionCurrentAnswer,
    handleSetMatchTextWithDescriptionCurrentAnswer,
  } = useTopic();

  const clsWrapper = clsx(cls.wrapper, {
    [cls.wrapperDisabled]: isChecked,
  });

  return (
    <div className={clsWrapper}>
      <div className={cls.dropArea}>
        {Object?.entries?.(task?.content?.descriptions)?.map?.(
          ([key, description], id) => {
            const image_url = task?.content?.images?.[
              matchTextWithDescriptionCurrentAnswer?.[key]
            ] as string;

            return (
              <Fragment key={`${key}-${description}-${id}-${image_url}`}>
                <DropZone
                  id={key}
                  description={description as string}
                  image_url={image_url}
                  image={
                    Object?.entries?.(task?.content?.images)?.find?.(
                      ([imgKey]) =>
                        imgKey === matchTextWithDescriptionCurrentAnswer?.[key]
                    ) as [string, string]
                  }
                  handleDrop={handleSetMatchTextWithDescriptionCurrentAnswer}
                />
              </Fragment>
            );
          }
        )}
      </div>
      <div className={cls.imagesArea}>
        {Object?.entries?.(task?.content?.images)?.map?.(
          ([key, image_url], id) => (
            <Fragment key={`${key}-${image_url}-${id}`}>
              <ImagePlaceholder
                id={key}
                image_url={image_url as string}
                isEmpty={
                  matchTextWithDescriptionCurrentAnswer &&
                  Object?.values?.(
                    matchTextWithDescriptionCurrentAnswer
                  )?.includes?.(key)
                }
              />
            </Fragment>
          )
        )}
      </div>
    </div>
  );
};

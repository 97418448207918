import React from 'react';
import cls from './safety.module.scss';
import textAnimation from 'assets/images/home/text-animation.mp4';
import textAnimationEng from 'assets/images/home/text-animation-eng.mp4';
import logoAnimation from 'assets/images/home/logo-animated.mp4';
import { useTranslation } from 'react-i18next';

export const SafetySection = () => {
  const { i18n } = useTranslation();
  return (
    <div className={cls.container}>
      <figure className={cls.text}>
        {i18n.language.includes('pl') && (
          <video src={textAnimation} playsInline autoPlay muted loop />
        )}
        {i18n.language.includes('eng') && (
          <video src={textAnimationEng} playsInline autoPlay muted loop />
        )}
      </figure>
      <figure className={cls.logo}>
        <video src={logoAnimation} playsInline autoPlay muted loop />
      </figure>
    </div>
  );
};

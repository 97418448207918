import { BREAKPOINTS } from 'constants/breakpoints';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { useMedia } from 'react-use';
import cls from './pagination.module.scss';

type Props = Pick<
  ReactPaginateProps,
  'pageCount' | 'initialPage' | 'forcePage' | 'onPageChange'
>;

export const Pagination = ({
  pageCount,
  initialPage,
  forcePage,
  onPageChange,
}: Props) => {
  const isLarge = useMedia(BREAKPOINTS.SM);

  return (
    <>
      {pageCount > 1 && (
        <ReactPaginate
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={isLarge ? 5 : 2}
          previousLabel="<"
          nextLabel=">"
          previousClassName={cls.previous}
          nextClassName={cls.next}
          containerClassName={cls.container}
          pageClassName={cls.page}
          breakClassName={cls.break}
          activeClassName={cls.active}
          initialPage={initialPage}
          forcePage={forcePage}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};

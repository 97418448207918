/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import React, { FC, InputHTMLAttributes } from 'react';
import { UseFormRegister } from 'react-hook-form';
import cls from './input.module.scss';

export type StyleType = 'secondary';
interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  styleType?: StyleType;
  name: string;
  label?: string;
  error?: string;
  hideErrorLabel?: boolean;
  adnotation?: string;
  register: UseFormRegister<any>;
  className?: string;
}

export const Input: FC<IProps> = ({
  label,
  id,
  type = 'text',
  error,
  hideErrorLabel = false,
  adnotation,
  register,
  name,
  className,
  ...props
}) => {
  const clsInputWrapper = clsx(cls.inputWrapper, {
    [cls.inputWrapperError]: error,
    [className]: Boolean(className),
  });

  return (
    <div className={clsInputWrapper}>
      {label && (
        <label htmlFor={id} className={cls.label}>
          {label}
        </label>
      )}
      <input
        {...register(name)}
        className={cls.input}
        id={id}
        type={type}
        {...props}
      />
      {!error && adnotation && <p className={cls.adnotation}>{adnotation}</p>}
      {error && !hideErrorLabel && <p className={cls.errorBox}>{error}</p>}
    </div>
  );
};

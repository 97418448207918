import { CustomLink } from 'components/universal/custom-link';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import { PATHS } from 'constants/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from './expand-your-knowledge.module.scss';

export const ExpandYourKnowledgeSection = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.wrapper}>
      <div className={cls.container}>
        <Heading headingLevel="h2" isWhite className={cls.header}>
          {t('aboutAction.expandYourKnowledge.title')}
        </Heading>
        <Paragraph className={cls.desc}>
          {t('aboutAction.expandYourKnowledge.desc')}
        </Paragraph>
        <div className={cls.cards}>
          <div className={cls.row}>
            <div className={cls.card}>
              <Heading headingLevel="h3" className={cls.cardHeader}>
                {t('aboutAction.expandYourKnowledge.card1.title')}{' '}
                <span className={cls.cardHeaderLowercase}>
                  {t('aboutAction.expandYourKnowledge.card1.titleLowerCaseA')}
                  <br className={cls.fakeBreak} />{' '}
                  {t('aboutAction.expandYourKnowledge.card1.titleLowerCaseB')}
                </span>
              </Heading>
              <Paragraph className={cls.cardText}>
                {t('aboutAction.expandYourKnowledge.card1.text')}
              </Paragraph>
              <CustomLink to={PATHS.CONTACT} className={cls.cardBtn}>
                {t('aboutAction.expandYourKnowledge.card1.button')}
              </CustomLink>
            </div>
            <div className={cls.card}>
              <Heading headingLevel="h3" className={cls.cardHeader}>
                {t('aboutAction.expandYourKnowledge.card2.title')}
              </Heading>
              <Paragraph className={cls.cardText}>
                {t('aboutAction.expandYourKnowledge.card2.text')}
              </Paragraph>
              <CustomLink
                to={PATHS.DISINFECTION_TECHNIQUES}
                state={{ goToId: 'promo-materials' }}
                className={cls.cardBtn}
              >
                {t('aboutAction.expandYourKnowledge.card2.button')}
              </CustomLink>
            </div>
          </div>
          <div className={cls.row}>
            <div className={cls.card}>
              <Heading headingLevel="h3" className={cls.cardHeader}>
                {t('aboutAction.expandYourKnowledge.card3.title')}
              </Heading>
              <Paragraph className={cls.cardText}>
                {t('aboutAction.expandYourKnowledge.card3.text')}
              </Paragraph>
              <CustomLink to={PATHS.KNOWLEDGE_BASE} className={cls.cardBtn}>
                {t('aboutAction.expandYourKnowledge.card3.button')}
              </CustomLink>
            </div>
            <div className={cls.card}>
              <Heading headingLevel="h3" className={cls.cardHeader}>
                {t('aboutAction.expandYourKnowledge.card4.title')}
              </Heading>
              <Paragraph className={cls.cardText}>
                {t('aboutAction.expandYourKnowledge.card4.text')}
              </Paragraph>
              <CustomLink
                to={PATHS.DISINFECTION_TECHNIQUES}
                state={{ goToId: 'how-to-disinfect' }}
                className={cls.cardBtn}
              >
                {t('aboutAction.expandYourKnowledge.card4.button')}
              </CustomLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

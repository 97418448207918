import { ProductsBody } from 'types/cart';
import { request } from 'utils/request';
import { ISubmitOrderBodyData } from 'views/submit-your-order';

export const getProducts = async (body: ProductsBody) =>
  await request('/shop/products', { method: 'POST' }, body);

export const getCategories = async () => await request('/shop/categories', {});

export const createOrder = async (orderData: ISubmitOrderBodyData) =>
  await request('/shop/order/create', { method: 'POST' }, orderData);

import { useTranslation } from 'react-i18next';
import privacyPolicy from 'assets/files/polityka_prywatnosci.pdf';
import actionRegulations from 'assets/files/regulamin_swiadczenia_akcji.pdf';
import cls from './footer.module.scss';
import { FooterIcons } from './FooterIcons';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={cls.footer}>
      <div className={cls.content}>
        <p className={cls.title}>{t('footer.title')}</p>
        <div className={cls.icons}>
          <FooterIcons />
        </div>
        <div className={cls.description}>
          <p className="mt-5">{t('footer.action')}</p>
          <div className={cls.policysWrapper}>
            <div className={cls.policyWrapper}>
              <a
                className={cls.policy}
                href={privacyPolicy}
                download
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.policy')}
              </a>
            </div>
            <div className={cls.policyWrapper}>
              <a
                className={cls.policy}
                href={actionRegulations}
                download
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.regulations')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

import React from 'react';
import cls from './get-to-know-action.module.scss';
import Heading from 'components/universal/heading';
import womanWithStethoscope from 'assets/images/about-action/woman-with-stethoscope.png';
import Paragraph from 'components/universal/paragraph';
import { useTranslation } from 'react-i18next';

export const GetToKnowActionSection = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.container}>
      <div className={cls.content}>
        <Heading headingLevel="h1" className={cls.header}>
          {t('aboutAction.getToKnowAction.title')}
        </Heading>
        <figure className={cls.imageTablet}>
          <img src={womanWithStethoscope} alt="" />
        </figure>
        <Paragraph className={cls.text}>
          {t('aboutAction.getToKnowAction.text1')}
        </Paragraph>
        <Paragraph className={cls.text}>
          {t('aboutAction.getToKnowAction.text2')}{' '}
          <b>{t('aboutAction.getToKnowAction.text2Bold')}</b>.
        </Paragraph>
        <Paragraph className={cls.text}>
          {t('aboutAction.getToKnowAction.text3')}
        </Paragraph>
      </div>
      <figure className={cls.image}>
        <img src={womanWithStethoscope} alt="" />
      </figure>
    </div>
  );
};

import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from './language-change.module.scss';

export const LanguageChange = () => {
  const { i18n } = useTranslation();

  const languages = ['pl', 'eng'];

  const clsListItem = (val: string) =>
    clsx(cls.btn, {
      [cls.active]: i18n.language.split('-').includes(val),
    });

  return (
    <ul className={cls.list}>
      {languages.map((l) => (
        <li key={l} className={cls.listItem}>
          <button
            onClick={() => i18n.changeLanguage(l)}
            className={clsListItem(l)}
          >
            {l}
          </button>
        </li>
      ))}
    </ul>
  );
};

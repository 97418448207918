import React from 'react';
import { ConfirmEmailAddressView } from 'views/confirm-email-address';

export const ConfirmEmailAddress = () => {
  return (
    <div>
      <ConfirmEmailAddressView />
    </div>
  );
};

import React, { useEffect, useRef } from 'react';
import cls from './how-to-disinfect-hands.module.scss';
import Heading from 'components/universal/heading';
import { Section } from './Section';
import Paragraph from 'components/universal/paragraph';
import { useTranslation } from 'react-i18next';
import { DisinfectionArticle } from 'types/disinfection-technique';
import { useLocation } from 'react-router-dom';
interface IProps {
  articles: DisinfectionArticle[];
}

interface LocationState {
  goToId: string;
}

export const HowToDisinfectHands = ({ articles }: IProps) => {
  const { t } = useTranslation();
  const disinfectRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const state = location.state as LocationState;

  useEffect(() => {
    if (state?.goToId === 'how-to-disinfect') {
      setTimeout(() => {
        const elementPositionY =
          disinfectRef?.current?.getBoundingClientRect().y;
        const extraOffset = -60;
        const scrollTo = elementPositionY + window.pageYOffset + extraOffset;

        window.scrollTo({
          top: scrollTo,
          behavior: 'smooth',
        });
      }, 700);
    }
  }, [disinfectRef?.current]);

  return (
    <div className={cls.wrapper} ref={disinfectRef}>
      <div className={cls.container}>
        <Heading headingLevel="h2" className={cls.header} isWhite>
          {t('disinfectionTechnique.howToDisinfectHands.title')}
        </Heading>
        <Paragraph isWhite className={cls.desc}>
          {t('disinfectionTechnique.howToDisinfectHands.desc')}
        </Paragraph>
        {articles?.map((article, index) => (
          <Section
            src={article.video_url}
            videoLeft={index % 2 === 0}
            poster={article.thumbnail_url}
          >
            <Paragraph isWhite fontWeight={700} className={cls.sectionTitle}>
              {article.title}
            </Paragraph>
            <Paragraph isWhite className={cls.sectionDesc}>
              {article.description}
            </Paragraph>
          </Section>
        ))}
      </div>
    </div>
  );
};

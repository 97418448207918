import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import { Button } from 'components/universal/button';
import { Checkbox } from 'components/universal/checkbox';
import { GoBack } from 'components/universal/go-back';
import Heading from 'components/universal/heading';
import { Input } from 'components/universal/input';
import { Textarea } from 'components/universal/textarea';
import { PATHS } from 'constants/routes';
import { useCart } from 'hooks/useCart';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createOrder } from 'services/shop';
import { UserData } from 'types/user';
import cls from './submit-your-order.module.scss';
import { schema } from './validation';
import { Accordion } from 'components/universal/accordion';
import { InfoClause } from './InfoClause';

export interface ISubmitOrderFormValues
  extends Pick<UserData, 'first_name' | 'last_name' | 'email' | 'phone'> {
  delivery_workplace_name: string;
  delivery_address_street: string;
  delivery_address_number: string;
  delivery_address_city: string;
  delivery_address_postcode: string;
  additional_comments: string;
  checkbox_a: boolean;
  checkbox_b: boolean;
  checkbox_c: boolean;
  checkbox_d: boolean;
}

export interface ISubmitOrderBodyData extends ISubmitOrderFormValues {
  products: {
    product_id: number;
    count: number;
  }[];
}

export const SubmitYourOrderView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { cart, setCart } = useCart();
  const { userData } = useUser();

  const { first_name, last_name, email, phone } = userData;

  const defaultValues = {
    first_name: first_name || '',
    last_name: last_name || '',
    email: email || '',
    phone: phone || '',
    delivery_workplace_name: '',
    delivery_address_street: '',
    delivery_address_number: '',
    delivery_address_city: '',
    delivery_address_postcode: '',
    agreement: false,
    additional_comments: '',
    checkbox_a: false,
    checkbox_b: false,
    checkbox_c: false,
    checkbox_d: false,
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ISubmitOrderFormValues>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = (formObject) => {
    const products = cart?.map?.((productWithQuantity) => ({
      product_id: productWithQuantity?.product?.id,
      count: productWithQuantity?.quantity,
    }));
    const submitOrderBodyData: ISubmitOrderBodyData = {
      ...formObject,
      products,
    };
    signUpMutation.mutate(submitOrderBodyData);
  };

  const signUpMutation = useMutation<
    AxiosResponse<{ message: string }>,
    AxiosError,
    ISubmitOrderBodyData
  >((data) => createOrder(data), {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      setCart([]);
      navigate(PATHS.SHOP);
    },
    onError: (error) => {
      // set errors from backend request
      const formErrors = error?.response?.data?.errors;
      if (formErrors) {
        Object.entries(formErrors).map(([key, value]) => {
          setError(key as keyof ISubmitOrderFormValues, {
            message: value?.[0],
          });
        });

        // case where products were removed after navigate to submit order page
        if (
          Object?.keys?.(formErrors)?.find((errMsg) =>
            errMsg?.includes?.('products.')
          )
        ) {
          // TODO - add translation
          toast.error('Wystąpił błąd, spróbuj ponownie.');
          navigate(PATHS.SHOP);
        }
      }
    },
  });

  return (
    <div className={cls.container}>
      <div className={cls.columnLeft}>
        <Heading className={cls.heading} headingLevel="h1" headingStyle="h2">
          {t('submitYourOrder.title')}
        </Heading>
        <GoBack to={PATHS.BASKET} className="mb-11">
          {t('submitYourOrder.backButton')}
        </GoBack>
        <form className={cls.form} onSubmit={handleSubmit(onSubmit)}>
          <Heading headingLevel="h3" className="mb-5">
            {t('submitYourOrder.contactData')}
          </Heading>
          <div className={cls.formFields}>
            <Input
              className="mb-6"
              register={register}
              id="first_name"
              name="first_name"
              label={t('submitYourOrder.firstName')}
              error={t(errors.first_name?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="last_name"
              name="last_name"
              label={t('submitYourOrder.lastName')}
              error={t(errors.last_name?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="email"
              name="email"
              label={t('submitYourOrder.email')}
              error={t(errors.email?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="phone"
              name="phone"
              label={t('submitYourOrder.phone')}
              error={t(errors.phone?.message)}
              maxLength={255}
            />
          </div>
          <Heading headingLevel="h3" className="mb-5 mt-7">
            {t('submitYourOrder.deliveryAddress')}
          </Heading>

          <div className={cls.formFields}>
            <Input
              className={cls.workplaceInput}
              register={register}
              id="delivery_workplace_name"
              name="delivery_workplace_name"
              label={t('submitYourOrder.workplaceName')}
              error={t(errors.delivery_workplace_name?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="delivery_address_street"
              name="delivery_address_street"
              label={t('submitYourOrder.street')}
              error={t(errors.delivery_address_street?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="delivery_address_number"
              name="delivery_address_number"
              label={t('submitYourOrder.apartmentNumber')}
              error={t(errors.delivery_address_number?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="delivery_address_city"
              name="delivery_address_city"
              label={t('submitYourOrder.city')}
              error={t(errors.delivery_address_city?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="delivery_address_postcode"
              name="delivery_address_postcode"
              label={t('submitYourOrder.zipCode')}
              error={t(errors.delivery_address_postcode?.message)}
              maxLength={255}
            />

            <Textarea
              className={cls.textarea}
              register={register}
              id="additional_comments"
              name="additional_comments"
              label={t('submitYourOrder.additionalComments')}
            />
          </div>

          <p className={cls.paragraph}>{t('submitYourOrder.requiredFields')}</p>
          <Checkbox
            className={cls.input}
            register={register}
            id="checkbox_a"
            name="checkbox_a"
            label={t('submitYourOrder.checkboxA')}
            error={t(errors.checkbox_a?.message)}
          />
          <Checkbox
            className={cls.input}
            register={register}
            id="checkbox_b"
            name="checkbox_b"
            label={t('submitYourOrder.checkboxB')}
            error={t(errors.checkbox_b?.message)}
          />
          <Checkbox
            className={cls.input}
            register={register}
            id="checkbox_c"
            name="checkbox_c"
            label={t('submitYourOrder.checkboxC')}
            error={t(errors.checkbox_c?.message)}
          />
          <Checkbox
            className={cls.checkbox}
            register={register}
            id="checkbox_d"
            name="checkbox_d"
            label={
              <>
                {t('submitYourOrder.agreement')}{' '}
                {t('submitYourOrder.agreementLink')}
              </>
            }
            error={t(errors.checkbox_d?.message)}
          />
          <Accordion
            height={300}
            arrow
            label={t('contact.contactForm.infoClause')}
            className="mb-5"
          >
            <InfoClause />
          </Accordion>
          <Button className={cls.button} type="submit">
            {t('submitYourOrder.submitOrder')}
          </Button>
        </form>
      </div>
    </div>
  );
};

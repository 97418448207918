import { ReactComponent as ArrowIcon } from 'assets/images/elearning/navigation/arrow_icon.svg';
import { ReactComponent as TopicIcon } from 'assets/images/elearning/navigation/topic_icon.svg';
import clsx from 'clsx';
import Heading from 'components/universal/heading';
import { BREAKPOINTS } from 'constants/breakpoints';
import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { Issue } from 'types/elearning';
import clsShared from './../accordion/accordion.module.scss';
import { ListItem } from './../accordion/list-item';
import cls from './accordion-general.module.scss';

interface IProps {
  issue: Issue;
  className?: string;
}

export const AccordionGeneral: FC<IProps> = ({ issue, className }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'elearning.handHygiene.courseLayout.issues',
  });
  const { name, path, topics } = issue;
  const isLg = useMedia(BREAKPOINTS.LG);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const toggleAccordion = () => setIsAccordionOpen((prevState) => !prevState);

  const clsArrowIcon = clsx(clsShared.arrowIcon, {
    [clsShared.arrowIconRotated]: isAccordionOpen,
  });
  const clsAccordion = clsx(cls.accordion, {
    [cls.accordionOpen]: isAccordionOpen,
    [className]: Boolean(className),
  });

  const clsList = clsx(cls.list, {
    [cls.listOpen]: isAccordionOpen,
  });

  return (
    <div className={clsAccordion}>
      <button
        aria-label={isAccordionOpen ? t('collapseList') : t('expandList')}
        className={cls.headingWrapper}
        onClick={toggleAccordion}
      >
        <Heading className={clsShared.heading} headingLevel="h3">
          {name}
        </Heading>
        {isLg && (
          <div className={cls.paragraphWrapper}>
            <TopicIcon />
            <p className={clsShared.paragraph}>
              {t('topics')} <b>{topics?.length}</b>
            </p>
          </div>
        )}
        <ArrowIcon className={clsArrowIcon} />
      </button>

      {!isLg && (
        <div className={clsShared.paragraphWrapper}>
          <TopicIcon />
          <p className={clsShared.paragraph}>
            {t('topics')} <b>{topics?.length}</b>
          </p>
        </div>
      )}
      {isAccordionOpen && <div className={cls.line}></div>}
      <ul className={clsList}>
        {topics?.map?.((topic) => (
          <Fragment key={topic?.path}>
            <ListItem topic={topic} path={path} isGreen />
          </Fragment>
        ))}
      </ul>
    </div>
  );
};

import sendButtonImg from 'assets/images/registration/send_button.png';
import { CustomLink } from 'components/universal/custom-link';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import { PATHS } from 'constants/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import cls from './../login/login.module.scss';

export const EmailVeryficationSuccessView = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const { t } = useTranslation();

  return (
    <div className={cls.container}>
      <div className={cls.columnLeft}>
        <Heading className={cls.heading} headingLevel="h1" headingStyle="h2">
          {t('emailVerificationSuccess.title')}
        </Heading>
        <Paragraph className="mb-10">
          {t('emailVerificationSuccess.textA')} <b>{email}</b>{' '}
          {t('emailVerificationSuccess.textB')}
        </Paragraph>
        <CustomLink to={PATHS.LOGIN} className="mx-auto w-fit">
          {t('emailVerificationSuccess.link')}
        </CustomLink>
      </div>
      <figure className={cls.figure}>
        <img src={sendButtonImg} className={cls.image} />
      </figure>
    </div>
  );
};

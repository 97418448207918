import React, { FC, HTMLAttributes } from 'react';
import cls from './tooltip.module.scss';
import clsx from 'clsx';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  x: number;
  y: number;
  visible?: boolean;
}

export const Tooltip: FC<IProps> = ({
  className,
  x,
  y,
  visible,
  children,
  ...props
}) => {
  const clsTooltip = clsx(cls.tooltip, {
    [className]: Boolean(className),
    [cls.visible]: Boolean(visible),
  });

  return (
    <div {...props} className={clsTooltip} style={{ top: y - 135, left: x }}>
      {children}
    </div>
  );
};

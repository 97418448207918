import { ERROR_MESSAGES } from 'constants/validation-error-messages';
import * as yup from 'yup';

export const schema = yup.object({
  bedsNumber: yup.string().required(ERROR_MESSAGES.REQUIRED),
  averageOccupancy: yup.string().required(ERROR_MESSAGES.REQUIRED),
  medicalPersonelNumber: yup.string().required(ERROR_MESSAGES.REQUIRED),
  averageProceduresPerDay: yup.string().required(ERROR_MESSAGES.REQUIRED),
  consumptionPerMonth: yup.string().required(ERROR_MESSAGES.REQUIRED),
  consumptionPerYear: yup.string().required(ERROR_MESSAGES.REQUIRED),
});

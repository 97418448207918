import React from 'react';
import { ConfirmResetPasswordView } from 'views/confirm-reset-password';

export const ConfirmResetPassword = () => {
  return (
    <div>
      <ConfirmResetPasswordView />
    </div>
  );
};

import { Accordion } from 'components/elearning/navigation/accordion';
import { TopicStatus } from 'components/elearning/topic-status';
import { GoBack } from 'components/universal/go-back';
import { PATHS } from 'constants/routes';
import { useTopic } from 'hooks/useTopic';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import cls from './course-layout.module.scss';

export const CourseLayout = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'elearning.handHygiene.courseLayout.general',
  });
  const { pathname } = useLocation();
  const { courseData, continueCourse, isFetchingCourseInfo, topicPath } =
    useTopic();

  const pathElements = topicPath?.split?.('/');
  const currentTopicPath = pathElements?.[3];

  const currentIssue =
    courseData &&
    courseData?.issues?.find?.((issue) => pathname?.includes?.(issue?.path));

  const currentTopic =
    currentIssue &&
    currentIssue?.topics?.find?.((topic) =>
      currentTopicPath?.includes?.(topic?.path)
    );

  return (
    <div className={cls.layout}>
      <nav className={cls.menu}>
        <GoBack className="mb-10" to={PATHS.HAND_HYGIENE}>
          {t('backButton')}
        </GoBack>
        <Accordion issue={currentIssue} />
        <div className={cls.line}></div>
      </nav>
      <div className={cls.container}>
        <div className={cls.content}>
          <div className={cls.box}>
            {currentTopic?.is_available ? (
              <Outlet />
            ) : (
              <>
                {!isFetchingCourseInfo && (
                  <TopicStatus
                    title={t('lockTopicTitle')}
                    description={t('lockTopicDescription')}
                    buttonText={t('lockTopicButtontext')}
                    isSuccess
                    isStandalone
                    isHideStatusIcon
                    onSubmit={continueCourse}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

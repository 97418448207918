import React, { FC, HTMLAttributes, useEffect } from 'react';
import cls from './newsletter.module.scss';
import clsx from 'clsx';
import Paragraph from '../paragraph';
import { Button } from '../button';
import { useDispatch, useSelector } from 'react-redux';
import { showModal, hideModal } from 'store/modal/modalSlice';
import { Modal } from '../modal';
import Heading from '../heading';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { newsletterSchema } from './validation';
import { AxiosError, AxiosResponse } from 'axios';
import { APIErrors } from 'types/api';
import { NewsletterFormDTO } from 'types/newsletter';
import { useMutation } from 'react-query';
import { submitNewsletterForm } from 'services/newsletter';
import { toast } from 'react-toastify';
import { Input } from '../input';
import { Checkbox } from '../checkbox';
import { Accordion } from '../accordion';
import { NewsletterClause } from './NewsletterClause';
import { RadioConsent } from '../radio-consent';
import { RootState } from 'store/store';
import { useTranslation } from 'react-i18next';

export interface INewsletterFormValues {
  first_name: string;
  last_name: string;
  email: string;
  workplace_name: string;
  employee_position_name: string;
  checkbox_a: string;
  checkbox_b: string;
  checkbox_c: string;
  checkbox_d: boolean;
}
interface IProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const Newsletter: FC<IProps> = ({ className, ...props }) => {
  const clsNewsletter = clsx(cls.newsletter, {
    [className]: Boolean(className),
  });

  const dispatch = useDispatch();
  const modal = () => {
    dispatch(showModal());
  };

  const { isModalOpen } = useSelector((state: RootState) => state.modal);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<INewsletterFormValues>({
    resolver: yupResolver(newsletterSchema),
    defaultValues: {
      workplace_name: '',
      first_name: '',
      last_name: '',
      email: '',
      employee_position_name: '',
      checkbox_a: '',
      checkbox_b: '',
      checkbox_c: '',
      checkbox_d: false,
    },
  });

  const onSubmit = (DTO) => contactMutation.mutate(DTO);

  const contactMutation = useMutation<
    AxiosResponse<{ message: string }>,
    AxiosError<APIErrors>,
    NewsletterFormDTO
  >(
    (data) =>
      submitNewsletterForm({
        ...data,
        checkbox_a: Boolean(data.checkbox_a),
        checkbox_b: Boolean(data.checkbox_b),
        checkbox_c: Boolean(data.checkbox_c),
      }),
    {
      onSuccess: async ({ data }) => {
        toast.success(data.message);
        reset();
        dispatch(hideModal());
      },
      onError: async (err) => {
        const resErrors = err.response.data.errors;
        Object.keys(resErrors).forEach((field: keyof NewsletterFormDTO) => {
          setError(field, {
            type: 'manual',
            message: resErrors[field].join('\n'),
          });
        });
      },
    }
  );

  useEffect(() => {
    if (!isModalOpen) reset();
  }, [isModalOpen]);

  return (
    <div {...props} className={clsNewsletter}>
      <Paragraph isWhite>{t('newsletter.text')}</Paragraph>
      <Button className={cls.btn} onClick={modal}>
        {t('newsletter.button')}
      </Button>
      <Modal>
        <Heading headingLevel="h3" className={cls.header}>
          {t('newsletter.modal.title')}
        </Heading>
        <div className={cls.modalContent}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              className={cls.input}
              register={register}
              id="first_name"
              name="first_name"
              label={t('newsletter.modal.firstName')}
              error={t(errors.first_name?.message)}
              maxLength={255}
            />
            <Input
              className={cls.input}
              register={register}
              id="last_name"
              name="last_name"
              label={t('newsletter.modal.lastName')}
              error={t(errors.last_name?.message)}
              maxLength={255}
            />
            <Input
              className={cls.input}
              register={register}
              id="email"
              name="email"
              label={t('newsletter.modal.email')}
              error={t(errors.email?.message)}
              type="email"
            />
            <Input
              className={cls.input}
              register={register}
              id="workplace_name"
              name="workplace_name"
              label={t('newsletter.modal.workplaceName')}
              error={t(errors.workplace_name?.message)}
              maxLength={50}
            />
            <Input
              className={cls.input}
              register={register}
              id="employee_position_name"
              name="employee_position_name"
              label={t('newsletter.modal.employeePositionName')}
              error={t(errors.employee_position_name?.message)}
              maxLength={50}
            />
            <RadioConsent
              className={cls.input}
              register={register}
              id="checkbox_a"
              name="checkbox_a"
              error={t(errors.checkbox_a?.message)}
            >
              {t('newsletter.modal.checkboxA')}
            </RadioConsent>
            <RadioConsent
              className={cls.input}
              register={register}
              id="checkbox_b"
              name="checkbox_b"
              error={t(errors.checkbox_b?.message)}
            >
              {t('newsletter.modal.checkboxB')}
            </RadioConsent>{' '}
            <RadioConsent
              className={cls.input}
              register={register}
              id="checkbox_c"
              name="checkbox_c"
              error={t(errors.checkbox_c?.message)}
            >
              {t('newsletter.modal.checkboxC')}
            </RadioConsent>
            <Checkbox
              className={cls.input}
              register={register}
              id="checkbox_d"
              name="checkbox_d"
              error={t(errors.checkbox_d?.message)}
              label={t('newsletter.modal.checkboxD')}
            ></Checkbox>
            <Paragraph className={cls.tip}>
              {t('newsletter.modal.requiredFields')}
            </Paragraph>
            <Accordion
              height={300}
              arrow
              label={t('newsletter.modal.infoClause')}
              className="mb-5"
            >
              <NewsletterClause />
            </Accordion>
            <Button className={cls.modalBtn} type="submit">
              {t('newsletter.modal.button')}
            </Button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

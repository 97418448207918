export type CourseName = 'hand-hygiene';

// course structure
export interface Course {
  issues: Issue[];
  id: number;
  path: string;
  name: string;
  stats: CourseStats;
  is_available: boolean;
  description: string;
  certificate_url?: string;
}

export interface CourseStats {
  progress: number;
  issues: number;
  topics: number;
  quizzes: number;
}
export interface Issue {
  id: number;
  name: string;
  path: string;
  is_available: boolean; // is Issue available for user
  is_done: boolean; // is Issue Done by user
  topics: Topic[];
}

export interface Topic {
  id: number;
  name: string;
  path: string;
  is_available: boolean;
  is_done: boolean;
  tasks?: Task[];
}

export const courseData: Course = {
  id: 1,
  path: 'hand-hygiene',
  name: 'HIGIENA RĄK',
  description:
    '„Higiena rąk jest uznawana za najważniejszą procedurę podczas zapobieganiu zakażeniom szpitalnym.” \\n\\n Reybrouck, 1983',
  stats: {
    progress: 0,
    issues: 3,
    topics: 13,
    quizzes: 1,
  },
  is_available: true,
  certificate_url: null,
  issues: [
    {
      id: 1,
      path: 'microbiology',
      name: 'MIKROBIOLOGIA',
      is_done: false,
      is_available: true,
      topics: [
        {
          id: 1,
          path: 'hospital-infections',
          name: 'Zakażenia szpitalne',
          is_done: false,
          is_available: true,
        },
        {
          id: 2,
          path: 'hand-borne-microorganisms',
          name: 'Mikroorganizmy przenoszone przez ręce',
          is_done: false,
          is_available: true,
        },
      ],
    },
    {
      id: 2,
      path: 'hand-disinfection-and-washing',
      name: 'DEZYNFEKCJA I MYCIE RĄK',
      is_done: false,
      is_available: true,
      topics: [
        {
          id: 3,
          path: 'preparing-your-hands-to-work',
          name: 'Przygotowanie dłoni do pracy',
          is_done: false,
          is_available: true,
        },
        {
          id: 4,
          path: 'proper-hand-disinfection',
          name: 'Prawidłowa dezynfekcja rąk',
          is_done: false,
          is_available: true,
        },
        {
          id: 5,
          path: 'proper-hand-washing',
          name: 'Prawidłowe mycie rąk',
          is_done: false,
          is_available: true,
        },
        {
          id: 6,
          path: 'disinfection-and-washing',
          name: 'Dezynfekcja a mycie',
          is_done: false,
          is_available: true,
        },
        {
          id: 7,
          path: 'hand-care',
          name: 'Pielęgnacja rąk',
          is_done: false,
          is_available: true,
        },
        {
          id: 8,
          path: 'hand-hygiene-and-medical-gloves',
          name: 'Higiena rąk a stosowanie rękawic medycznych',
          is_done: false,
          is_available: true,
        },
      ],
    },
    {
      id: 3,
      path: 'moments-of-hand-hygiene-animations',
      name: '5 MOMENTÓW HIGIENY RĄK WG WHO - ANIMACJE INSTRUKTAŻOWE',
      is_done: false,
      is_available: true,
      topics: [
        {
          id: 9,
          path: 'moments-of-hand-hygiene',
          name: '5 momentów higieny rąk',
          is_done: false,
          is_available: true,
        },
        {
          id: 10,
          path: 'scenario-1',
          name: 'Scenariusz 1',
          is_done: false,
          is_available: true,
        },
        {
          id: 11,
          path: 'scenario-2',
          name: 'Scenariusz 2',
          is_done: false,
          is_available: true,
        },
        {
          id: 12,
          path: 'scenario-3',
          name: 'Scenariusz 3',
          is_done: false,
          is_available: true,
        },
        {
          id: 13,
          path: 'scenario-4',
          name: 'Scenariusz 4',
          is_done: false,
          is_available: true,
        },
      ],
    },
    {
      id: 4,
      path: 'q',
      name: null,
      is_done: false,
      is_available: true,
      topics: [
        {
          id: 14,
          path: 'quiz',
          name: 'Quiz podsumowujący',
          is_done: false,
          is_available: true,
        },
      ],
    },
  ],
};

// course statistics
export interface CourseStatistics {
  progress: number;
  issues: number;
  topisc: number;
  quizzes: number;
  hasCertificate: boolean;
  isCertiificateAvailable: boolean;
}

export const courseStatistics: CourseStatistics = {
  progress: 1,
  issues: 3,
  topisc: 13,
  quizzes: 1,
  hasCertificate: true, // is there option to get certificate in this course - in order to display text in statistics
  isCertiificateAvailable: false, // if true (quiz is done) - show download button
};

export interface Points {
  obtained?: number;
  total: number;
}

// tasks
export interface Task {
  id: number;
  type: TaskType;
  is_available: boolean;
  is_done: boolean;
  user_answer: UserAnswer;
  content?: Content;
  points: Points;
}

export type TaskType =
  | 'challenge_put_in_correct_order_images'
  | 'challenge_match_image_with_description'
  | 'challenge_match_text_with_description'
  | 'challenge_choose_one_answer'
  | 'challenge_choose_many_answers';

export const DnDTypes = {
  PUT_IN_CORRECT_ORDER_IMAGES: 'PUT_IN_CORRECT_ORDER_IMAGES',
  MATCH_IMAGE_WITH_DESCRIPTION: 'MATCH_IMAGE_WITH_DESCRIPTION',
};
export interface Content {
  [key: string]: {
    [key: string]:
      | string
      | {
          [key: string]: string;
        };
    question?: string;
    answers?: {
      [key: string]: string;
    };
    image_url?: string;
    description?: string;
    images?: { [key: string]: string };
  };
}

export interface UserAnswer {
  [key: string]: string[];
}

export interface CurrentAnswer {
  [questionId: string]: string[];
}
export interface Answer {
  id: number;
  type: TaskType;
  is_done: boolean;
  is_available: boolean;
  content?: Content;
  correct_answer?: {
    [key: string]: string[];
  };
  user_answer?: UserAnswer;
  points: Points;
}
export interface DnDOrder {
  order: string[];
}

export interface MatchTextWithDescriptionCurrentAnswer {
  [key: string]: string;
}

export type CurrentAnswerPayload =
  | CurrentAnswer
  | DnDOrder
  | MatchTextWithDescriptionCurrentAnswer;

import { ERROR_MESSAGES } from 'constants/validation-error-messages';
import * as yup from 'yup';

export const contactSchema = yup.object({
  workplace_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  first_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  last_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  email: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.EMAIL),
  phone: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(9, ERROR_MESSAGES.PHONE_LENGHT),
  message: yup.string(),
  checkbox_rodo: yup.boolean().oneOf([true], ERROR_MESSAGES.REQUIRED),
  checkbox_terms: yup.boolean().oneOf([true], ERROR_MESSAGES.REQUIRED),
  checkbox_marketing: yup.boolean().oneOf([true], ERROR_MESSAGES.REQUIRED),
  checkbox_consent: yup.boolean().oneOf([true], ERROR_MESSAGES.REQUIRED),
});

import React, { FC, HTMLAttributes } from 'react';
import cls from './box.module.scss';
import clsx from 'clsx';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const Box: FC<IProps> = ({ className, children, ...props }) => {
  const clsButton = clsx(cls.box, {
    [className]: Boolean(className),
  });

  return (
    <div {...props} className={clsButton}>
      {children}
    </div>
  );
};

import { PATHS } from 'constants/routes';
import { useUser } from 'hooks/useUser';
import { Navigate, useLocation } from 'react-router-dom';
import { Permission } from 'types/user';

interface IProps {
  children: JSX.Element;
  permission?: Permission;
}

const PrivateRoute = ({ children, permission }: IProps) => {
  const location = useLocation();
  const { isAuthenticated, hasUserRequiredPermissions } = useUser();

  if (!isAuthenticated) {
    return <Navigate to={PATHS.LOGIN} state={{ from: location }} />;
  }

  if (!hasUserRequiredPermissions(permission)) {
    return <Navigate to={PATHS.HOME} state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;

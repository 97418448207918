import React from 'react';
import { useTranslation } from 'react-i18next';

export const RegistrationClause = () => {
  const { t } = useTranslation();
  return (
    <p>
      {t('registration.accordion.p1')}
      <br />
      <br />
      {t('registration.accordion.p2')}
      <br />
      <br />
      {t('registration.accordion.p3')}
      <br />
      <br />
      {t('registration.accordion.p4')}
      <br />
      <br />
      {t('registration.accordion.p5')}
      <br />
      <br />
      {t('registration.accordion.p6')}
      <br />
      <br />
      {t('registration.accordion.p7')}
      <br />
      <br />
      {t('registration.accordion.p8')}
      <br />
      <br />
      {t('registration.accordion.p9')}
      <br />
      <br />
      {t('registration.accordion.p10')}
      <br />
      <br />
      {t('registration.accordion.p11')}
      <br />
      <br />
      {t('registration.accordion.p12')}
      <br />
      <br />
      {t('registration.accordion.p13')}
      <br />
      <br />
      {t('registration.accordion.p14')}
      <br />
      <br />
      {t('registration.accordion.p15')}
      <br />
      <br />
      {t('registration.accordion.p16')}
      <br />
      <br />
      {t('registration.accordion.p17')}
      <br />
      {t('registration.accordion.p18')}
      <br />
      {t('registration.accordion.p19')}{' '}
      <a href="mailto:aktualnosci@ecolab.com">aktualnosci@ecolab.com</a>
    </p>
  );
};

import calculateConsumptionTranslation from './calculate-consumption/index.json';
import expandYourKnowledgeTranslation from './expand-your-knowledge/index.json';
import getToKnowActionTranslation from './get-to-know-action/index.json';
import healthcareTranslation from './healthcare/index.json';
import howToJoinTranslation from './how-to-join/index.json';
import whatYouGainTranslation from './what-you-gain/index.json';

const aboutActionTranslation = {
  calculateConsumption: calculateConsumptionTranslation,
  expandYourKnowledge: expandYourKnowledgeTranslation,
  getToKnowAction: getToKnowActionTranslation,
  healthcare: healthcareTranslation,
  howToJoin: howToJoinTranslation,
  whatYouGain: whatYouGainTranslation,
};

export default aboutActionTranslation;

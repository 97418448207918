import { TasksGenerator } from 'components/elearning/tasks/tasks-generator';
import { TopicStatusWithButton } from 'components/elearning/topic-status-with-button';
import { Carousel } from 'components/universal/carousel';
import Heading from 'components/universal/heading';
import { useTopic } from 'hooks/useTopic';
import React from 'react';

import img1 from 'assets/images/elearning/moments-of-hand-hygiene/5_m_1a.png';
import img2 from 'assets/images/elearning/moments-of-hand-hygiene/5_m_1b.png';
import img3 from 'assets/images/elearning/moments-of-hand-hygiene/5_m_2a.png';
import img4 from 'assets/images/elearning/moments-of-hand-hygiene/5_m_2b.png';
import img5 from 'assets/images/elearning/moments-of-hand-hygiene/5_m_3a.png';
import img6 from 'assets/images/elearning/moments-of-hand-hygiene/5_m_3b.png';
import img7 from 'assets/images/elearning/moments-of-hand-hygiene/5_m_4a.png';
import img8 from 'assets/images/elearning/moments-of-hand-hygiene/5_m_4b.png';
import img9 from 'assets/images/elearning/moments-of-hand-hygiene/5_m_5a.png';
import img10 from 'assets/images/elearning/moments-of-hand-hygiene/5_m_5b.png';
import img1en from 'assets/images/elearning/moments-of-hand-hygiene/en/5_m_1a.png';
import img2en from 'assets/images/elearning/moments-of-hand-hygiene/en/5_m_1b.png';
import img3en from 'assets/images/elearning/moments-of-hand-hygiene/en/5_m_2a.png';
import img4en from 'assets/images/elearning/moments-of-hand-hygiene/en/5_m_2b.png';
import img5en from 'assets/images/elearning/moments-of-hand-hygiene/en/5_m_3a.png';
import img6en from 'assets/images/elearning/moments-of-hand-hygiene/en/5_m_3b.png';
import img7en from 'assets/images/elearning/moments-of-hand-hygiene/en/5_m_4a.png';
import img8en from 'assets/images/elearning/moments-of-hand-hygiene/en/5_m_4b.png';
import img9en from 'assets/images/elearning/moments-of-hand-hygiene/en/5_m_5a.png';
import img10en from 'assets/images/elearning/moments-of-hand-hygiene/en/5_m_5b.png';
import { useTranslation } from 'react-i18next';
import { getMediaLanguage } from 'utils/language';

export const FiveMomentsHandHygiene = () => {
  const { t, i18n } = useTranslation('', {
    keyPrefix:
      'elearning.handHygiene.momentsOfHandHygieneAnimations.momentsOfHandHygiene',
  });
  const { tasks, correctAnswer, isAnswerChecked } = useTopic();

  const MOMENTS_IMGS = {
    PL: [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10],
    ENG: [
      img1en,
      img2en,
      img3en,
      img4en,
      img5en,
      img6en,
      img7en,
      img8en,
      img9en,
      img10en,
    ],
  };

  const getImg = (imgIndex: number): string =>
    MOMENTS_IMGS[getMediaLanguage(i18n.language)][imgIndex];

  const carouselItems = [
    { original: getImg(0) },
    { original: getImg(1) },
    { original: getImg(2) },
    { original: getImg(3) },
    { original: getImg(4) },
    { original: getImg(5) },
    { original: getImg(6) },
    { original: getImg(7) },
    { original: getImg(8) },
    { original: getImg(9) },
  ];

  return (
    <div>
      <Heading className="mb-5" headingLevel="h1" headingStyle="h3">
        {t('title1')}
      </Heading>
      <Carousel items={carouselItems} />
      <div className="divider" />
      <Heading className="mb-10" headingLevel="h3">
        {t('title2')}
      </Heading>
      <TasksGenerator
        tasks={tasks}
        isChecked={isAnswerChecked}
        correctAnswer={correctAnswer}
      />
      <TopicStatusWithButton />
    </div>
  );
};

import React from 'react';
import { EmailVeryficationSuccessView } from 'views/email-verification-success';

export const EmailVeryficationSuccess = () => {
  return (
    <div>
      <EmailVeryficationSuccessView />
    </div>
  );
};

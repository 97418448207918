import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface IProps {
  title?: string;
  description?: string;
}

export const CustomHeader: FC<IProps> = ({
  title = 'Ecolab',
  description = 'Ecolab application',
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

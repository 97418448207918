import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/universal/button';
import Heading from 'components/universal/heading';
import { Input } from 'components/universal/input';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import cls from './calculate-consumption.module.scss';
import { schema } from './validation';

interface ICalculatorFormValues {
  bedsNumber: string;
  averageOccupancy: string;
  medicalPersonelNumber: string;
  averageProceduresPerDay: string;
  consumptionPerMonth: string;
  consumptionPerYear: string;
}

const defaultValues = {
  bedsNumber: '',
  averageOccupancy: '',
  medicalPersonelNumber: '',
  averageProceduresPerDay: '',
  consumptionPerMonth: '',
  consumptionPerYear: '',
};

type Regex = keyof typeof REGEXS;

const REGEXS = {
  positiveIntegerRegex: /^\d*$/,
  positiveOneDecimalsRegex: /^\d*[.,]?\d{0,1}$/,
};

export const CalculateConsumption = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<ICalculatorFormValues>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'onBlur',
  });

  const { t } = useTranslation();

  const [isResultsBoxShown, setIsResultsBoxShown] = useState(false);
  const [rowHeight, setRowHeight] = useState([0, 0, 0, 0]);
  const [debouncedRowHeight, setDebouncedRowHeight] = useState([0, 0, 0, 0]);
  const [, cancel] = useDebounce(
    () => {
      setDebouncedRowHeight(rowHeight);
    },
    30,
    [rowHeight]
  );

  const row1Ref = useRef<HTMLDivElement | null>(null);
  const row2Ref = useRef<HTMLDivElement | null>(null);
  const row3Ref = useRef<HTMLDivElement | null>(null);
  const row4Ref = useRef<HTMLDivElement | null>(null);

  const resizeWindow = useCallback(() => {
    const windowWidth = window.innerWidth;
    // if touch screen reset height to auto
    if (windowWidth <= 1024) return setRowHeight([0, 0, 0, 0]);
    const heights = [
      row1Ref?.current?.offsetHeight || 0,
      row2Ref?.current?.offsetHeight || 0,
      row3Ref?.current?.offsetHeight || 0,
      row4Ref?.current?.offsetHeight || 0,
    ];
    setRowHeight(heights);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeWindow);
    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  useEffect(() => {
    resizeWindow();
  }, [isResultsBoxShown]);

  useEffect(() => {
    return () => {
      cancel();
    };
  }, []);

  const onSubmit = () => setIsResultsBoxShown(true);

  const formatText = (text: string) =>
    text.replace(/^0+/, '').replace(/,/g, '.');

  const setInputValue = (
    targetValue: string,
    formField: keyof ICalculatorFormValues,
    {
      maxValue = 100000,
      regex = 'positiveIntegerRegex',
    }: { maxValue?: number; regex?: Regex; multiplyValue?: number }
  ) => {
    // remove leading 0 and replace comma with dot
    let newValue = formatText(targetValue);
    if (REGEXS[regex].test(newValue)) {
      if (parseFloat(newValue) > maxValue) {
        newValue = maxValue.toString();
      }
      setValue(formField, newValue);
    }
  };

  const setRelatedFieldValue = (
    text: string,
    formField: keyof ICalculatorFormValues,
    multiplyValue: number
  ) => {
    if (text.slice(-1) !== ',' && text.slice(-1) !== '.') {
      let newValue: number | string =
        parseFloat(text.replace(/^0+/, '')) * multiplyValue;

      if (isNaN(newValue)) {
        newValue = '';
      } else {
        newValue = newValue.toFixed(1).toString();
      }

      setValue(formField, newValue as string);
    }
  };

  const watchInt = (formField: keyof ICalculatorFormValues) => {
    return parseInt(watch(formField));
  };

  const normalizeResult = (value: number) => Math.round(value * 10) / 10;

  const getUsePerOneProcedure = () => {
    const total =
      ((watchInt('consumptionPerMonth') / 30) * 1000) /
      (((watchInt('bedsNumber') * watchInt('averageOccupancy')) / 100) *
        watchInt('averageProceduresPerDay'));

    return normalizeResult(total);
  };

  const getDailyUsePerOneWorker = () => {
    const total =
      ((watchInt('consumptionPerMonth') / 30) * 1000) /
      watchInt('medicalPersonelNumber');

    return normalizeResult(total);
  };

  const getDailyUsePerOneWorkerIdeal = () => {
    const total =
      (3 *
        watchInt('bedsNumber') *
        (watchInt('averageOccupancy') / 100) *
        watchInt('averageProceduresPerDay')) /
      watchInt('medicalPersonelNumber');

    return normalizeResult(total);
  };

  const getDailyProceduresPerOneWorker = () => {
    const total =
      (watchInt('bedsNumber') *
        (watchInt('averageOccupancy') / 100) *
        watchInt('averageProceduresPerDay')) /
      watchInt('medicalPersonelNumber');

    return normalizeResult(total);
  };

  const getDesinfectantUsePerMonth = () =>
    normalizeResult(Number(watch('consumptionPerMonth')));

  const getDesinfectantUsePerYear = () =>
    normalizeResult(Number(watch('consumptionPerYear')));

  const getDesinfectantUsePerDayIdeal = () => {
    const total =
      0.003 *
      watchInt('bedsNumber') *
      (watchInt('averageOccupancy') / 100) *
      watchInt('averageProceduresPerDay');

    return total;
  };

  const getDesinfectantUsePerMonthIdeal = () => {
    const total = getDesinfectantUsePerDayIdeal() * 30;

    return normalizeResult(total);
  };

  const getDesinfectantUsePerYearIdeal = () => {
    const total = getDesinfectantUsePerDayIdeal() * 365;

    return normalizeResult(total);
  };

  return (
    <div className={cls.wrapper}>
      <div className={cls.container}>
        <Heading headingLevel="h2" className={cls.header}>
          {t('aboutAction.calculateConsumption.title')}
        </Heading>
        <div className={cls.calculator}>
          <Heading headingLevel="h3" className={cls.headerData}>
            {t('aboutAction.calculateConsumption.calculator.title')}
          </Heading>
          <form className={cls.form} onSubmit={handleSubmit(onSubmit)}>
            <Input
              className={cls.input}
              register={register}
              id="bedsNumber"
              name="bedsNumber"
              label={t('aboutAction.calculateConsumption.calculator.beds')}
              error={t(errors.bedsNumber?.message)}
              value={watch('bedsNumber')}
              onChange={(e) => setInputValue(e.target.value, 'bedsNumber', {})}
            />
            <Input
              className={cls.input}
              register={register}
              id="averageOccupancy"
              name="averageOccupancy"
              label={t('aboutAction.calculateConsumption.calculator.load')}
              error={t(errors.averageOccupancy?.message)}
              value={watch('averageOccupancy')}
              onChange={(e) =>
                setInputValue(e.target.value, 'averageOccupancy', {
                  maxValue: 100,
                  regex: 'positiveOneDecimalsRegex',
                })
              }
            />
            <Input
              className={cls.input}
              register={register}
              id="medicalPersonelNumber"
              name="medicalPersonelNumber"
              label={t('aboutAction.calculateConsumption.calculator.personel')}
              error={t(errors.medicalPersonelNumber?.message)}
              value={watch('medicalPersonelNumber')}
              onChange={(e) =>
                setInputValue(e.target.value, 'medicalPersonelNumber', {})
              }
            />
            <Input
              className={cls.input}
              register={register}
              id="averageProceduresPerDay"
              name="averageProceduresPerDay"
              label={t(
                'aboutAction.calculateConsumption.calculator.procedures'
              )}
              error={t(errors.averageProceduresPerDay?.message)}
              value={watch('averageProceduresPerDay')}
              onChange={(e) =>
                setInputValue(e.target.value, 'averageProceduresPerDay', {
                  maxValue: 100,
                  regex: 'positiveOneDecimalsRegex',
                })
              }
            />
            <Input
              className={cls.input}
              register={register}
              id="consumptionPerMonth"
              name="consumptionPerMonth"
              label={t(
                'aboutAction.calculateConsumption.calculator.usageMonthly'
              )}
              error={t(errors.consumptionPerMonth?.message)}
              value={watch('consumptionPerMonth')}
              onChange={(e) => {
                const targetValue = formatText(e.target.value);
                setInputValue(e.target.value, 'consumptionPerMonth', {
                  regex: 'positiveOneDecimalsRegex',
                });
                setRelatedFieldValue(targetValue, 'consumptionPerYear', 12);
              }}
            />
            <Input
              className={cls.input}
              register={register}
              id="consumptionPerYear"
              name="consumptionPerYear"
              label={t(
                'aboutAction.calculateConsumption.calculator.usageAnnualy'
              )}
              error={t(errors.consumptionPerYear?.message)}
              value={watch('consumptionPerYear')}
              onChange={(e) => {
                const targetValue = formatText(e.target.value);
                setInputValue(e.target.value, 'consumptionPerYear', {
                  regex: 'positiveOneDecimalsRegex',
                });
                setRelatedFieldValue(
                  targetValue,
                  'consumptionPerMonth',
                  1 / 12
                );
              }}
            />
            <p className={cls.requiredText}>
              {t('aboutAction.calculateConsumption.calculator.requiredFields')}
            </p>
            <Button className={cls.button} type="submit" disabled={!isDirty}>
              {t('aboutAction.calculateConsumption.calculator.button')}
            </Button>
          </form>

          {isResultsBoxShown && (
            <div className={cls.results}>
              <Heading headingLevel="h3" className={cls.heading1}>
                {t('aboutAction.calculateConsumption.calculator.results.title')}
              </Heading>
              <div className={cls.real}>
                <div className={cls.row} ref={row1Ref}>
                  <span>
                    {t(
                      'aboutAction.calculateConsumption.calculator.results.row1'
                    )}
                  </span>
                  <span>
                    <b>{getUsePerOneProcedure() || ''}</b>{' '}
                    {t(
                      'aboutAction.calculateConsumption.calculator.results.row1Unit'
                    )}
                  </span>
                </div>

                <div className={cls.row} ref={row2Ref}>
                  <span>
                    {t(
                      'aboutAction.calculateConsumption.calculator.results.row2'
                    )}
                  </span>
                  <span>
                    <b>{getDailyUsePerOneWorker() || ''}</b>{' '}
                    {t(
                      'aboutAction.calculateConsumption.calculator.results.row2Unit'
                    )}
                  </span>
                </div>

                <div className={cls.row} ref={row3Ref}>
                  <span>
                    {t(
                      'aboutAction.calculateConsumption.calculator.results.row3'
                    )}
                  </span>
                  <span>
                    <b>{getDailyProceduresPerOneWorker() || ''}</b>{' '}
                    {t(
                      'aboutAction.calculateConsumption.calculator.results.row3Unit'
                    )}
                  </span>
                </div>

                <div className={cls.row} ref={row4Ref}>
                  <span>
                    <b>
                      {t(
                        'aboutAction.calculateConsumption.calculator.results.row4a'
                      )}
                    </b>
                  </span>
                  <span className="mb-4">
                    <b>{getDesinfectantUsePerMonth() || ''}</b>{' '}
                    {t(
                      'aboutAction.calculateConsumption.calculator.results.row4aUnit'
                    )}
                  </span>

                  <span>
                    <b>
                      {t(
                        'aboutAction.calculateConsumption.calculator.results.row4b'
                      )}
                    </b>
                  </span>
                  <span>
                    <b>{getDesinfectantUsePerYear() || ''}</b>{' '}
                    {t(
                      'aboutAction.calculateConsumption.calculator.results.row4bUnit'
                    )}
                  </span>
                </div>
              </div>

              <Heading headingLevel="h3" className={cls.heading2}>
                {t('aboutAction.calculateConsumption.calculator.ideal.title')}
              </Heading>
              <div className={cls.ideal}>
                <div
                  className={cls.rowWithOneItem}
                  style={{ height: debouncedRowHeight[0] || 'auto' }}
                >
                  <span>
                    <b>3</b>{' '}
                    {t(
                      'aboutAction.calculateConsumption.calculator.ideal.row1'
                    )}
                  </span>
                </div>

                <div
                  className={cls.rowWithOneItem}
                  style={{ height: debouncedRowHeight[1] || 'auto' }}
                >
                  <span>
                    <b>{getDailyUsePerOneWorkerIdeal() || ''}</b>{' '}
                    {t(
                      'aboutAction.calculateConsumption.calculator.ideal.row2'
                    )}
                  </span>
                </div>

                <div
                  className={cls.rowWithOneItem}
                  style={{ height: debouncedRowHeight[2] || 'auto' }}
                >
                  <span>
                    <b>{getDailyProceduresPerOneWorker() || ''}</b>{' '}
                    {t(
                      'aboutAction.calculateConsumption.calculator.ideal.row3'
                    )}
                  </span>
                </div>

                <div
                  className={cls.rowWithOneItem}
                  style={{ height: debouncedRowHeight[3] || 'auto' }}
                >
                  <div className="mb-4">
                    <b>{getDesinfectantUsePerMonthIdeal() || ''}</b>{' '}
                    {t(
                      'aboutAction.calculateConsumption.calculator.ideal.row4a'
                    )}
                  </div>
                  <span>
                    <b>{getDesinfectantUsePerYearIdeal() || ''}</b>{' '}
                    {t(
                      'aboutAction.calculateConsumption.calculator.ideal.row4b'
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

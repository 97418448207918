import clsx from 'clsx';
import Paragraph from 'components/universal/paragraph';
import React, { FC } from 'react';
import cls from './question.module.scss';

interface IProps {
  questionNumber: number;
  title: string;
  answers: { [key: string]: string };
  checkedAnswers: string[];
  correctAnswers?: string[];
  isChecked?: boolean;
  setAnswers?: (answerId) => void;
  className?: string;
}

export const Question: FC<IProps> = ({
  questionNumber,
  title,
  answers,
  checkedAnswers,
  correctAnswers,
  isChecked,
  setAnswers,
  className,
}) => {
  const clsWrapper = clsx(cls.wrapper, {
    [className]: Boolean(className),
  });

  return (
    <div className={clsWrapper}>
      <div className={cls.headingWrapper}>
        <Paragraph className={cls.bullet}>{questionNumber}</Paragraph>
        <Paragraph fontWeight={700}>{title}</Paragraph>
      </div>
      <ul>
        {answers &&
          Object?.entries?.(answers)?.map?.(([id, answer]) => (
            <li
              key={id}
              className={clsx(cls.answer, {
                [cls.answerChecked]: checkedAnswers?.includes?.(id),
                [cls.answerError]:
                  isChecked &&
                  correctAnswers &&
                  checkedAnswers?.includes?.(id) &&
                  !correctAnswers?.includes?.(id),
                [cls.answerCorrect]:
                  isChecked &&
                  correctAnswers?.includes?.(id) &&
                  checkedAnswers?.includes?.(id),
              })}
            >
              <button
                className={cls.button}
                onClick={() => setAnswers(id)}
                disabled={isChecked}
              >
                <Paragraph className={cls.answerText}>{answer}</Paragraph>
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
};

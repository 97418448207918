import {
  ERROR_MESSAGES,
  PASSWORD_REGEX,
} from 'constants/validation-error-messages';
import * as yup from 'yup';

export const schema = yup.object({
  new_password: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .matches(PASSWORD_REGEX, ERROR_MESSAGES.PASSWORD_STRENGTH),
  new_password_repeat: yup
    .string()
    .oneOf([yup.ref('new_password'), null], ERROR_MESSAGES.PASSWORD_MATCH),
});

import { CustomHeader } from 'components/universal/custom-header';
import { useTopic } from 'hooks/useTopic';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnauthorizedUser } from 'views/disinfection-techniques/unauthorized-user';
import { NoCoursePermission } from 'views/no-course-permission';
import { Issues } from 'views/online-trainings/hand-hygiene/issues';
import { Overview } from 'views/online-trainings/hand-hygiene/overview';

export const HandHygiene = () => {
  const { isAuthenticated, hasElearningPermission } = useUser();

  if (!isAuthenticated) return <UnauthorizedUser />;
  if (!hasElearningPermission) return <NoCoursePermission />;
  return <Course />;
};

const Course = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'SEO.handHygiene',
  });
  const { courseData, continueCourse } = useTopic();
  if (!courseData) return null;
  return (
    <>
      <CustomHeader title={t('title')} />
      <Overview courseData={courseData} continueCourse={continueCourse} />
      <Issues courseData={courseData} />
    </>
  );
};

import { TasksGenerator } from 'components/elearning/tasks/tasks-generator';
import { TopicStatusWithButton } from 'components/elearning/topic-status-with-button';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import { useTopic } from 'hooks/useTopic';
import React from 'react';
import cls from './disinfection-and-washing.module.scss';
import { useTranslation } from 'react-i18next';

import waterImg from 'assets/images/elearning/disinfection-and-washing/water.png';
import soapImg from 'assets/images/elearning/disinfection-and-washing/soap.png';
import sanitizerImg from 'assets/images/elearning/disinfection-and-washing/sanitizer.png';
import chartImg from 'assets/images/elearning/disinfection-and-washing/chart.png';
import chartImgEn from 'assets/images/elearning/disinfection-and-washing/en/chart.png';
import sinkImg from 'assets/images/elearning/disinfection-and-washing/sink.png';
import difficileImg from 'assets/images/elearning/disinfection-and-washing/difficile.png';
import whenToWashImg from 'assets/images/elearning/disinfection-and-washing/when-to-wash.png';
import { Carousel } from 'components/universal/carousel';
import { getMediaLanguage } from 'utils/language';

export const DisinfectionAndWashing = () => {
  const { t, i18n } = useTranslation('', {
    keyPrefix:
      'elearning.handHygiene.handDisinfectionAndWashing.disinfectionAndWashing',
  });
  const { tasks, correctAnswer, isAnswerChecked } = useTopic();

  const IMGS = {
    PL: [chartImg],
    ENG: [chartImgEn],
  };

  const getImg = (imgIndex: number): string =>
    IMGS[getMediaLanguage(i18n.language)][imgIndex];

  const carousel1 = [{ original: sinkImg }];
  const carousel2 = [{ original: getImg(0) }];

  return (
    <div>
      <Heading className="mb-5" headingLevel="h1" headingStyle="h3">
        {t('title1')}
      </Heading>
      <ul className={cls.list}>
        <li>
          <Paragraph>{t('desc1')}</Paragraph>
        </li>
        <li>
          <Paragraph>{t('desc2')}</Paragraph>
        </li>
        <li>
          <Paragraph>{t('desc3')}</Paragraph>
        </li>
        <li>
          <Paragraph>{t('desc4')}</Paragraph>
        </li>
      </ul>

      <div className={cls.icons}>
        <div className={cls.iconWrapper}>
          <img src={waterImg} alt={t('img_alt1')} className={cls.icon} />
          <Paragraph className={cls.iconText}>{t('desc5')}</Paragraph>
        </div>
        <div className={cls.iconWrapper}>
          <img src={soapImg} alt={t('img_alt2')} className={cls.icon} />
          <Paragraph className={cls.iconText}>{t('desc5_1')}</Paragraph>
        </div>
        <div className={cls.iconWrapper}>
          <img src={sanitizerImg} alt={t('img_alt3')} className={cls.icon} />
          <Paragraph className={cls.iconText}>{t('desc6')}</Paragraph>
        </div>
      </div>

      <Carousel items={carousel1} showNav={false} showIndex={false} />
      <Paragraph className="mt-5 mb-10">{t('desc7')}</Paragraph>
      <Heading headingLevel="h3" className="mb-5">
        {t('desc8')}
      </Heading>
      <Carousel items={carousel2} showNav={false} showIndex={false} />
      <div className={cls.whenToWash}>
        <div className={cls.whenToWashLeft}>
          <Heading headingLevel="h3" className="mb-5">
            {t('title2')}
          </Heading>
          <div className="sm:hidden mb-5 mx-auto">
            <figure className={cls.icon}>
              <img src={whenToWashImg} alt={t('img_alt4')} />
            </figure>
          </div>
          <Paragraph className="mb-5">{t('desc9')}</Paragraph>
          <ul className={cls.list}>
            <li>
              <Paragraph>{t('desc10')}</Paragraph>
            </li>
            <li>
              <Paragraph>{t('desc11')}</Paragraph>
            </li>
            <li>
              <Paragraph>{t('desc12')}</Paragraph>
            </li>
            <li>
              <Paragraph>{t('desc13')}</Paragraph>
            </li>
          </ul>
        </div>
        <div className="hidden sm:block">
          <figure className={cls.icon}>
            <img src={whenToWashImg} alt={t('img_alt5')} />
          </figure>
        </div>
      </div>
      <div className={cls.difficile}>
        <div className="hidden sm:block">
          <figure className={cls.icon}>
            <img src={difficileImg} alt={t('img_alt6')} />
          </figure>
        </div>
        <div className={cls.difficileRight}>
          <Heading headingLevel="h3" className="mb-5">
            {t('title3')}
          </Heading>
          <div className="sm:hidden mx-auto mb-5">
            <figure className={cls.icon}>
              <img src={difficileImg} alt={t('img_alt6')} />
            </figure>
          </div>
          <Paragraph>{t('desc14')}</Paragraph>
        </div>
      </div>

      <div className="divider" />
      <Heading className="mb-10" headingLevel="h3">
        {t('title4')}
      </Heading>
      <TasksGenerator
        tasks={tasks}
        isChecked={isAnswerChecked}
        correctAnswer={correctAnswer}
      />
      <TopicStatusWithButton />
    </div>
  );
};

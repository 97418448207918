import clsx from 'clsx';
import React, { FC, InputHTMLAttributes } from 'react';
import cls from './radio.module.scss';

export type StyleType = 'secondary';
interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  styleType?: StyleType;
}

export const Radio: FC<IProps> = ({
  children,
  checked,
  id,
  className,
  ...props
}) => {
  const clsRadioLabel = clsx(cls.radioLabel, {
    [cls.radioLabelChecked]: checked,
    [className]: Boolean(className),
  });

  return (
    <>
      <input className={cls.radio} type="radio" id={id} {...props} />
      <label className={clsRadioLabel} htmlFor={id}>
        {children}
      </label>
    </>
  );
};

import { CourseName, CurrentAnswerPayload } from 'types/elearning';
import { request } from 'utils/request';

export const getCourseData = async (path: CourseName = 'hand-hygiene') =>
  await request(`/elearning/course/${path}`, {});

// path must be string in format /course/issue/topic
// e.g. /hand-hygiene/microbiology/hospital-infections
export const getTopic = async (path: string) =>
  await request(`/elearning/course${path}`, {});

export const sendAnswer = async (
  taskId: number,
  answers: CurrentAnswerPayload
) => await request(`/elearning/answer/${taskId}`, { method: 'POST' }, answers);

import { AxiosError, AxiosResponse } from 'axios';
import { CustomHeader } from 'components/universal/custom-header';
import { QUERY_KEYS } from 'constants/query-keys';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getArticles } from 'services/knowledge-base';
import { IKnowledgeBase } from 'types/knowledge-base';
import { queryKeyWithLanguage } from 'utils/language';
import { UnauthorizedUser } from 'views/disinfection-techniques/unauthorized-user';
import { Articles } from 'views/knowledge-base/articles';
import { CaseStudy } from 'views/knowledge-base/case-study';
import { Presentation } from 'views/knowledge-base/presentation';
import { WhyItMatters } from 'views/knowledge-base/why-it-matters';

export const KnowledgeBase = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'SEO.knowledgeBase',
  });
  const { isAuthenticated } = useUser();
  const { data } = useQuery<AxiosResponse<IKnowledgeBase>, AxiosError>(
    queryKeyWithLanguage(QUERY_KEYS.GET_KNOWLEDGE_BASE_ARTICLES),
    getArticles
  );
  if (!isAuthenticated) return <UnauthorizedUser />;
  return (
    <>
      <CustomHeader title={t('title')} />
      <WhyItMatters />
      <Presentation data={data?.data?.section_1} />
      <Articles data={data?.data?.section_2} />
      <CaseStudy data={data?.data?.section_3} />
    </>
  );
};

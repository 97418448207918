import clsx from 'clsx';
import React, { FC } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DnDTypes } from 'types/elearning';
import cls from './image-dnd.module.scss';

interface IProps {
  id: string;
  image_url: string;
  description: string;
  disabled: boolean;
  handleDrop: (dropzoneId: string, elementId: string) => void;
}

export const ImageDnd: FC<IProps> = ({
  image_url,
  description,
  id,
  disabled,
  handleDrop,
}) => {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: DnDTypes.PUT_IN_CORRECT_ORDER_IMAGES,
    canDrag: () => !disabled,
    item: {
      draggedElementId: id,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: DnDTypes.PUT_IN_CORRECT_ORDER_IMAGES,
    drop: (item: { draggedElementId: string }) =>
      handleDrop(id, item?.draggedElementId),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  const clsDropzone = clsx(cls.dropzone, {
    [cls.dropzoneOver]: isOver,
  });

  return (
    <div ref={dropRef} className={clsDropzone}>
      <figure
        className={cls.imageWrapper}
        ref={dragRef}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: 'move',
        }}
      >
        <img className={cls.image} src={image_url} alt={description} />
        <figcaption className={cls.description}>{description}</figcaption>
      </figure>
    </div>
  );
};

import { TasksGenerator } from 'components/elearning/tasks/tasks-generator';
import { TopicStatusWithButton } from 'components/elearning/topic-status-with-button';
import Heading from 'components/universal/heading';
import { useTopic } from 'hooks/useTopic';
import React from 'react';

import poresImg from 'assets/images/elearning/hand-hygiene-and-medical-gloves/pores.png';
import poresImgEn from 'assets/images/elearning/hand-hygiene-and-medical-gloves/en/pores.png';
import pyramid1Img from 'assets/images/elearning/hand-hygiene-and-medical-gloves/pyramid1.png';
import pyramid2Img from 'assets/images/elearning/hand-hygiene-and-medical-gloves/pyramid2.png';
import pyramid3Img from 'assets/images/elearning/hand-hygiene-and-medical-gloves/pyramid3.png';
import pyramid1ImgEn from 'assets/images/elearning/hand-hygiene-and-medical-gloves/en/pyramid1.png';
import pyramid2ImgEn from 'assets/images/elearning/hand-hygiene-and-medical-gloves/en/pyramid2.png';
import pyramid3ImgEn from 'assets/images/elearning/hand-hygiene-and-medical-gloves/en/pyramid3.png';
import { Carousel } from 'components/universal/carousel';
import Paragraph from 'components/universal/paragraph';
import { useTranslation } from 'react-i18next';
import { getMediaLanguage } from 'utils/language';

export const HandHygieneAndMedicalGloves = () => {
  const { t, i18n } = useTranslation('', {
    keyPrefix:
      'elearning.handHygiene.handDisinfectionAndWashing.handHygieneAndMedicalGloves',
  });
  const { tasks, correctAnswer, isAnswerChecked } = useTopic();

  const PYRAMID_IMGS = {
    PL: [pyramid1Img, pyramid2Img, pyramid3Img, poresImg],
    ENG: [pyramid1ImgEn, pyramid2ImgEn, pyramid3ImgEn, poresImgEn],
  };

  const getImg = (imgIndex: number): string =>
    PYRAMID_IMGS[getMediaLanguage(i18n.language)][imgIndex];

  const carousel1 = [{ original: getImg(3) }];
  const carousel2 = [{ original: getImg(0) }];
  const carousel3 = [{ original: getImg(1) }];
  const carousel4 = [{ original: getImg(2) }];

  return (
    <div>
      <Heading className="mb-5" headingLevel="h1" headingStyle="h3">
        {t('title1')}
      </Heading>
      <Carousel items={carousel1} showNav={false} showIndex={false} />
      <Heading headingLevel="h3" className="mt-10 mb-5">
        {t('title2')}
      </Heading>
      <Paragraph className="mb-5">{t('desc1')}</Paragraph>
      <Carousel items={carousel2} showNav={false} showIndex={false} />
      <Carousel items={carousel3} showNav={false} showIndex={false} />
      <Carousel items={carousel4} showNav={false} showIndex={false} />
      <div className="divider" />
      <Heading className="mb-10" headingLevel="h3">
        {t('title3')}
      </Heading>
      <TasksGenerator
        tasks={tasks}
        isChecked={isAnswerChecked}
        correctAnswer={correctAnswer}
      />
      <TopicStatusWithButton />
    </div>
  );
};

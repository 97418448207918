import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer } from '../footer';
import { Navigation } from '../navigation';
import cls from './layout.module.scss';

type Props = {
  children: ReactNode;
};

export const Layout = ({ children }: Props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className={cls.layout}>
      <Navigation />
      <main className={cls.mainContainer}>{children}</main>
      <Footer />
    </div>
  );
};

import React from 'react';
import cls from './why-is-hygiene-important.module.scss';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import washingHandsImg from 'assets/images/disinfection-techniques/washing-hands.png';
import { ReactComponent as HandSanitizerIcon } from 'assets/images/disinfection-techniques/hand-sanitizer.svg';
import { ReactComponent as ClockIcon } from 'assets/images/disinfection-techniques/clock.svg';
import { ReactComponent as WashingTechniqueIcon } from 'assets/images/disinfection-techniques/washing-technique.svg';
import { useTranslation } from 'react-i18next';

export const WhyIsHygieneImportant = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.container}>
      <div className={cls.topSection}>
        <div className={cls.topContent}>
          <Heading headingLevel="h1" className={cls.header}>
            {t('disinfectionTechnique.whyIsHygieneImportant.title')}
          </Heading>
          <figure className={cls.imageTablet}>
            <img src={washingHandsImg} alt="" />
          </figure>
          <Paragraph className={cls.desc}>
            {t('disinfectionTechnique.whyIsHygieneImportant.descA')}
          </Paragraph>
          <Paragraph className={cls.desc}>
            {t('disinfectionTechnique.whyIsHygieneImportant.descB')}
          </Paragraph>
        </div>
        <figure className={cls.image}>
          <img src={washingHandsImg} alt="" />
        </figure>
      </div>
      <Paragraph className={cls.divider}>
        {t('disinfectionTechnique.whyIsHygieneImportant.text')}
      </Paragraph>
      <div className={cls.bottomSection}>
        <div className={cls.bottomContent}>
          <HandSanitizerIcon className={cls.icon} />
          <Paragraph fontWeight={700}>
            {t('disinfectionTechnique.whyIsHygieneImportant.card1')}
          </Paragraph>
        </div>
        <div className={cls.bottomContent}>
          <ClockIcon className={cls.icon} />
          <Paragraph fontWeight={700}>
            {t('disinfectionTechnique.whyIsHygieneImportant.card2')}
          </Paragraph>
        </div>
        <div className={cls.bottomContent}>
          <WashingTechniqueIcon className={cls.icon} />
          <Paragraph fontWeight={700}>
            {t('disinfectionTechnique.whyIsHygieneImportant.card3')}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

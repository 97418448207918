import React from 'react';
import { CustomLink } from 'components/universal/custom-link';
import cls from './quiz.module.scss';
import { QuizData } from 'copy/home/hygiene-quiz';
import Paragraph from 'components/universal/paragraph';
import { useTranslation } from 'react-i18next';

type IProps = {
  data: QuizData;
  correctAnswers: number;
  QUIZ_POINTS_REPLACEMENT: string;
};

const Results: React.FC<IProps> = ({
  data,
  correctAnswers,
  QUIZ_POINTS_REPLACEMENT,
}) => {
  const { t } = useTranslation();
  const mapPoints = () => {
    const questionsAmount = Object.keys(data.questions).length;
    switch (questionsAmount) {
      case 1:
        return `${correctAnswers}/${questionsAmount} ${t(
          'home.quiz.points.one'
        )}`;
      case 2:
      case 3:
      case 4:
        return `${correctAnswers}/${questionsAmount} ${t(
          'home.quiz.points.twoFour'
        )}`;
      default:
        return `${correctAnswers}/${questionsAmount} ${t(
          'home.quiz.points.fiveMore'
        )}`;
    }
  };

  const replaceWithComponent = (text: string) => {
    // i assume there's gonna be only one replacement - one instance of QUIZ_POINTS_REPLACEMENT.
    const split = text.split(QUIZ_POINTS_REPLACEMENT);
    if (split.length === 1) return text;
    return (
      <>
        {split[0]}
        <b className={cls.points}>{mapPoints()}</b>
        {split[1]}
      </>
    );
  };

  return (
    <React.Fragment>
      {data.results[correctAnswers].split('\n').map((text) => (
        <Paragraph className={cls.result} key={text}>
          {replaceWithComponent(t(text))}
        </Paragraph>
      ))}
      <CustomLink className={cls.button} to={data.button.link}>
        {t(data.button.text)}
      </CustomLink>
    </React.Fragment>
  );
};

export default Results;

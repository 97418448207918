import { CustomHeader } from 'components/universal/custom-header';
import { Newsletter } from 'components/universal/newsletter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalculateConsumption } from 'views/about-action/calculate-consumption';
import { ExpandYourKnowledgeSection } from 'views/about-action/expand-your-knowledge';
import { GetToKnowActionSection } from 'views/about-action/get-to-know-action';
import { HealthcareSection } from 'views/about-action/healthcare';
import { HowToJoinSection } from 'views/about-action/how-to-join';
import { WhatYouGainSection } from 'views/about-action/what-you-gain';

export const AboutAction = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'SEO.aboutAction',
  });

  return (
    <>
      <CustomHeader title={t('title')} />
      <GetToKnowActionSection />
      <ExpandYourKnowledgeSection />
      <WhatYouGainSection />
      <CalculateConsumption />
      <HowToJoinSection />
      <HealthcareSection />
      <Newsletter />
    </>
  );
};

import { getProtectedFile } from 'services/knowledge-base';
import { ArticleType } from 'types/knowledge-base';

const downloadFile = (item: ArticleType) => {
  const anchor = document.createElement('a');
  getProtectedFile(item?.file_url)
    .then((res) => new Blob([res.data]))
    .then((blob) => {
      const objectUrl = window.URL.createObjectURL(blob);
      anchor.href = objectUrl;
      const fileName =
        item.title.length > 20
          ? `${item.title.slice(0, 20)}(...).pdf`
          : `${item.title}.pdf`;
      anchor.setAttribute('download', fileName);
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    });
};

export default downloadFile;

import { CustomLink } from 'components/universal/custom-link';
import { PATHS } from 'constants/routes';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserMenu } from '../user-menu';
import cls from './login-component.module.scss';

export const LoginComponent = () => {
  const { isAuthenticated } = useUser();
  const { t } = useTranslation();

  return (
    <div>
      {isAuthenticated ? (
        <UserMenu className={cls.link} />
      ) : (
        <CustomLink to={PATHS.LOGIN} className={cls.link}>
          {t('navigation.loginComponent.logIn')}
        </CustomLink>
      )}
    </div>
  );
};

import { ReactNode, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { store } from 'store/store';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import {
  DndProvider,
  TouchTransition,
  MouseTransition,
} from 'react-dnd-multi-backend';
import { Cookies } from 'components/Cookies';
import 'translations';

export const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
    },
  ],
};

type Props = {
  children: ReactNode;
};

export const Providers = ({ children }: Props) => {
  const queryClient = new QueryClient();

  useEffect(() => {
    document.addEventListener('contextmenu', (event: MouseEvent) => {
      const target = event?.target as HTMLElement;
      if (target?.nodeName === 'VIDEO') {
        event.preventDefault();
      }
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <DndProvider options={HTML5toTouch}>
            <Cookies />
            <ToastContainer position="bottom-center" theme="colored" />
            {children}
          </DndProvider>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

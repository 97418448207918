import { TopicStatus } from 'components/elearning/topic-status';
import { Button } from 'components/universal/button';
import { useTopic } from 'hooks/useTopic';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCanSubmitAnswer } from 'store/topic/topicSlice';

export const TopicStatusWithButton: FC = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'components.topicStatusWithButton',
  });
  const {
    checkAnswers,
    isQuestionDone,
    isSuccessSendAnswer,
    totalPoints,
    obtainedPoints,
    handleTryAgainTask,
    goToNextTopic,
  } = useTopic();

  const canSubmitAnswer = useSelector(selectCanSubmitAnswer);

  return (
    <Fragment>
      {isSuccessSendAnswer ? (
        <TopicStatus
          title={isQuestionDone ? t('titleDone') : t('titleNotDone')}
          buttonText={
            isQuestionDone ? t('buttonTextDone') : t('buttonTextNotDone')
          }
          obtainedPoints={obtainedPoints}
          maxScore={totalPoints}
          isSuccess={isQuestionDone}
          onSubmit={isQuestionDone ? goToNextTopic : handleTryAgainTask}
        />
      ) : (
        <Button
          className="mx-auto"
          onClick={checkAnswers}
          disabled={!canSubmitAnswer}
        >
          {t('checkButtonText')}
        </Button>
      )}
    </Fragment>
  );
};

import React from 'react';
import { EmailVeryficationErrorView } from 'views/email-verification-error';

export const EmailVeryficationError = () => {
  return (
    <div>
      <EmailVeryficationErrorView />
    </div>
  );
};

import { TasksGenerator } from 'components/elearning/tasks/tasks-generator';
import { TopicStatusWithButton } from 'components/elearning/topic-status-with-button';
import { Carousel } from 'components/universal/carousel';
import Heading from 'components/universal/heading';
import { useTopic } from 'hooks/useTopic';
import React from 'react';
import { useTranslation } from 'react-i18next';

import img1 from 'assets/images/elearning/microorganisms/img1.png';
import img2 from 'assets/images/elearning/microorganisms/img2.png';
import img3 from 'assets/images/elearning/microorganisms/img3.png';
import img4 from 'assets/images/elearning/microorganisms/img4.png';
import img5 from 'assets/images/elearning/microorganisms/img5.png';
import img6 from 'assets/images/elearning/microorganisms/img6.png';
import img7 from 'assets/images/elearning/microorganisms/img7.png';
import img8 from 'assets/images/elearning/microorganisms/img8.png';
import img1en from 'assets/images/elearning/microorganisms/en/img1.png';
import img2en from 'assets/images/elearning/microorganisms/en/img2.png';
import img3en from 'assets/images/elearning/microorganisms/en/img3.png';
import img4en from 'assets/images/elearning/microorganisms/en/img4.png';
import img5en from 'assets/images/elearning/microorganisms/en/img5.png';
import img6en from 'assets/images/elearning/microorganisms/en/img6.png';
import img7en from 'assets/images/elearning/microorganisms/en/img7.png';
import img8en from 'assets/images/elearning/microorganisms/en/img8.png';
import { getMediaLanguage } from 'utils/language';

const IMGS = {
  PL: [img1, img2, img3, img4, img5, img6, img7, img8],
  ENG: [img1en, img2en, img3en, img4en, img5en, img6en, img7en, img8en],
};

export const HandBorneMicroorganisms = () => {
  const { t, i18n } = useTranslation('', {
    keyPrefix: 'elearning.handHygiene.microbiology.handBorneMicroorganisms',
  });
  const { tasks, correctAnswer, isAnswerChecked } = useTopic();

  const getImg = (imgIndex: number): string =>
    IMGS[getMediaLanguage(i18n.language)][imgIndex];

  const carouselItems = [
    { original: getImg(0) },
    { original: getImg(1) },
    { original: getImg(2) },
    { original: getImg(3) },
    { original: getImg(4) },
    { original: getImg(5) },
    { original: getImg(6) },
    { original: getImg(7) },
  ];

  return (
    <div>
      <Heading className="mb-5" headingLevel="h1" headingStyle="h3">
        {t('title')}
      </Heading>
      <Carousel items={carouselItems} />
      <div className="divider" />
      <Heading className="mb-10" headingLevel="h3">
        {t('title2')}
      </Heading>
      <TasksGenerator
        tasks={tasks}
        isChecked={isAnswerChecked}
        correctAnswer={correctAnswer}
      />
      <TopicStatusWithButton />
    </div>
  );
};

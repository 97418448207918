import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import english from './en';
import polish from './pl';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      pl: {
        translation: polish,
      },
      eng: {
        translation: english,
      },
    },
    fallbackLng: 'pl',
  });

import { CustomHeader } from 'components/universal/custom-header';
import { PATHS } from 'constants/routes';
import { useUser } from 'hooks/useUser';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginView } from 'views/login';

export const Login = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'SEO.login',
  });
  const { isAuthenticated } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const shouldRedirectUser =
      isAuthenticated && location.pathname === PATHS.LOGIN;

    if (shouldRedirectUser) {
      navigate(PATHS.HOME);
    }
  }, []);

  return (
    <div>
      <CustomHeader title={t('title')} />
      <LoginView />
    </div>
  );
};

import { TasksGenerator } from 'components/elearning/tasks/tasks-generator';
import { TopicStatusWithButton } from 'components/elearning/topic-status-with-button';
import Heading from 'components/universal/heading';
import { useTopic } from 'hooks/useTopic';
import React from 'react';
import { useTranslation } from 'react-i18next';

import handsImg from 'assets/images/elearning/hand-care/hands.png';
import careImg from 'assets/images/elearning/hand-care/care.png';
import regenerationImg from 'assets/images/elearning/hand-care/regeneration.png';
import protectionImg from 'assets/images/elearning/hand-care/protection.png';
import { Carousel } from 'components/universal/carousel';
import Paragraph from 'components/universal/paragraph';
import cls from './hand-care.module.scss';

export const HandCare = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'elearning.handHygiene.handDisinfectionAndWashing.handCare',
  });
  const { tasks, correctAnswer, isAnswerChecked } = useTopic();

  const carouselItems = [{ original: handsImg }];

  return (
    <div>
      <Heading className="mb-5" headingLevel="h1" headingStyle="h3">
        {t('title1')}
      </Heading>
      <Carousel items={carouselItems} showNav={false} showIndex={false} />
      <Paragraph className="mt-5 mb-10">{t('desc1')}</Paragraph>
      <Heading headingLevel="h3" className="mb-5">
        {t('title2')}
      </Heading>
      <div className={cls.icons}>
        <div className={cls.icon}>
          <figure className={cls.imgWrapper}>
            <img src={careImg} alt={t('img_alt1')} />
          </figure>
          <Paragraph fontWeight={700} className={cls.iconText}>
            {t('desc2')}
          </Paragraph>
          <Paragraph className={cls.hyphens}>{t('desc3')}</Paragraph>
        </div>
        <div className={cls.icon}>
          <figure className={cls.imgWrapper}>
            <img src={regenerationImg} alt={t('img_alt1')} />
          </figure>
          <Paragraph fontWeight={700} className={cls.iconText}>
            {t('desc4')}
          </Paragraph>
          <Paragraph>{t('desc5')}</Paragraph>
        </div>
        <div className={cls.icon}>
          <figure className={cls.imgWrapper}>
            <img src={protectionImg} alt={t('img_alt1')} />
          </figure>
          <Paragraph fontWeight={700} className={cls.iconText}>
            {t('desc6')}
          </Paragraph>
          <Paragraph>{t('desc7')}</Paragraph>
        </div>
      </div>
      <div className="divider" />
      <Heading className="mb-10" headingLevel="h3">
        {t('title3')}
      </Heading>
      <TasksGenerator
        tasks={tasks}
        isChecked={isAnswerChecked}
        correctAnswer={correctAnswer}
      />
      <TopicStatusWithButton />
    </div>
  );
};

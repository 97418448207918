import { CustomHeader } from 'components/universal/custom-header';
import { Newsletter } from 'components/universal/newsletter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactForm } from 'views/contact/contact-form';
import { PlacesMap } from 'views/contact/map';
import cls from './contact.module.scss';

export const Contact = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'SEO.contact',
  });

  return (
    <React.Fragment>
      <CustomHeader title={t('title')} />
      <div className={cls.container}>
        <div className={cls.form}>
          <ContactForm />
        </div>
        <div className={cls.map}>
          <PlacesMap />
        </div>
      </div>
      <Newsletter />
    </React.Fragment>
  );
};

import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleType } from 'types/knowledge-base';
import { Article } from './article/Article';
import cls from './articles.module.scss';

interface IProps {
  data?: ArticleType[];
}

export const Articles = ({ data }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={cls.container}>
      <Heading headingLevel="h2" className={cls.header}>
        {t('knowledgeBase.articles.title')}
      </Heading>
      <Paragraph className={cls.desc}>
        {t('knowledgeBase.articles.desc')}
      </Paragraph>
      <div className={cls.articles}>
        {data?.map((a, i) => (
          <div className={cls.article} key={a.title + i}>
            <Article item={a} />
          </div>
        ))}
      </div>
    </div>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import lockImg from 'assets/images/registration/lock.png';
import { AxiosError, AxiosResponse } from 'axios';
import { Button } from 'components/universal/button';
import Heading from 'components/universal/heading';
import { Input } from 'components/universal/input';
import { PATHS } from 'constants/routes';
import { PASSWORD_ADNOTATION } from 'constants/validation-error-messages';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { passwordReset } from 'services/registration';
import { APIErrors } from 'types/api';
import { SetNewPasswordDTO } from 'types/user';
import cls from './../login/login.module.scss';
import { schema } from './validation';

export interface ISetNewPasswordFormValues {
  new_password: string;
  new_password_repeat: string;
}

export const SetNewPasswordView = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISetNewPasswordFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      new_password: '',
      new_password_repeat: '',
    },
  });

  const onSubmit = (formData) => {
    const newData = {
      password: formData.new_password,
      token: searchParams.get('token'),
      email: searchParams.get('email'),
    };

    passwordResetMutation.mutate(newData);
  };

  const passwordResetMutation = useMutation<
    AxiosResponse<{ message: string }>,
    AxiosError<APIErrors>,
    SetNewPasswordDTO
  >({
    mutationFn: (data) => passwordReset(data),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      navigate(PATHS.LOGIN);
    },
  });

  return (
    <div className={cls.container}>
      <div className={cls.columnLeft}>
        <Heading className={cls.heading} headingLevel="h1" headingStyle="h2">
          {t('setNewPassword.title')}
        </Heading>
        <form className={cls.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="mb-6"
            register={register}
            id="new_password"
            name="new_password"
            label={t('setNewPassword.newPassword')}
            error={t(errors.new_password?.message)}
            type="password"
            maxLength={255}
            adnotation={t(PASSWORD_ADNOTATION)}
          />
          <Input
            className="mb-6"
            register={register}
            id="new_password_repeat"
            name="new_password_repeat"
            label={t('setNewPassword.newPasswordRepeat')}
            error={t(errors.new_password_repeat?.message)}
            type="password"
            maxLength={255}
          />
          <p className="mb-5 lg:mb-10">{t('setNewPassword.requiredFields')}</p>
          <Button className="mx-auto mb-4" type="submit">
            {t('setNewPassword.buttonText')}
          </Button>
        </form>
      </div>
      <img src={lockImg} className={cls.image} />
    </div>
  );
};

import React, { FC } from 'react';
import cls from '../youtube-embed/youtube-embed.module.scss';
import clsx from 'clsx';

interface IProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  className?: string;
  src: string;
}

export const VideoEmbed: FC<IProps> = ({ className, src, ...props }) => {
  const clsFigure = clsx(cls.videoContent, {
    [className]: Boolean(className),
  });

  return (
    <figure className={clsFigure}>
      <video
        src={src}
        className={cls.video}
        controlsList="nodownload"
        {...props}
      />
    </figure>
  );
};

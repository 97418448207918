import { ReactComponent as ArrowUpIcon } from 'assets/images/shop/arrow_up.svg';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cls from './scroll-to-top.module.scss';

interface IProps {
  className?: string;
}

export const ScrollToTop: FC<IProps> = ({ className }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'components.scrolltoTop',
  });

  const handleScrollToTop = () => {
    scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const clsContainer = clsx(cls.container, {
    [cls.containerVisible]: true,
    [className]: Boolean(className),
  });

  return (
    <button
      aria-label={t('buttonAriaLabel')}
      className={clsContainer}
      onClick={handleScrollToTop}
    >
      <ArrowUpIcon />
    </button>
  );
};

import { CustomHeader } from 'components/universal/custom-header';
import { useTranslation } from 'react-i18next';
import { BasketView } from 'views/basket';

export const Basket = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'SEO.basket',
  });

  return (
    <>
      <CustomHeader title={t('title')} />
      <BasketView />
    </>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import { Accordion } from 'components/universal/accordion';
import { Button } from 'components/universal/button';
import { Checkbox } from 'components/universal/checkbox';
import Heading from 'components/universal/heading';
import { Input } from 'components/universal/input';
import Paragraph from 'components/universal/paragraph';
import { PATHS, ROUTES } from 'constants/routes';
import { PASSWORD_ADNOTATION } from 'constants/validation-error-messages';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { signUp } from 'services/registration';
import { SignUpDTO } from 'types/user';
import cls from './registration.module.scss';
import { RegistrationClause } from './RegistrationClause';
import { schema } from './validation';

export interface IRegistrationFormValues {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  password_repeat: string;
  workplace_name: string;
  employee_position_name: string;
  workplace_address_street: string;
  workplace_address_number: string;
  workplace_address_city: string;
  workplace_address_postcode: string;
  agreement: boolean;
}

const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  password: '',
  password_repeat: '',
  workplace_name: '',
  employee_position_name: '',
  workplace_address_street: '',
  workplace_address_number: '',
  workplace_address_city: '',
  workplace_address_postcode: '',
  agreement: false,
};

export const RegistrationView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<IRegistrationFormValues>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = (formObject) => {
    signUpMutation.mutate(formObject);
  };

  const signUpMutation = useMutation<
    AxiosResponse<{ message: string }>,
    AxiosError,
    SignUpDTO
  >((data) => signUp(data), {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      navigate(PATHS.CONFIRM_EMAIL_ADDRESS, {
        state: {
          email: watch('email'),
        },
      });
    },
    onError: (error) => {
      // set errors from backend request
      const formErrors = error?.response?.data?.errors;
      if (formErrors) {
        Object.entries(formErrors).map(([key, value]) => {
          setError(key as keyof IRegistrationFormValues, {
            message: value?.[0],
          });
        });
      }
    },
  });

  return (
    <div className={cls.container}>
      <div className={cls.columnLeft}>
        <Heading className={cls.heading} headingLevel="h1" headingStyle="h2">
          {t('registration.title')}
        </Heading>
        <Paragraph className="mb-10">{t('registration.desc')}</Paragraph>
        <form className={cls.form} onSubmit={handleSubmit(onSubmit)}>
          <Heading headingLevel="h3" className="mb-5">
            {t('registration.yourData')}
          </Heading>
          <div className={cls.formFields}>
            <Input
              className="mb-6"
              register={register}
              id="first_name"
              name="first_name"
              label={t('registration.firstName')}
              error={t(errors.first_name?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="last_name"
              name="last_name"
              label={t('registration.lastName')}
              error={t(errors.last_name?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="email"
              name="email"
              label={t('registration.email')}
              error={t(errors.email?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="phone"
              name="phone"
              label={t('registration.phone')}
              error={t(errors.phone?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="password"
              name="password"
              label={t('registration.password')}
              error={t(errors.password?.message)}
              maxLength={255}
              type="password"
              adnotation={t(PASSWORD_ADNOTATION)}
            />
            <Input
              className="mb-6"
              register={register}
              id="password_repeat"
              name="password_repeat"
              label={t('registration.repeatPassword')}
              error={t(errors.password_repeat?.message)}
              maxLength={255}
              type="password"
            />
          </div>
          <Heading headingLevel="h3" className="mb-5">
            {t('registration.workplaceData')}
          </Heading>

          <div className={cls.formFields}>
            <Input
              className="mb-6"
              register={register}
              id="workplace_name"
              name="workplace_name"
              label={t('registration.workplaceName')}
              error={t(errors.workplace_name?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="employee_position_name"
              name="employee_position_name"
              label={t('registration.position')}
              error={t(errors.employee_position_name?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="workplace_address_street"
              name="workplace_address_street"
              label={t('registration.street')}
              error={t(errors.workplace_address_street?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="workplace_address_number"
              name="workplace_address_number"
              label={t('registration.apartmentNumber')}
              error={t(errors.workplace_address_number?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="workplace_address_city"
              name="workplace_address_city"
              label={t('registration.city')}
              error={t(errors.workplace_address_city?.message)}
              maxLength={255}
            />
            <Input
              className="mb-6"
              register={register}
              id="workplace_address_postcode"
              name="workplace_address_postcode"
              label={t('registration.zipCode')}
              error={t(errors.workplace_address_postcode?.message)}
              maxLength={255}
            />
          </div>

          <p className={cls.paragraph}>{t('logIn.requiredFields')}</p>
          <Checkbox
            className={cls.checkbox}
            register={register}
            id="agreement"
            name="agreement"
            label={t('registration.consent')}
            error={t(errors.agreement?.message)}
          />
          <Accordion
            label={t('registration.infoClause')}
            arrow
            height={300}
            className={cls.accordion}
          >
            <RegistrationClause />
          </Accordion>
          <Button className={cls.button} type="submit">
            {t('registration.register')}
          </Button>
          <Paragraph className="text-center">
            {t('registration.alreadyAccount')}{' '}
            <Link to={ROUTES.LOGIN.path}>
              <b>{t(`registration.${ROUTES.LOGIN.name}`)}</b>
            </Link>
          </Paragraph>
        </form>
      </div>
    </div>
  );
};

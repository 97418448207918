import { CertificateFormDTO } from 'types/contact';
import { request } from 'utils/request';

export const submitCertificateForm = async (
  certificateData: CertificateFormDTO
) => {
  const processedData = {
    ...certificateData,
    participants: certificateData.participants.map((p) => p.participant),
  };
  return await request(
    '/elearning/certificate/hand-hygiene/premium/download',
    { method: 'POST', responseType: 'blob' },
    processedData
  );
};

export const submitCertificateCourseForm = async () => {
  return await request('/elearning/certificate/hand-hygiene/download', {
    method: 'GET',
    responseType: 'blob',
  });
};

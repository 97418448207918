import { ERROR_MESSAGES } from 'constants/validation-error-messages';
import * as yup from 'yup';

export const schema = yup.object({
  email: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.EMAIL),
  password: yup.string().required(ERROR_MESSAGES.REQUIRED),
});

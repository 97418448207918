import React, { FC } from 'react';
import cls from './youtube-embed.module.scss';
import clsx from 'clsx';

interface IProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  className?: string;
  src: string;
}

export const YoutubeEmbed: FC<IProps> = ({ className, src, ...props }) => {
  const clsFigure = clsx(cls.videoContent, {
    [className]: Boolean(className),
  });

  return (
    <figure className={clsFigure}>
      <iframe src={src} className={cls.video} allowFullScreen {...props} />
    </figure>
  );
};

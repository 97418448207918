import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_TYPES } from 'store/constants';
import { RootState } from 'store/store';
import {
  Answer,
  Course,
  CurrentAnswer,
  MatchTextWithDescriptionCurrentAnswer,
  TaskType,
} from 'types/elearning';

type NewAnswer = { questionId: string; newAnswerId: string };

type InitialState = {
  canSubmitAnswers: boolean;
  checkedAnswers: CurrentAnswer;
  correctAnswer: Answer['correct_answer'];
  isAnswerChecked: boolean;
  isSuccessSendAnswer: boolean;
  courseData: Course;
  isQuestionDone: boolean;
  answer: Answer;
  curentDnDOrder: string[];
  matchTextWithDescriptionCurrentAnswer: MatchTextWithDescriptionCurrentAnswer;
  taskType: TaskType;
};

const initialState: InitialState = {
  canSubmitAnswers: false,
  checkedAnswers: null,
  correctAnswer: null,
  isAnswerChecked: false,
  isSuccessSendAnswer: false,
  courseData: null,
  isQuestionDone: false,
  answer: null,
  curentDnDOrder: [],
  matchTextWithDescriptionCurrentAnswer: null,
  taskType: null,
};

export const topicSlice = createSlice({
  name: SLICE_TYPES.TOPIC,
  initialState,
  reducers: {
    setCanSubmitAnswer: (state, action) => {
      state.canSubmitAnswers = action.payload;
    },
    setCheckedAnswerSingle: (state, action: PayloadAction<NewAnswer>) => {
      const newCheckedAnswersSingl = {
        ...state.checkedAnswers,
        [action.payload.questionId]: [action.payload.newAnswerId],
      };

      state.checkedAnswers = newCheckedAnswersSingl;
    },
    setCheckedAnswerMultiple: (state, action: PayloadAction<NewAnswer>) => {
      const questionId = action.payload.questionId;
      const newAnswerId = action.payload.newAnswerId;
      const questionToChange = state.checkedAnswers?.[questionId];

      if (questionToChange?.includes(newAnswerId)) {
        state.checkedAnswers = {
          ...state.checkedAnswers,
          [questionId]: questionToChange?.filter?.(
            (answer) => answer !== newAnswerId
          ),
        };
      } else {
        const newCheckedAnswersMultiple = {
          ...state.checkedAnswers,
          [questionId]: [...(questionToChange || []), newAnswerId],
        };

        state.checkedAnswers = newCheckedAnswersMultiple;
      }
    },
    resetCheckedAnswer: (state) => {
      state.checkedAnswers = null;
    },
    setCorrectAnswer: (
      state,
      action: PayloadAction<Answer['correct_answer']>
    ) => {
      state.correctAnswer = action.payload;
    },
    setIsAnswerChecked: (state, action: PayloadAction<boolean>) => {
      state.isAnswerChecked = action.payload;
    },
    resetSlice: () => initialState,
    resetSliceWithoutCourseData: (state) => {
      const newState = { ...initialState };
      delete newState.courseData;
      return { ...state, ...newState };
    },
    setIsSuccessSendAnswer: (state, action: PayloadAction<boolean>) => {
      state.isSuccessSendAnswer = action.payload;
    },
    setCourseData: (state, action: PayloadAction<Course>) => {
      state.courseData = action.payload;
    },
    setIsQuestionDone: (state, action: PayloadAction<boolean>) => {
      state.isQuestionDone = action.payload;
    },
    setAnswer: (state, action: PayloadAction<Answer>) => {
      state.answer = action.payload;
    },
    setCurentDnDOrder: (state, action: PayloadAction<string[]>) => {
      state.curentDnDOrder = action.payload;
    },
    setMatchTextWithDescriptionCurrentAnswer: (
      state,
      action: PayloadAction<MatchTextWithDescriptionCurrentAnswer>
    ) => {
      state.matchTextWithDescriptionCurrentAnswer = {
        ...state.matchTextWithDescriptionCurrentAnswer,
        ...action.payload,
      };
    },
    setOneMatchTextWithDescriptionCurrentAnswer: (
      state,
      action: PayloadAction<{
        dropzoneId: string;
        elementId: string;
        removeFromPreviousPosition: boolean;
      }>
    ) => {
      const elementId = action.payload.elementId;
      let newState = { ...state.matchTextWithDescriptionCurrentAnswer };

      if (action.payload.removeFromPreviousPosition) {
        const prevElement = Object.entries(newState).find(
          ([, image]) => image === elementId
        );
        newState = { ...newState, [prevElement[0]]: '' };
      }

      newState = {
        ...newState,
        [action.payload.dropzoneId]: action.payload.elementId,
      };

      state.matchTextWithDescriptionCurrentAnswer = newState;
    },
    setTaskType: (state, action: PayloadAction<TaskType>) => {
      state.taskType = action.payload;
    },
  },
});

export const {
  setCanSubmitAnswer,
  setCheckedAnswerSingle,
  setCheckedAnswerMultiple,
  resetCheckedAnswer,
  setCorrectAnswer,
  setIsAnswerChecked,
  resetSlice,
  resetSliceWithoutCourseData,
  setIsSuccessSendAnswer,
  setCourseData,
  setIsQuestionDone,
  setAnswer,
  setCurentDnDOrder,
  setMatchTextWithDescriptionCurrentAnswer,
  setOneMatchTextWithDescriptionCurrentAnswer,
  setTaskType,
} = topicSlice.actions;

export const topicSliceReducer = topicSlice.reducer;

export const selectCanSubmitAnswer = (state: RootState) =>
  state.topic.canSubmitAnswers;

export const selectCheckedAnswers = (state: RootState) =>
  state.topic.checkedAnswers;

export const selectCorrectAnswer = (state: RootState) =>
  state.topic.correctAnswer;

export const selectIsAnswerChecked = (state: RootState) =>
  state.topic.isAnswerChecked;

export const selectIsSuccessSendAnswer = (state: RootState) =>
  state.topic.isSuccessSendAnswer;

export const selectCourseData = (state: RootState) => state.topic.courseData;

export const selectisQuestionDone = (state: RootState) =>
  state.topic.isQuestionDone;

export const selectAnswer = (state: RootState) => state.topic.answer;

export const selectCurentDnDOrder = (state: RootState) =>
  state.topic.curentDnDOrder;

export const selectMatchTextWithDescriptionCurrentAnswer = (state: RootState) =>
  state.topic.matchTextWithDescriptionCurrentAnswer;

export const selectTaskType = (state: RootState) => state.topic.taskType;

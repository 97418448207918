import clsx from 'clsx';
import { useTopic } from 'hooks/useTopic';
import React, { FC } from 'react';
import nextId from 'react-id-generator';
import { Answer, Task } from 'types/elearning';
import { ImageDnd } from './image-dnd';
import cls from './put-in-correct-order-images.module.scss';

interface IProps {
  task: Task;
  isChecked: boolean;
  correctAnswer: Answer['correct_answer'];
}

export const PutInCorrectOrderImages: FC<IProps> = ({ task, isChecked }) => {
  const { curentDnDOrder, handleSetCurentDnDOrder } = useTopic();

  const clsDropElement = clsx(cls.dropElement, {
    [cls.dropElementDisabled]: isChecked,
  });

  return (
    <div className={cls.dropElements}>
      {Object?.entries?.(task?.content)?.map?.(([itemKey], id) => {
        const contentImageUrl = Object?.values?.(task?.content)?.[id]
          ?.image_url;
        const constentImageDescription = Object?.values?.(task?.content)?.[id]
          ?.description;

        const currentImageUrl =
          task?.content?.[curentDnDOrder?.[id]]?.image_url;
        const currentImageDescription =
          task?.content?.[curentDnDOrder?.[id]]?.description;

        return (
          <div
            className={clsDropElement}
            key={`${nextId()}-${itemKey}-${id}-${contentImageUrl}-${constentImageDescription}-${currentImageUrl}-${currentImageDescription}-wrapper`}
          >
            <ImageDnd
              disabled={isChecked}
              handleDrop={handleSetCurentDnDOrder}
              id={curentDnDOrder?.[id]}
              image_url={currentImageUrl}
              description={currentImageDescription}
            />
          </div>
        );
      })}
    </div>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import { Button } from 'components/universal/button';
import Heading from 'components/universal/heading';
import { Input } from 'components/universal/input';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { APIErrors } from 'types/api';
import { CertificateFormDTO } from 'types/contact';
import { certificateSchema } from './validation';
import cls from './generate-certificate.module.scss';
import { submitCertificateForm } from 'services/certificate';
import { ReactComponent as Close } from 'assets/images/generate-certificate/close.svg';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export interface ICertificateFormValues {
  city: string;
  date: string;
  coordinator: string;
  participants: { participant: string }[];
}

export const GenerateCertificateView = () => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<ICertificateFormValues>({
    resolver: yupResolver(certificateSchema),
    defaultValues: {
      city: '',
      date: '',
      coordinator: '',
      participants: [{ participant: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray<ICertificateFormValues>({
    control,
    name: 'participants',
  });

  const { t } = useTranslation();

  const onSubmit = (DTO: ICertificateFormValues) =>
    certificateMutation.mutate(DTO);

  const certificateMutation = useMutation<
    AxiosResponse<string>,
    AxiosError<APIErrors>,
    CertificateFormDTO
  >((data) => submitCertificateForm(data), {
    onSuccess: async ({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'certyfikaty.pdf');
      link.click();
      toast.success(t('generateCertificate.generateSuccess'));
      reset();
    },
    onError: async (err) => {
      const resErrors = err.response.data.errors;
      Object.keys(resErrors).forEach((field: keyof ICertificateFormValues) => {
        setError(field, {
          type: 'manual',
          message: resErrors[field].join('\n'),
        });
      });
    },
  });

  const addParticipant = () => append({ participant: '' });
  const deleteParticipant = (id: string) =>
    remove(fields.findIndex((f) => f.id === id));

  return (
    <div className={cls.container}>
      <Heading headingLevel="h1" headingStyle="h2" className={cls.header}>
        {t('generateCertificate.title')}
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={cls.inputRow}>
          <Input
            className={cls.inputInRow}
            register={register}
            id="city"
            name="city"
            label={t('generateCertificate.city')}
            error={t(errors.city?.message)}
            maxLength={255}
          />
          <Input
            className={cls.inputInRow}
            register={register}
            id="date"
            name="date"
            label={t('generateCertificate.date')}
            error={t(errors.date?.message)}
            maxLength={255}
          />
        </div>
        <Input
          className={cls.input}
          register={register}
          id="coordinator"
          name="coordinator"
          label={t('generateCertificate.coordinator')}
          error={t(errors.coordinator?.message)}
          maxLength={255}
        />
        <Heading headingLevel="h3" className={cls.subHeader}>
          {t('generateCertificate.participants')}
        </Heading>
        {fields.map((f, i) => (
          <div key={f.id} className={cls.dynamicInput}>
            <Input
              className={cls.input}
              register={register}
              id={f.id}
              name={`participants[${i}].participant`}
              label={t('generateCertificate.name')}
              error={t(errors.participants?.[i]?.participant?.message)}
              maxLength={255}
              style={{ paddingRight: '35px' }}
            />
            {i !== 0 && (
              <Close
                className={clsx(cls.close, {
                  [cls.closeErr]: Boolean(
                    errors.participants?.[i]?.participant?.message
                  ),
                })}
                onClick={() => deleteParticipant(f.id)}
              />
            )}
          </div>
        ))}
        <Button
          styleType="tertiary"
          onClick={addParticipant}
          className={cls.btnAdd}
          type="button"
        >
          {t('generateCertificate.addParticipant')}
        </Button>
        <Button className={cls.btnDownload} type="submit">
          {t('generateCertificate.download')}
        </Button>
      </form>
    </div>
  );
};

import clsx from 'clsx';
import { Button } from 'components/universal/button';
import Paragraph from 'components/universal/paragraph';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleType } from 'types/knowledge-base';
import downloadFile from 'views/knowledge-base/downloadFile';
import cls from './article.module.scss';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  item: ArticleType;
}

export const Article = ({ item, className, ...props }: IProps) => {
  const { t } = useTranslation();
  const clsWrapper = clsx(cls.wrapper, {
    [className]: Boolean(className),
  });

  return (
    <div {...props} className={clsWrapper}>
      <img className={cls.image} src={item.image_url} alt="" />
      <div className={cls.content}>
        <Paragraph fontWeight={700} className={cls.title}>
          {item.title}
        </Paragraph>
        <Paragraph className={cls.desc}>{item.description}</Paragraph>
        <Button className={cls.btn} onClick={() => downloadFile(item)}>
          {t('knowledgeBase.articles.readMore')}
        </Button>
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import cls from './footer.module.scss';
import { ReactComponent as IconFacebook } from 'assets/images/footer/ICON_facebook.svg';
import { ReactComponent as IconInstagram } from 'assets/images/footer/ICON_instagram.svg';
import { ReactComponent as IconLinkedIn } from 'assets/images/footer/ICON_linkedin.svg';
import { ReactComponent as IconTwitter } from 'assets/images/footer/ICON_twitter.svg';
import clsx from 'clsx';

type IProps = {
  isWhite?: boolean;
  isBlueLight?: boolean;
};

export const FooterIcons: FC<IProps> = ({ isWhite, isBlueLight }) => {
  const clsIcon = clsx(cls.icon, {
    [cls.iconWhite]: Boolean(isWhite),
    [cls.iconBlueLight]: Boolean(isBlueLight),
  });
  return (
    <React.Fragment>
      <a
        href="https://twitter.com/Ecolab?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
        target="_blank"
      >
        <IconTwitter className={clsIcon} />
      </a>
      <a href="https://www.facebook.com/ecolab" target="_blank">
        <IconFacebook className={clsIcon} />
      </a>
      <a href="https://www.linkedin.com/company/ecolab/" target="_blank">
        <IconLinkedIn className={clsIcon} />
      </a>
      <a href="https://www.instagram.com/ecolab_inc/" target="_blank">
        <IconInstagram className={clsIcon} />
      </a>
    </React.Fragment>
  );
};

import sendButtonImg from 'assets/images/registration/send_button.png';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import cls from './../login/login.module.scss';

interface LocationState {
  email: string;
}

export const ConfirmResetPasswordView = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const { t } = useTranslation();

  return (
    <div className={cls.container}>
      <div className={cls.columnLeft}>
        <Heading className={cls.heading} headingLevel="h1" headingStyle="h2">
          {t('confirmResetPassword.title')}
        </Heading>
        <Paragraph className="mb-9">
          {t('confirmResetPassword.textA')} <b>{state?.email}</b>{' '}
          {t('confirmResetPassword.textB')}
        </Paragraph>
        <Paragraph>{t('confirmResetPassword.link')}</Paragraph>
      </div>
      <figure className={cls.figure}>
        <img src={sendButtonImg} className={cls.image} />
      </figure>
    </div>
  );
};

import { useUser } from 'hooks/useUser';
import { ReactNode } from 'react';
import { Permission } from 'types/user';

interface IProps {
  children: ReactNode;
  permission?: Permission;
}

const PrivateComponent = ({ children, permission }: IProps) => {
  const { isAuthenticated, hasUserRequiredPermissions } = useUser();

  if (isAuthenticated && hasUserRequiredPermissions(permission)) {
    return <>{children}</>;
  }

  return null;
};

export default PrivateComponent;

/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import { PATHS } from 'constants/routes';
import { API_URL } from 'constants/variables';
import { toast } from 'react-toastify';
import { getLang } from './language';
import { getToken, removeToken } from './local-storage-token';

type Options = Partial<AxiosRequestConfig>;

type Body =
  | {
      [k: string]: any;
    }
  | FormData;

type RequestConfig = {
  omitApiUrl?: boolean;
};

// TODO - with  or without modal option ?
export const request = async (
  path: string,
  options?: Options,
  body?: Body,
  requestConfig?: RequestConfig
) => {
  const requestURL = new URL((requestConfig?.omitApiUrl ? '' : API_URL) + path);

  const token = getToken();
  const lang = getLang();

  const requestOptions: Options = {
    method: 'GET',
    data: body || null,
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  if (token) {
    requestOptions.headers.Authorization = `Bearer ${token}`;
  }

  if (lang)
    requestOptions.headers['Accept-Language'] = lang === 'eng' ? 'en' : lang;

  try {
    const response = await axios(requestURL.href, requestOptions);

    return response;
  } catch (error) {
    const statusCode = error?.response?.status;
    const errorMsg = error?.response?.data?.message;

    switch (statusCode) {
      case 401:
        window.location.href = PATHS.LOGIN;
        removeToken();
        break;
      case 422:
        throw error;
      case 500:
        toast.error('Server error');
        break;
      case 504:
        toast.error('Gateway Time-out');
        break;
      default:
        if (errorMsg) {
          toast.error(errorMsg);
        }
        throw error;
    }
  }
};

import React from 'react';
import cls from './contact-form.module.scss';
import Heading from 'components/universal/heading';
import { Input } from 'components/universal/input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactSchema } from './validation';
import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { APIErrors } from 'types/api';
import { ContactFormDTO } from 'types/contact';
import { submitContactForm } from 'services/contact';
import { Button } from 'components/universal/button';
import { Textarea } from 'components/universal/textarea';
import { Checkbox } from 'components/universal/checkbox';
import Paragraph from 'components/universal/paragraph';
import { InfoClause } from './InfoClause';
import { Accordion } from 'components/universal/accordion';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export interface IContactFormValues {
  workplace_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  message: string;
  checkbox_rodo: boolean;
  checkbox_terms: boolean;
  checkbox_marketing: boolean;
  checkbox_consent: boolean;
}

export const ContactForm = () => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<IContactFormValues>({
    resolver: yupResolver(contactSchema),
    defaultValues: {
      workplace_name: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      message: '',
      checkbox_rodo: false,
      checkbox_terms: false,
      checkbox_marketing: false,
      checkbox_consent: false,
    },
  });

  const { t } = useTranslation();

  const onSubmit = (DTO) => contactMutation.mutate(DTO);

  const contactMutation = useMutation<
    AxiosResponse<{ message: string }>,
    AxiosError<APIErrors>,
    ContactFormDTO
  >((data) => submitContactForm(data), {
    onSuccess: async ({ data }) => {
      toast.success(data.message);
      reset();
    },
    onError: async (err) => {
      const resErrors = err.response.data.errors;
      Object.keys(resErrors).forEach((field: keyof IContactFormValues) => {
        setError(field, {
          type: 'manual',
          message: resErrors[field].join('\n'),
        });
      });
    },
  });

  return (
    <div className={cls.container}>
      <Heading headingLevel="h1" headingStyle="h3" className={cls.header}>
        {t('contact.contactForm.title')}
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          className={cls.input}
          register={register}
          id="workplace_name"
          name="workplace_name"
          label={t('contact.contactForm.workplaceName')}
          error={t(errors.workplace_name?.message)}
          maxLength={255}
        />
        <Input
          className={cls.input}
          register={register}
          id="first_name"
          name="first_name"
          label={t('contact.contactForm.firstName')}
          error={t(errors.first_name?.message)}
          maxLength={255}
        />
        <Input
          className={cls.input}
          register={register}
          id="last_name"
          name="last_name"
          label={t('contact.contactForm.lastName')}
          error={t(errors.last_name?.message)}
          maxLength={255}
        />
        <Input
          className={cls.input}
          register={register}
          id="email"
          name="email"
          label={t('contact.contactForm.email')}
          error={t(errors.email?.message)}
          type="email"
        />
        <Input
          className={cls.input}
          register={register}
          id="phone"
          name="phone"
          label={t('contact.contactForm.phone')}
          error={t(errors.phone?.message)}
          maxLength={20}
        />
        <Textarea
          className={cls.input}
          register={register}
          id="message"
          name="message"
          label={t('contact.contactForm.message')}
          error={t(errors.message?.message)}
        />
        <Checkbox
          className={cls.input}
          register={register}
          id="checkbox_rodo"
          name="checkbox_rodo"
          label={t('contact.contactForm.checkboxA')}
          error={t(errors.checkbox_rodo?.message)}
        />
        <Checkbox
          className={cls.input}
          register={register}
          id="checkbox_terms"
          name="checkbox_terms"
          label={t('contact.contactForm.checkboxB')}
          error={t(errors.checkbox_terms?.message)}
        />
        <Checkbox
          className={cls.input}
          register={register}
          id="checkbox_marketing"
          name="checkbox_marketing"
          label={t('contact.contactForm.checkboxC')}
          error={t(errors.checkbox_marketing?.message)}
        />
        <Checkbox
          className={cls.input}
          register={register}
          id="checkbox_consent"
          name="checkbox_consent"
          label={t('contact.contactForm.checkboxD')}
          error={t(errors.checkbox_consent?.message)}
        />
        <Paragraph className={cls.tip}>
          {t('contact.contactForm.requiredFields')}
        </Paragraph>
        <Accordion
          height={300}
          arrow
          label={t('contact.contactForm.infoClause')}
          className="mb-5"
        >
          <InfoClause />
        </Accordion>
        <Button className={cls.btn} type="submit">
          {t('contact.contactForm.button')}
        </Button>
      </form>
    </div>
  );
};

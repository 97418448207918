import { CustomLink } from 'components/universal/custom-link';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import { PATHS } from 'constants/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleType } from 'types/knowledge-base';
import cls from './presentation.module.scss';

interface IProps {
  data?: ArticleType[];
}

export const Presentation = ({ data }: IProps) => {
  const { t } = useTranslation();
  return (
    <div className={cls.wrapper}>
      <div className={cls.container}>
        {data?.map((article, i) => (
          <React.Fragment key={article.title + i}>
            <div className={cls.left}>
              <img
                src={article.image_url}
                alt={article.title}
                className={cls.image}
              />
            </div>
            <div className={cls.right}>
              <Heading headingLevel="h2" isWhite className={cls.header}>
                {article.title}
              </Heading>
              <Paragraph className={cls.desc}>{article.description}</Paragraph>
              <CustomLink className={cls.btn} to={PATHS.CONTACT}>
                {t('knowledgeBase.presentation.download')}
              </CustomLink>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

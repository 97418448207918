import privacyPolicy from 'assets/files/polityka_prywatnosci.pdf';
import { Button } from 'components/universal/button';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import cls from './cookies.module.scss';

const ecolab_cookies_accepted = 'ecolab_cookies_accepted';

export const Cookies = () => {
  const next2YearsDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 2)
  );
  const acceptCookies = () =>
    setCookies(ecolab_cookies_accepted, true, {
      expires: next2YearsDate,
    });
  const [cookies, setCookies] = useCookies([ecolab_cookies_accepted]);

  const { t } = useTranslation();

  if (!cookies.ecolab_cookies_accepted)
    return (
      <div className={cls.container}>
        <div className={cls.content}>
          <p className={cls.text}>
            {t('cookies.text')}{' '}
            <a
              className={cls.policy}
              href={privacyPolicy}
              download
              target="_blank"
              rel="noreferrer"
            >
              {t('cookies.terms')}
            </a>
            .
          </p>
          <div className={cls.button}>
            <Button onClick={acceptCookies}>{t('cookies.button')}</Button>
          </div>
        </div>
      </div>
    );

  return null;
};

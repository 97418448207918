import clsx from 'clsx';
import { StyleType } from 'components/universal/button';
import React, { FC } from 'react';
import cls from './../button/button.module.scss';

interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  styleType?: StyleType;
  className?: string;
}

export const ExternalLink: FC<IProps> = ({
  styleType,
  className,
  children,
  ...props
}) => {
  const clsButton = clsx(cls.button, {
    [cls.buttonSecondary]: styleType === 'secondary',
    [className]: Boolean(className),
  });

  return (
    <a {...props} className={clsButton}>
      {children}
    </a>
  );
};

import React, { useState } from 'react';
import cls from './accordion.module.scss';
import clsx from 'clsx';
import { ReactComponent as Arrow } from 'assets/images/contact/arrow-down.svg';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: number;
  label: string;
  arrow?: boolean;
}

export const Accordion = ({
  height = 300,
  label,
  arrow,
  children,
  className,
  ...props
}: IProps) => {
  const [active, setActive] = useState(false);

  const clsAccordion = clsx(cls.accordion, {
    [className]: Boolean(className),
  });

  const clsArrow = clsx(cls.arrow, {
    [cls.reversed]: Boolean(active),
  });

  return (
    <div>
      <label className={cls.label} onClick={() => setActive(!active)}>
        {label}
        {arrow && <Arrow className={clsArrow} />}{' '}
      </label>
      <div
        className={clsAccordion}
        {...props}
        style={{
          maxHeight: `${height}px`,
          height: active ? `${height}px` : '0px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

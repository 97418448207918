import { Button } from 'components/universal/button';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProduct } from 'types/cart';
import { replaceToPositiveNumbers } from 'utils/regexp';
import styles from './product.module.scss';

interface IProps {
  product: IProduct;
  handleSubmit: (quantity: number, product: IProduct) => void;
}

export const Product: FC<IProps> = ({ product, handleSubmit }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'shop',
  });
  const [quantity, setQuantity] = useState(1);

  const handleChange = (event) => {
    setQuantity(Number(replaceToPositiveNumbers(event.target.value)));
  };

  const thumbnail_url = product?.thumbnail_url;

  return (
    <div className={styles.product}>
      <img
        className={styles.productImage}
        src={thumbnail_url}
        // onError={() =>
        //   request(thumbnail_url, {
        //     responseType: 'blob',
        //   }).then((d) => {
        //     console.log('d', d);

        //     thumbnail_url = window.URL.createObjectURL(d?.data);
        //     console.log('thumbnail_url', thumbnail_url);
        //   })
        // }
      />
      <div className={styles.productInfo}>
        <div className={styles.productInfoTop}>
          <p className={styles.productType}>{product?.category_name}</p>
          <p className={styles.productName}>{product?.title}</p>
        </div>
        <div className={styles.inputWrapper}>
          <input
            className={styles.productInput}
            value={quantity}
            type="text"
            onChange={handleChange}
            maxLength={4}
          />
          <Button
            className={styles.productButton}
            onClick={() => handleSubmit(quantity, product)}
          >
            {t('addButton')}
          </Button>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import cls from './carousel.module.scss';
import { ReactComponent as ArrowSvg } from 'assets/images/elearning/carousel/arrow.svg';
import { ReactComponent as FullscreenSvg } from 'assets/images/elearning/carousel/fullscreen.svg';

interface IProps {
  slideLeft: () => void;
  slideRight: () => void;
  toggleFullScreen: () => void;
  state: {
    currentIndex: number;
  };
  props: {
    items: undefined[];
  };
  showNav?: boolean;
  showFullScreen?: boolean;
  showIndex?: boolean;
}

export const Controls = ({
  slideLeft,
  slideRight,
  toggleFullScreen,
  showNav,
  showFullScreen,
  showIndex,
  state,
  props,
}: IProps) => {
  if (!slideLeft || !slideRight || !toggleFullScreen || !state || !props)
    return null;
  return (
    <>
      {showNav !== false && (
        <>
          <ArrowSvg className={cls.left} onClick={slideLeft} />
          <ArrowSvg className={cls.right} onClick={slideRight} />
        </>
      )}
      {showFullScreen !== false && (
        <FullscreenSvg
          className={cls.fullscreen}
          onClick={() => toggleFullScreen()}
        />
      )}
      {showIndex !== false && (
        <label className={cls.index}>
          <b>{state.currentIndex + 1}</b>/{props.items.length}
        </label>
      )}
    </>
  );
};

import { AxiosError, AxiosResponse } from 'axios';
import { QUERY_KEYS } from 'constants/query-keys';
import { PATHS } from 'constants/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCourseData, getTopic, sendAnswer } from 'services/elearning';
import {
  resetSliceWithoutCourseData,
  selectAnswer,
  selectCheckedAnswers,
  selectCorrectAnswer,
  selectCourseData,
  selectCurentDnDOrder,
  selectIsAnswerChecked,
  selectisQuestionDone,
  selectIsSuccessSendAnswer,
  selectMatchTextWithDescriptionCurrentAnswer,
  selectTaskType,
  setAnswer,
  setCanSubmitAnswer,
  setCheckedAnswerMultiple,
  setCheckedAnswerSingle,
  setCorrectAnswer,
  setCourseData,
  setCurentDnDOrder,
  setIsAnswerChecked,
  setIsQuestionDone,
  setIsSuccessSendAnswer,
  setMatchTextWithDescriptionCurrentAnswer,
  setOneMatchTextWithDescriptionCurrentAnswer,
  setTaskType,
} from 'store/topic/topicSlice';
import { APIErrors } from 'types/api';
import {
  Answer,
  CurrentAnswerPayload,
  MatchTextWithDescriptionCurrentAnswer,
  Task,
  Topic,
} from 'types/elearning';
import { moveElementInArray } from 'utils/array-helper';
import { queryKeyWithLanguage } from 'utils/language';
import { useUser } from './useUser';

export const useTopic = () => {
  const { pathname } = useLocation();
  const { hasElearningPermission } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const correctAnswer = useSelector(selectCorrectAnswer);
  const isAnswerChecked = useSelector(selectIsAnswerChecked);
  const isSuccessSendAnswer = useSelector(selectIsSuccessSendAnswer);
  const courseData = useSelector(selectCourseData);
  const isQuestionDone = useSelector(selectisQuestionDone);
  const answer = useSelector(selectAnswer);
  const checkedAnswers = useSelector(selectCheckedAnswers);
  const curentDnDOrder = useSelector(selectCurentDnDOrder);
  const matchTextWithDescriptionCurrentAnswer = useSelector(
    selectMatchTextWithDescriptionCurrentAnswer
  );
  const taskType = useSelector(selectTaskType);

  const handleSetCheckedAnswersSingle = (questionId, newAnswerId) =>
    dispatch(
      setCheckedAnswerSingle({
        questionId,
        newAnswerId,
      })
    );

  const handleSetCheckedAnswersMultiple = (questionId, newAnswerId) =>
    dispatch(
      setCheckedAnswerMultiple({
        questionId,
        newAnswerId,
      })
    );

  const { refetch: refetchCourseInfo, isFetching: isFetchingCourseInfo } =
    useQuery<AxiosResponse, AxiosError>(
      queryKeyWithLanguage(QUERY_KEYS.COURSE_INFO),
      () => getCourseData(),
      {
        onSuccess: (res) => {
          if (res?.data) {
            dispatch(setCourseData(res?.data));
          }
        },
      }
    );

  function getNthCharacterPositionInString(string, subString, index) {
    const splitedArray = string.split(subString, index);
    return splitedArray.join(subString).length;
  }

  // path to topic always starts from the second occurrence of the '/'
  const startPositionOfTopic = getNthCharacterPositionInString(
    pathname,
    '/',
    2
  );
  const topicPath = pathname.substring(startPositionOfTopic);

  const [tasks, setTasks] = useState<Task[]>([]);

  const { refetch: refetchTopic } = useQuery<AxiosResponse<Topic>, AxiosError>(
    queryKeyWithLanguage(`TASKS-${topicPath}`),
    () => getTopic(topicPath),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      onSuccess: (res) => {
        if (res?.data?.tasks) {
          dispatch(setTaskType(res?.data?.tasks?.[0]?.type));
          setTasks(res?.data?.tasks);
          if (
            res?.data?.tasks?.[0]?.type ===
            'challenge_put_in_correct_order_images'
          ) {
            dispatch(
              setCurentDnDOrder(Object?.keys?.(res?.data?.tasks?.[0]?.content))
            );
          }
          if (
            res?.data?.tasks?.[0]?.type ===
            'challenge_match_image_with_description'
          ) {
            dispatch(
              setMatchTextWithDescriptionCurrentAnswer(
                Object?.keys?.(
                  res?.data?.tasks?.[0]?.content?.descriptions
                )?.reduce?.(
                  (acc, value) => ({ ...acc, [value]: '' }),
                  {}
                ) as MatchTextWithDescriptionCurrentAnswer
              )
            );
          }
        }
      },
    }
  );

  const totalPoints = useMemo(
    () => tasks?.[0]?.points?.total,
    [checkedAnswers, curentDnDOrder, matchTextWithDescriptionCurrentAnswer]
  );

  const { mutate: sendAnswerMutate } = useMutation<
    AxiosResponse<Answer>,
    AxiosError<APIErrors>,
    CurrentAnswerPayload
  >((checkedAnswers) => sendAnswer(tasks?.[0]?.id, checkedAnswers), {
    onSuccess: async (data) => {
      if (data?.data) {
        dispatch(setIsQuestionDone(data?.data?.is_done));
        dispatch(setCorrectAnswer(data?.data?.correct_answer));
        dispatch(setIsSuccessSendAnswer(true));
        dispatch(setAnswer(data?.data));
      }
    },
  });

  const obtainedPoints = useMemo(() => answer?.points?.obtained, [answer]);

  // if users have no permission for course and try to access it via direct link
  // send them back to start screen where they will be shown adequate screen
  useEffect(() => {
    if (!hasElearningPermission) navigate(PATHS.HAND_HYGIENE);
  }, [hasElearningPermission]);

  useEffect(() => {
    dispatch(resetSliceWithoutCourseData());
  }, [pathname]);

  // refetch topics data only when URL is changed
  useEffect(() => {
    refetchCourseInfo();
    refetchTopic();
  }, [pathname]);

  useEffect(() => {
    const numberOfNotEmptyCheckedAnswersInQuestion =
      checkedAnswers &&
      Object?.entries?.(checkedAnswers)?.filter(
        ([, elements]) => elements?.length
      )?.length;

    const isAnswerChecked =
      totalPoints === numberOfNotEmptyCheckedAnswersInQuestion;

    dispatch(setCanSubmitAnswer(isAnswerChecked));

    if (taskType === 'challenge_put_in_correct_order_images') {
      if (curentDnDOrder?.length) {
        dispatch(setCanSubmitAnswer(true));
      }
    }
    if (
      taskType === 'challenge_match_image_with_description' ||
      taskType === 'challenge_match_text_with_description'
    ) {
      if (
        tasks?.[0]?.content?.descriptions &&
        matchTextWithDescriptionCurrentAnswer &&
        Object?.keys?.(tasks?.[0]?.content?.descriptions)?.length ===
          Object?.values?.(matchTextWithDescriptionCurrentAnswer)?.filter?.(
            (value) => value != ''
          )?.length
      ) {
        dispatch(setCanSubmitAnswer(true));
      }
    }
  }, [checkedAnswers, curentDnDOrder, matchTextWithDescriptionCurrentAnswer]);

  const checkAnswers = () => {
    if (
      taskType === 'challenge_choose_one_answer' ||
      taskType === 'challenge_choose_many_answers'
    ) {
      sendAnswerMutate(checkedAnswers);
    } else if (taskType === 'challenge_put_in_correct_order_images') {
      sendAnswerMutate({ order: curentDnDOrder });
    } else if (
      taskType === 'challenge_match_image_with_description' ||
      taskType === 'challenge_match_text_with_description'
    ) {
      sendAnswerMutate(matchTextWithDescriptionCurrentAnswer);
    }
    dispatch(setIsAnswerChecked(true));
  };

  const handleTryAgainTask = () => {
    dispatch(resetSliceWithoutCourseData());
    refetchCourseInfo();
    refetchTopic();
    window.scrollTo(0, 0);
  };

  const handleSetCurentDnDOrder = useCallback(
    (dropzoneId: string, elementId: string) => {
      const newArray = moveElementInArray(
        curentDnDOrder,
        elementId,
        dropzoneId
      );
      dispatch(setCurentDnDOrder([...newArray]));
    },
    [curentDnDOrder]
  );

  const handleSetMatchTextWithDescriptionCurrentAnswer = useCallback(
    (
      dropzoneId: string,
      elementId: string,
      removeFromPreviousPosition = false
    ) => {
      dispatch(
        setOneMatchTextWithDescriptionCurrentAnswer({
          dropzoneId,
          elementId,
          removeFromPreviousPosition,
        })
      );
    },
    [matchTextWithDescriptionCurrentAnswer]
  );

  const goToNextTopic = () => {
    const pathElements = topicPath?.split?.('/');
    const currentCoursePath = pathElements?.[1];
    const currentIssuePath = pathElements?.[2];
    const currentTopicPath = pathElements?.[3];
    const currentIssue = courseData?.issues?.find?.(
      (issue) => issue?.path === currentIssuePath
    );
    const currentIssueIndex = courseData?.issues?.findIndex?.(
      (issue) => issue?.path === currentIssuePath
    );
    const currentTopicIndex = currentIssue?.topics?.findIndex?.(
      (topic) => topic?.path === currentTopicPath
    );

    let newIssuePath = null;
    let newTopicPath = null;
    let newPath = `${PATHS.ONLINE_TRAININGS}/${currentCoursePath}`;

    // paths have been found
    if (currentTopicIndex > -1 && currentIssueIndex > -1) {
      // thera are another topic in isssue
      if (currentTopicIndex + 1 < currentIssue?.topics?.length) {
        newIssuePath = currentIssuePath;
        newTopicPath = currentIssue?.topics?.[currentTopicIndex + 1]?.path;
      }
      // this is the last topic in issue
      else if (currentTopicIndex + 1 === currentIssue?.topics?.length) {
        // there is another issue - take firs topic from it
        if (currentIssueIndex + 1 < courseData?.issues?.length) {
          newIssuePath = courseData?.issues?.[currentIssueIndex + 1]?.path;
          newTopicPath =
            courseData?.issues?.[currentIssueIndex + 1]?.topics?.[0]?.path;
        }
        // this is last issue and last topic - go to course overview
        else if (currentIssueIndex + 1 === courseData?.issues?.length) {
          //
        }
      }

      if (newIssuePath && newTopicPath) {
        newPath = newPath.concat(`/${newIssuePath}/${newTopicPath}`);
      }

      navigate(newPath);
    }
  };

  const continueCourse = () => {
    const pathElements = topicPath?.split?.('/');
    const currentCoursePath = pathElements?.[1];

    const firstNotDoneIssue = courseData?.issues?.find?.(
      (isssue) => isssue?.is_done == false
    );
    const firstNotDoneTopic = firstNotDoneIssue?.topics?.find?.(
      (topic) => topic?.is_done == false
    );
    const continueCoursePath = `${PATHS.ONLINE_TRAININGS}/${currentCoursePath}/${firstNotDoneIssue?.path}/${firstNotDoneTopic?.path}`;

    navigate(continueCoursePath);
  };

  return {
    courseData,
    isFetchingCourseInfo,
    topicPath,
    tasks,
    correctAnswer,
    isAnswerChecked,
    checkedAnswers,
    handleSetCheckedAnswersSingle,
    handleSetCheckedAnswersMultiple,
    checkAnswers,
    isQuestionDone,
    isSuccessSendAnswer,
    totalPoints,
    obtainedPoints,
    handleTryAgainTask,
    goToNextTopic,
    continueCourse,
    curentDnDOrder,
    handleSetCurentDnDOrder,
    matchTextWithDescriptionCurrentAnswer,
    handleSetMatchTextWithDescriptionCurrentAnswer,
  };
};

/* eslint-disable no-useless-escape */
export const ERROR_MESSAGES = {
  PASSWORD_LENGHT: 'registration.errors.passwordLength',
  PHONE_LENGHT: 'registration.errors.phoneLength',
  PASSWORD_STRENGTH: 'registration.errors.passwordStrength',
  PASSWORD_MATCH: 'registration.errors.passwordMatch',
  REQUIRED: 'registration.errors.required',
  EMAIL: 'registration.errors.email',
};

export const PASSWORD_REGEX =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[§!@#\$%^&*()_+\-=\[\]{};\\\':\"|,.\<>?`~])(?=\S+$).+$/;

export const PASSWORD_ADNOTATION = 'registration.errors.passwordAdnotation';

import React from 'react';
import cls from './internet-explorer.module.scss';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import { ReactComponent as Logo } from 'assets/images/internet-explorer/logo.svg';
import { ReactComponent as Chrome } from 'assets/images/internet-explorer/chrome_logo.svg';
import { ReactComponent as Firefox } from 'assets/images/internet-explorer/firefox_logo.svg';
import { useTranslation } from 'react-i18next';

export const InternetExplorer = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'internetExplorer',
  });

  return (
    <div className={cls.container}>
      <Logo className={cls.logo} />
      <Heading headingLevel="h1" headingStyle="h2" className={cls.header}>
        {t('title')}
      </Heading>
      <Paragraph>{t('desc1')}</Paragraph>
      <Paragraph className={cls.desc}>{t('desc2')}</Paragraph>
      <Paragraph className={cls.desc2}>{t('desc3')}</Paragraph>
      <div className={cls.browsers}>
        <div className={cls.browser}>
          <Chrome className={cls.browserIcon} />
          <Paragraph className={cls.title}>{t('browser1')}</Paragraph>
          <a
            className={cls.link}
            href="https://www.google.com/chrome/"
            target="_blank"
          >
            {t('downloadLink')}
          </a>
        </div>
        <div className={cls.browser}>
          <Firefox className={cls.browserIcon} />
          <Paragraph className={cls.title}>{t('browser2')}</Paragraph>
          <a
            className={cls.link}
            href="https://www.mozilla.org/en-US/firefox/new/"
            target="_blank"
          >
            {t('downloadLink')}
          </a>
        </div>
      </div>
      <Paragraph>
        {t('desc4')}{' '}
        <a className={cls.gotoLink} href="https://akcjaczysterece.pl/">
          {t('linkToPage')}
        </a>{' '}
      </Paragraph>
    </div>
  );
};

import { CustomHeader } from 'components/universal/custom-header';
import { useTranslation } from 'react-i18next';
import { ShopView } from 'views/shop';

export const Shop = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'SEO.shop',
  });

  return (
    <>
      <CustomHeader title={t('title')} />
      <ShopView />
    </>
  );
};

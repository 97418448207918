import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  isModalOpen: boolean;
};

const initialState: InitialState = {
  isModalOpen: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state) => {
      state.isModalOpen = true;
    },
    hideModal: (state) => {
      state.isModalOpen = false;
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;

export const modalSliceReducer = modalSlice.reducer;

import { ERROR_MESSAGES } from 'constants/validation-error-messages';
import * as yup from 'yup';

export const newsletterSchema = yup.object({
  first_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  last_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  email: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.EMAIL),
  workplace_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  employee_position_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  checkbox_a: yup.string().required(ERROR_MESSAGES.REQUIRED),
  checkbox_b: yup.string().required(ERROR_MESSAGES.REQUIRED),
  checkbox_c: yup.string().required(ERROR_MESSAGES.REQUIRED),
  checkbox_d: yup.boolean().oneOf([true], ERROR_MESSAGES.REQUIRED),
});

import { LS_AUTH_KEY } from 'constants/variables';

export const setToken = (token: string) => {
  localStorage.setItem(LS_AUTH_KEY, token);
};

export const getToken = (): string => localStorage.getItem(LS_AUTH_KEY);

export const removeToken = () => {
  localStorage.removeItem(LS_AUTH_KEY);
};

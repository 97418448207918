import articlesTranslation from './articles/index.json';
import caseStudyTranslation from './case-study/index.json';
import presentationTranslation from './presentation/index.json';
import whyItMattersTranslation from './why-it-matters/index.json';

const knowledgeBaseTranslation = {
  articles: articlesTranslation,
  caseStudy: caseStudyTranslation,
  presentation: presentationTranslation,
  whyItMatters: whyItMattersTranslation,
};

export default knowledgeBaseTranslation;

import clsx from 'clsx';
import React, { FC } from 'react';
import { useDrop } from 'react-dnd';
import { DnDTypes } from 'types/elearning';
import { DragSource, TextDnd } from '../text-dnd';
import cls from './drop-zone.module.scss';

interface IProps {
  id: string;
  handleDrop: (
    dropzoneId: string,
    elementId: string,
    removeFromPreviousPosition: boolean
  ) => void;
  description: string;
  image_url?: string;
  image: [string, string];
}

export const DropZone: FC<IProps> = ({
  id,
  handleDrop,
  description,
  image_url,
  image,
}) => {
  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: DnDTypes.MATCH_IMAGE_WITH_DESCRIPTION,
    drop: (item: { draggedElementId: string; source: DragSource }) => {
      handleDrop(
        id,
        item?.draggedElementId,
        item?.source === 'drop-area' ? true : false
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  const clsDropzone = clsx(cls.dropzone, {
    [cls.dropzoneOver]: isOver,
    [cls.dropzoneWithImg]: Boolean(image_url),
  });

  return (
    <div className={cls.container}>
      <div ref={dropRef} className={clsDropzone}>
        {image_url ? (
          <TextDnd
            id={image?.[0]}
            text={image_url as string}
            source="drop-area"
          />
        ) : (
          <div className={cls.dashedBoxWrapper}>
            <div className={cls.dashedBox} />
          </div>
        )}
      </div>
      <div className={cls.descriptionWrapper}>
        <div className={cls.description}>{description}</div>
      </div>
    </div>
  );
};

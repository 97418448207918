import React from 'react';
import { useTranslation } from 'react-i18next';

export const NewsletterClause = () => {
  const { t } = useTranslation();

  const paragraphs = [
    'p1',
    'p2',
    'p3',
    'p4',
    'p5',
    'p6',
    'p7',
    'p8',
    'p9',
    'p10',
    'p11',
    'p12',
    'p13',
    'p14',
  ];
  return (
    <p className="text-left">
      {paragraphs.map((p, index) => (
        <>
          {t(`newsletter.modal.accordion.${p}`)}
          {index + 1 !== paragraphs.length && (
            <>
              <br />
              <br />
            </>
          )}
        </>
      ))}
    </p>
  );
};

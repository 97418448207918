import { TasksGenerator } from 'components/elearning/tasks/tasks-generator';
import { TopicStatusWithButton } from 'components/elearning/topic-status-with-button';
import Heading from 'components/universal/heading';
import { useTopic } from 'hooks/useTopic';
import React from 'react';
import { ReactComponent as WhoSVG } from 'assets/images/elearning/preparing-your-hands-to-work/who_logo.svg';
import { ReactComponent as JewelrySVG } from 'assets/images/elearning/preparing-your-hands-to-work/jewelry.svg';
import { ReactComponent as FingernailSVG } from 'assets/images/elearning/preparing-your-hands-to-work/fingernail.svg';
import { ReactComponent as WatchSVG } from 'assets/images/elearning/preparing-your-hands-to-work/watch.svg';

import cls from './preparing-your-hands-to-work.module.scss';
import Paragraph from 'components/universal/paragraph';
import { Carousel } from 'components/universal/carousel';
import artificialNailsImg from 'assets/images/elearning/preparing-your-hands-to-work/sztuczne-paznokcie.png';
import jewelryImg from 'assets/images/elearning/preparing-your-hands-to-work/bizuteria.png';
import { useTranslation } from 'react-i18next';

export const PreparingYourHandsToWork = () => {
  const { t } = useTranslation('', {
    keyPrefix:
      'elearning.handHygiene.handDisinfectionAndWashing.preparingYourHandsToWork',
  });
  const { tasks, correctAnswer, isAnswerChecked } = useTopic();

  const carousel1 = [
    {
      original: artificialNailsImg,
    },
  ];
  const carousel2 = [
    {
      original: jewelryImg,
    },
  ];

  return (
    <div>
      <div className={cls.title}>
        <Heading className={cls.header} headingLevel="h1" headingStyle="h3">
          {t('title1')}
        </Heading>
        <WhoSVG className={cls.logo} />
      </div>
      <Paragraph className={cls.desc}>{t('desc1')}</Paragraph>
      <div className={cls.iconRow}>
        <JewelrySVG className={cls.icon} />
        <Paragraph className={cls.iconText}>{t('desc2')}</Paragraph>
      </div>
      <div className={cls.iconRow}>
        <FingernailSVG className={cls.icon} />
        <Paragraph className={cls.iconText}>{t('desc3')}</Paragraph>
      </div>
      <div className={cls.iconRow}>
        <WatchSVG className={cls.icon} />
        <Paragraph className={cls.iconText}>{t('desc4')}</Paragraph>
      </div>
      <Heading headingLevel="h3" className={cls.sectionHeader}>
        {t('title2')}
      </Heading>
      <Carousel items={carousel1} showIndex={false} showNav={false} />
      <p className={cls.tip}>{t('desc5')}</p>
      <Paragraph className={cls.text}>{t('desc6')}</Paragraph>
      <Paragraph className={cls.text}>{t('desc7')}</Paragraph>
      <Heading headingLevel="h3" className={cls.sectionHeader}>
        {t('title3')}
      </Heading>
      <Carousel items={carousel2} showIndex={false} showNav={false} />
      <p className={cls.tip}>{t('desc8')}</p>
      <Paragraph className={cls.text}>{t('desc9')}</Paragraph>
      <div className="divider" />
      <Heading className="mb-10" headingLevel="h3">
        {t('title4')}
      </Heading>
      <TasksGenerator
        tasks={tasks}
        isChecked={isAnswerChecked}
        correctAnswer={correctAnswer}
      />
      <TopicStatusWithButton />
    </div>
  );
};

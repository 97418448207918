import { Button } from 'components/universal/button';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import React, { Fragment } from 'react';
import { Course } from 'types/elearning';
import cls from './overview.module.scss';
import { ReactComponent as LightbulbSvg } from 'assets/images/elearning/progress/lighbulb.svg';
import { ReactComponent as DocSvg } from 'assets/images/elearning/progress/doc.svg';
import { ReactComponent as QuestionBubbleSvg } from 'assets/images/elearning/progress/question-bubble.svg';
import { ReactComponent as CertificateSvg } from 'assets/images/elearning/progress/certificate.svg';
import { Circle } from './circle';
import { Certificate } from './certificate';
import { useTranslation } from 'react-i18next';

interface IProps {
  courseData: Course;
  continueCourse: () => void;
}

export const Overview = ({ courseData, continueCourse }: IProps) => {
  const { t } = useTranslation('', {
    keyPrefix: 'elearning.handHygiene.courseLayout.overview',
  });
  const mapTextWithNewline = (text: string) =>
    text.split('\\n').map((t) => (
      <Fragment key={t}>
        <Paragraph className={cls.desc}>{t}</Paragraph>
      </Fragment>
    ));

  const courseProgress = courseData.stats.progress;
  const isCourseFinished = courseProgress === 100;
  const btnText = () => {
    if (courseProgress === 0) return t('startTraining');
    return isCourseFinished ? t('trainingFinished') : t('continueTraining');
  };

  return (
    <div className={cls.container}>
      <div className={cls.wrapper}>
        <div className={cls.left}>
          <Heading headingLevel="h1" className={cls.header}>
            {courseData.name}
          </Heading>
          {mapTextWithNewline(courseData.description)}
          <Button
            className={cls.btn}
            disabled={isCourseFinished}
            onClick={!isCourseFinished && continueCourse}
          >
            {btnText()}
          </Button>
        </div>
        <div className={cls.right}>
          <div className={cls.progress}>
            <p className={cls.text}>
              <b>{t('trainingProgress')}</b>
            </p>
            <div className={cls.circle}>
              <Circle courseProgress={courseProgress} />
            </div>
          </div>
          <div className={cls.details}>
            <p className={cls.text}>
              <b>{t('trainingDetails')}</b>
            </p>
            <div className={cls.row}>
              <LightbulbSvg className={cls.icon} />
              <p className={cls.text}>
                {t('trainingIssues')} <b>{courseData.stats.issues}</b>
              </p>
            </div>
            <div className={cls.row}>
              <DocSvg className={cls.icon} />
              <p className={cls.text}>
                {t('trainingTopics')} <b>{courseData.stats.topics}</b>
              </p>
            </div>
            <div className={cls.row}>
              <QuestionBubbleSvg className={cls.icon} />
              <p className={cls.text}>
                {t('trainingQuizs')} <b>{courseData.stats.quizzes}</b>
              </p>
            </div>
            <div className={cls.row}>
              <CertificateSvg className={cls.icon} />
              <p className={cls.text}>
                <b>{t('trainingCertificates')}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      {isCourseFinished && (
        <div className={cls.certWrapper}>
          <Certificate />
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { ResetPasswordView } from 'views/reset-password';

export const ResetPassword = () => {
  return (
    <>
      <ResetPasswordView />
    </>
  );
};

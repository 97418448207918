import React from 'react';
import cls from './healthcare.module.scss';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import ecolabFacilityImg from 'assets/images/about-action/ecolab-facility.png';
import { FooterIcons } from 'components/universal/footer/FooterIcons';
import { ExternalLink } from 'components/universal/custom-link/External';
import { URLS } from 'constants/external-urls';
import { useTranslation } from 'react-i18next';

export const HealthcareSection = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.wrapper}>
      <div className={cls.container}>
        <div className={cls.content}>
          <Heading headingLevel="h2" className={cls.header}>
            {t('aboutAction.healthcare.title')}
          </Heading>
          <Paragraph className={cls.desc}>
            {t('aboutAction.healthcare.desc')}
          </Paragraph>
          <ExternalLink href={URLS.ECOLAB} target="_blank" className={cls.btn}>
            {t('aboutAction.healthcare.link')}
          </ExternalLink>
          <p className={cls.socialsText}>
            {t('aboutAction.healthcare.socials')}
          </p>
          <div className={cls.icons}>
            <FooterIcons isBlueLight />
          </div>
        </div>
        <figure className={cls.image}>
          <img src={ecolabFacilityImg} alt="" />
        </figure>
      </div>
    </div>
  );
};

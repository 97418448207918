import { QuizQuestions } from 'copy/home/hygiene-quiz';

export const getStep = (
  step: string,
  value: number,
  data: QuizQuestions
): string | null => {
  const steps = Object.keys(data);
  const index = steps.indexOf(step);
  if (index < 0 || index + value < 0) return step;
  if (index + value >= Object.keys(data).length) return null;
  if (!data[steps[index + value]]) return step;
  return steps[index + value];
};

import React, { useEffect, useRef } from 'react';
import cls from './downloadable-materials.module.scss';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import { Material } from './Material';

import fiveMomentsV1Img from 'assets/images/disinfection-techniques/5-momentow-higieny-rak-v1.png';
import fiveMomentsV1Wallpaper from 'assets/images/disinfection-techniques/wallpapers/5-momentow-higieny-rak-v1.pdf';

import fiveMomentsV2Img from 'assets/images/disinfection-techniques/5-momentow-higieny-rak-v2.png';
import fiveMomentsV2Wallpaper from 'assets/images/disinfection-techniques/wallpapers/5-momentow-higieny-rak-v2.pdf';

import surgicalDisinfectionImg from 'assets/images/disinfection-techniques/chirurgiczna-dezynfekcja-rak.png';
import surgicalDisinfectionWallpaper from 'assets/images/disinfection-techniques/wallpapers/chirurgiczna-dezynfekcja-rak.pdf';

import incubatorsImg from 'assets/images/disinfection-techniques/inkubatory.png';
import incubatorsWallpaper from 'assets/images/disinfection-techniques/wallpapers/inkubatory.pdf';

import visitorsImg from 'assets/images/disinfection-techniques/odwiedzajacy.png';
import visitorsWallpaper from 'assets/images/disinfection-techniques/wallpapers/odwiedzajacy.pdf';

import patient1Img from 'assets/images/disinfection-techniques/pacjent-1.png';
import patient1Wallpaper from 'assets/images/disinfection-techniques/wallpapers/pacjent-1.pdf';

import patient2Img from 'assets/images/disinfection-techniques/pacjent-2.png';
import patient2Wallpaper from 'assets/images/disinfection-techniques/wallpapers/pacjent-2.pdf';

import stopWashHandsImg from 'assets/images/disinfection-techniques/stop-umyj-rece.png';
import stopWashHandsWallpaper from 'assets/images/disinfection-techniques/wallpapers/stop-umyj-rece.pdf';

import stopDisinfectHandsImg from 'assets/images/disinfection-techniques/stop-zdezynfekuj-rece.png';
import stopDisinfectHandsWallpaper from 'assets/images/disinfection-techniques/wallpapers/stop-zdezynfekuj-rece.pdf';

import disinfectionTechniqueImg from 'assets/images/disinfection-techniques/technika-dezynfekcji-rak-nowa.png';
import disinfectionTechniqueWallpaper from 'assets/images/disinfection-techniques/wallpapers/technika-dezynfekcji-rak-nowa.pdf';

import washingHandsTechniqueImg from 'assets/images/disinfection-techniques/technika-mycia-rak-b5.png';
import washingHandsTechniqueWallpaper from 'assets/images/disinfection-techniques/wallpapers/technika-mycia-rak-b5.pdf';

import disinfectingHandsTechniqueImg from 'assets/images/disinfection-techniques/technika-dezynfekcji-rak-b5.png';
import disinfectingHandsTechniqueWallpaper from 'assets/images/disinfection-techniques/wallpapers/technika-dezynfekcji-rak-b5.pdf';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface LocationState {
  goToId: string;
}

export const DownloadableMaterials = () => {
  const { t } = useTranslation();
  const promoRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const state = location.state as LocationState;

  useEffect(() => {
    if (state?.goToId === 'promo-materials') {
      setTimeout(() => {
        const elementPositionY = promoRef?.current?.getBoundingClientRect().y;
        const extraOffset = -60;
        const scrollTo = elementPositionY + window.pageYOffset + extraOffset;

        window.scrollTo({
          top: scrollTo,
          behavior: 'smooth',
        });
      }, 700);
    }
  }, [promoRef?.current]);

  return (
    <div id="promo-materials" className={cls.container} ref={promoRef}>
      <Heading headingLevel="h2" className={cls.header}>
        {t('disinfectionTechnique.downloadableMaterials.title')}
      </Heading>
      <Paragraph className={cls.desc}>
        {t('disinfectionTechnique.downloadableMaterials.desc')}
      </Paragraph>
      <div className={cls.materials}>
        <Material img={fiveMomentsV2Img} download={fiveMomentsV1Wallpaper}>
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download1')}
          </Paragraph>
        </Material>
        <Material img={fiveMomentsV1Img} download={fiveMomentsV2Wallpaper}>
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download2')}
          </Paragraph>
        </Material>
        <Material
          img={surgicalDisinfectionImg}
          download={surgicalDisinfectionWallpaper}
        >
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download3')}
          </Paragraph>
        </Material>
        <Material img={incubatorsImg} download={incubatorsWallpaper}>
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download4')}
          </Paragraph>
        </Material>
        <Material img={visitorsImg} download={visitorsWallpaper}>
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download5')}
          </Paragraph>
        </Material>
        <Material img={patient1Img} download={patient1Wallpaper}>
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download6')}
          </Paragraph>
        </Material>
        <Material img={patient2Img} download={patient2Wallpaper}>
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download7')}
          </Paragraph>
        </Material>
        <Material img={stopWashHandsImg} download={stopWashHandsWallpaper}>
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download8')}
          </Paragraph>
        </Material>
        <Material
          img={stopDisinfectHandsImg}
          download={stopDisinfectHandsWallpaper}
        >
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download9')}
          </Paragraph>
        </Material>
        <Material
          img={disinfectionTechniqueImg}
          download={disinfectionTechniqueWallpaper}
        >
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download10')}
          </Paragraph>
        </Material>
        <Material
          img={washingHandsTechniqueImg}
          download={washingHandsTechniqueWallpaper}
        >
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download11')}
          </Paragraph>
        </Material>

        <Material
          img={disinfectingHandsTechniqueImg}
          download={disinfectingHandsTechniqueWallpaper}
        >
          <Paragraph className={cls.text}>
            {t('disinfectionTechnique.downloadableMaterials.download12')}
          </Paragraph>
        </Material>
      </div>
    </div>
  );
};

import { AxiosResponse, AxiosError } from 'axios';
import { Button } from 'components/universal/button';
import { GoBack } from 'components/universal/go-back';
import Heading from 'components/universal/heading';
import { Pagination } from 'components/universal/Pagination';
import Paragraph from 'components/universal/paragraph';
import { QUERY_KEYS } from 'constants/query-keys';
import { PATHS } from 'constants/routes';
import { useCart } from 'hooks/useCart';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getProducts } from 'services/shop';
import { IProduct } from 'types/cart';
import { IPagination } from 'types/pagination';
import { queryKeyWithLanguage } from 'utils/language';
import cls from './basket.module.scss';
import { Product } from './product';

export const BasketView = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'basket',
  });
  const [page, setPage] = useState(1);
  const { cart, setCart } = useCart();

  const navigate = useNavigate();

  const isBasketEmpty = !cart?.length;

  const handleSubmitOrder = () => navigate(PATHS.SUBMIT_YOUR_ORDER);

  const totalPrice =
    cart?.reduce?.(
      (acc, val) => acc + (val?.product?.price || 0) * val?.quantity,
      0
    ) || 0;

  const roundTotalPrice = (Math.round(totalPrice * 100) / 100)
    .toFixed(2)
    .replace('.', ',');

  const { data: productsData, refetch: refetchProducts } = useQuery<
    AxiosResponse<IPagination<IProduct[]>>,
    AxiosError
  >(
    [queryKeyWithLanguage(QUERY_KEYS.PROCUCSTS), page],
    () =>
      getProducts({
        page,
        per_page: (cart?.length || 0) + 20,
        filter: {
          id: cart?.map?.(
            (prouctWithQuantity) => prouctWithQuantity?.product?.id
          ),
        },
      }),
    {
      onSuccess: (pData) => updateBasket(pData),
    }
  );

  const updateBasket = (pData) => {
    const productsIds = pData?.data?.data?.map?.((product) => product.id);
    setCart((previousState) => {
      return previousState?.filter?.((productWithQuantity) =>
        productsIds?.includes?.(productWithQuantity?.product?.id)
      );
    });
  };

  useEffect(() => {
    refetchProducts();
  }, []);

  return (
    <div className={cls.container}>
      <Heading className={cls.heading} headingLevel="h1" headingStyle="h2">
        {t('title')}
      </Heading>
      <GoBack to={PATHS.SHOP} className={cls.backButton}>
        {t('goBackButton')}
      </GoBack>
      {productsData?.data?.data?.map?.((product, id) => (
        <>
          {cart?.find?.(
            (productWitchQuantity) =>
              productWitchQuantity?.product?.id == product?.id
          )?.quantity && (
            <div key={`${product?.id}-${id}`}>
              <Product
                product={product}
                quantity={
                  cart?.find?.(
                    (productWitchQuantity) =>
                      productWitchQuantity.product.id === product.id
                  )?.quantity
                }
              />
              <div className={cls.divider} />
            </div>
          )}
        </>
      ))}
      <div className={cls.pagination}>
        <Pagination
          pageCount={productsData?.data?.last_page}
          onPageChange={({ selected }) => setPage(selected + 1)}
          forcePage={page - 1}
        />
      </div>
      <div className={cls.summary}>
        <div className={cls.info}>
          <Paragraph className="mb-5">
            {t('desc1')}{' '}
            <b>
              {roundTotalPrice} {t('currency')}
            </b>
          </Paragraph>
          <Paragraph>
            {t('desc2')}{' '}
            <span className={cls.totalCostText}>0,00 {t('currency')}</span>
          </Paragraph>
        </div>
        <Button
          onClick={handleSubmitOrder}
          className={cls.sendButton}
          disabled={isBasketEmpty}
        >
          {t('buttonText')}
        </Button>
      </div>
    </div>
  );
};

import React, { FC, Fragment } from 'react';
import { Answer, Task } from 'types/elearning';
import { TaskFactory } from '../task-factory';

interface IProps {
  tasks: Task[];
  isChecked: boolean;
  correctAnswer: Answer['correct_answer'];
}

export const TasksGenerator: FC<IProps> = ({
  tasks,
  isChecked,
  correctAnswer,
}) => {
  return (
    <>
      {tasks?.map?.((task) => (
        <Fragment key={task?.id}>
          <TaskFactory
            task={task}
            isChecked={isChecked}
            correctAnswer={correctAnswer}
          />
        </Fragment>
      ))}
    </>
  );
};

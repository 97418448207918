/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import React, { FC, InputHTMLAttributes } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cls from './radio-consent.module.scss';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  register: UseFormRegister<any>;
  label1?: string;
  label2?: string;
}

export const RadioConsent: FC<IProps> = ({
  children,
  id,
  error,
  register,
  name,
  className,
  label1,
  label2,
  checked,
  ...props
}) => {
  const { t } = useTranslation();
  const clsCheckboxLabel = clsx(cls.checkboxLabel, {
    [cls.checkboxLabelChecked]: checked,
    [className]: Boolean(className),
  });

  const clsCheckbox = clsx(cls.checkbox, {
    [cls.checkboxError]: error,
  });

  return (
    <div className="mb-5">
      <label className={clsCheckboxLabel} htmlFor={id}>
        {children}
      </label>
      <div className={cls.container}>
        <label className={cls.text}>
          <input
            className={clsCheckbox}
            type="radio"
            id={id}
            name={name}
            {...register(name)}
            {...props}
            value="true"
          />
          <span className="pl-8">{label1 || t('radioConsent.agree')}</span>
        </label>
        <label className={cls.text}>
          <input
            className={cls.checkbox}
            type="radio"
            id={id}
            name={name}
            {...register(name)}
            {...props}
            value=""
          />
          <span className="pl-8">{label2 || t('radioConsent.dontAgree')}</span>
        </label>
      </div>
      {error && <p className={cls.errorBox}>{error}</p>}
    </div>
  );
};

import { ReactComponent as ArrowGoIcon } from 'assets/images/elearning/arrow_go_white_icon.svg';
import { ReactComponent as TickGreenIcon } from 'assets/images/elearning/tick_green_icon.svg';
import clsx from 'clsx';
import { PATHS } from 'constants/routes';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Issue } from 'types/elearning';
import cls from './single-course-element.module.scss';

interface IProps {
  issue: Issue;
}

export const SingleCourseElement: FC<IProps> = ({ issue }) => {
  const clsItem = clsx(cls.item, {
    [cls.itemDone]: issue?.is_done,
    [cls.itemBlocked]: !issue?.is_available,
  });

  return (
    <div className={clsItem}>
      <Link
        className={cls.itemLink}
        to={`${PATHS.HAND_HYGIENE}/${issue?.path}/${issue?.topics?.[0]?.path}`}
      >
        <div className={cls.itemTopicWrapper}>
          <div
            className={clsx(cls.itemStatus, {
              [cls.itemStatusDone]: issue?.is_done,
            })}
          >
            {issue?.is_done && <TickGreenIcon />}
          </div>
          <span>{issue?.topics?.[0]?.name}</span>
        </div>
        <ArrowGoIcon className={cls.itemArrow} />
      </Link>
    </div>
  );
};

import clsx from 'clsx';
import { useTopic } from 'hooks/useTopic';
import React, { FC, Fragment } from 'react';
import { Answer, Task } from 'types/elearning';
import { DropZone } from './drop-zone';
import { ImagePlaceholder } from './image-placeholder';
import cls from './match-text-with-description.module.scss';

interface IProps {
  task: Task;
  isChecked: boolean;
  correctAnswer: Answer['correct_answer'];
}

export const MatchTextWithDescription: FC<IProps> = ({ task, isChecked }) => {
  const {
    matchTextWithDescriptionCurrentAnswer,
    handleSetMatchTextWithDescriptionCurrentAnswer,
  } = useTopic();

  const clsWrapper = clsx(cls.wrapper, {
    [cls.wrapperDisabled]: isChecked,
  });

  return (
    <div className={clsWrapper}>
      <div className={cls.imagesArea}>
        {Object?.entries?.(task?.content?.texts)?.map?.(([key, text], id) => (
          <Fragment key={`${key}-${text}-${id}`}>
            <ImagePlaceholder
              id={key}
              text={text as string}
              isEmpty={
                matchTextWithDescriptionCurrentAnswer &&
                Object?.values?.(
                  matchTextWithDescriptionCurrentAnswer
                )?.includes?.(key)
              }
            />
          </Fragment>
        ))}
      </div>
      <div className={cls.dropArea}>
        {Object?.entries?.(task?.content?.descriptions)?.map?.(
          ([key, description], id) => {
            const image_url = task?.content?.texts?.[
              matchTextWithDescriptionCurrentAnswer?.[key]
            ] as string;

            return (
              <Fragment key={`${key}-${description}-${id}-${image_url}`}>
                <DropZone
                  id={key}
                  description={description as string}
                  image_url={image_url}
                  image={
                    Object?.entries?.(task?.content?.texts)?.find?.(
                      ([imgKey]) =>
                        imgKey === matchTextWithDescriptionCurrentAnswer?.[key]
                    ) as [string, string]
                  }
                  handleDrop={handleSetMatchTextWithDescriptionCurrentAnswer}
                />
              </Fragment>
            );
          }
        )}
      </div>
    </div>
  );
};

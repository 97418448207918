import { CustomHeader } from 'components/universal/custom-header';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitYourOrderView } from 'views/submit-your-order';

export const SubmitYourOrder = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'SEO.submitOrder',
  });

  return (
    <>
      <CustomHeader title={t('title')} />
      <SubmitYourOrderView />
    </>
  );
};

import Heading from 'components/universal/heading';
import React from 'react';
import cls from './quiz.module.scss';
import { Quiz } from 'components/quiz';
import { HYGIENE_QUIZ, QUIZ_POINTS_REPLACEMENT } from 'copy/home/hygiene-quiz';
import { useTranslation } from 'react-i18next';

export const QuizSection = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.container}>
      <div className={cls.content}>
        <Heading headingLevel="h2" className={cls.header}>
          {t('home.quiz.title')}
        </Heading>
        <Quiz
          data={HYGIENE_QUIZ}
          QUIZ_POINTS_REPLACEMENT={QUIZ_POINTS_REPLACEMENT}
        />
      </div>
    </div>
  );
};

import { useLocalStorage } from 'usehooks-ts';
import { Cart } from 'types/cart';

export const useCart = () => {
  const [cart, setCart] = useLocalStorage<Cart>('cart', []);

  const numberOfAddedProducts = cart?.reduce(
    (acc, val) => acc + Number(val.quantity),
    0
  );

  return {
    cart,
    setCart,
    numberOfAddedProducts,
  };
};

import React from 'react';
import { AboutSection } from 'views/home/about';
import { SafetySection } from 'views/home/safety';
import { TrustedUsSection } from 'views/home/trusted-us';
import { NumbersSection } from 'views/home/numbers';
import { QuizSection } from 'views/home/quiz';
import { PatronageSection } from 'views/home/patronage';
import { Newsletter } from 'components/universal/newsletter';
import { CustomHeader } from 'components/universal/custom-header';
import { useTranslation } from 'react-i18next';

export const Home = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'SEO.home',
  });

  return (
    <>
      <CustomHeader title={t('title')} />
      <SafetySection />
      <AboutSection />
      <TrustedUsSection />
      <NumbersSection />
      <QuizSection />
      <PatronageSection />
      <Newsletter />
    </>
  );
};

import {
  PasswordUpdateDTO,
  ResetPasswordDTO,
  SetNewPasswordDTO,
  SignInDTO,
  SignUpDTO,
} from 'types/user';
import { request } from 'utils/request';

export const signIn = async (userData: SignInDTO) =>
  await request('/auth/login', { method: 'POST' }, userData);

export const signUp = async (userData: SignUpDTO) =>
  await request('/auth/registration', { method: 'POST' }, userData);

export const logout = async () =>
  await request('/auth/logout/current', { method: 'POST' });

export const passwordUpdate = async (passwordData: PasswordUpdateDTO) =>
  await request(`/auth/password-update`, { method: 'POST' }, passwordData);

export const sendEmailForPasswordReset = async (data: ResetPasswordDTO) =>
  await request(`/auth/password-reset/send-email`, { method: 'POST' }, data);

export const passwordReset = async (data: SetNewPasswordDTO) =>
  await request('/auth/password-reset', { method: 'POST' }, data);

export const getUserData = async () => await request('/user/read', {});

import { PATHS } from 'constants/routes';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { logout } from 'services/registration';
import { Permission, UserData } from 'types/user';
import { getToken, removeToken } from 'utils/local-storage-token';

export const useUser = () => {
  const [userData, setUserData] = useLocalStorage<UserData>('userData', null);
  const navigate = useNavigate();
  const isPremium = userData?.is_premium;
  const isAdmin = userData?.is_admin;
  const isAuthenticated = Boolean(getToken());
  const hasElearningPermission = Boolean(userData?.has_elearning_permission);

  const logoutUser = () => logoutMutation.mutate();

  const logoutMutation = useMutation(() => logout(), {
    onSuccess: () => {
      removeToken();
      setUserData(null);
      navigate(PATHS.HOME);
    },
  });

  const hasUserRequiredPermissions = (permission: Permission) => {
    switch (permission) {
      case 'premium':
        return isPremium;
      case 'admin':
        return isAdmin;
      default:
        return true;
    }
  };

  return {
    userData,
    setUserData,
    isPremium,
    isAdmin,
    isAuthenticated,
    hasElearningPermission,
    hasUserRequiredPermissions,
    logoutUser,
  };
};

import { request } from 'utils/request';

export const getArticles = async () => await request(`/knowledge/all`);

export const getProtectedFile = async (path: string) =>
  await request(
    path,
    { method: 'GET', responseType: 'blob' },
    {},
    { omitApiUrl: true }
  );

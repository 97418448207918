import { ReactComponent as CrosIcon } from 'assets/images/shop/cross.svg';
import Paragraph from 'components/universal/paragraph';
import { useCart } from 'hooks/useCart';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IProduct } from 'types/cart';
import { replaceToPositiveNumbers } from 'utils/regexp';
import cls from './product.module.scss';

interface IProps {
  product: IProduct;
  quantity: number;
}

export const Product: FC<IProps> = ({ product, quantity }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'basket',
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const { setCart } = useCart();

  const handleChange = (event) => {
    const digitValue = replaceToPositiveNumbers(event.target.value);
    event.target.value = Number(digitValue);
  };

  const removeProduct = () => {
    setCart((previousState) => {
      return previousState?.filter?.(
        (productWithQuantity) =>
          productWithQuantity?.product?.id !== product?.id
      );
    });
  };

  const changeProductQuantity = (e) => {
    e.preventDefault();
    const newQuantity = Number(inputRef?.current.value);

    if (newQuantity > 0) {
      setCart((previousState) => {
        const newProducts = previousState?.map?.((productWithQuantity) => {
          if (productWithQuantity?.product?.id === product?.id) {
            const newProductWithQuantity = productWithQuantity;
            newProductWithQuantity.quantity = newQuantity;

            return newProductWithQuantity;
          }

          return productWithQuantity;
        });

        return [...newProducts];
      });

      toast.info(t('updateProductNumberSuccess'));
    } else {
      toast.error(t('updateProductNumberError'));
    }
  };

  return (
    <div className={cls.container}>
      <div className={cls.leftWrapper}>
        <img src={product?.thumbnail_url} className={cls.image} />
        <div className={cls.info}>
          <Paragraph className={cls.title}>{product?.title}</Paragraph>
          <Paragraph className={cls.description}>
            {product?.description}
          </Paragraph>
        </div>
      </div>
      <div className={cls.rightWrapper}>
        <form
          className={cls.productGroup}
          noValidate
          onSubmit={changeProductQuantity}
        >
          <input
            ref={inputRef}
            className={cls.input}
            defaultValue={quantity}
            type="text"
            name="quantity"
            onChange={handleChange}
            onBlur={changeProductQuantity}
            maxLength={4}
          />
        </form>
        <button className={cls.removeButton} onClick={removeProduct}>
          <Paragraph className={cls.removeButtonText}>
            {t('removeProduct')}
          </Paragraph>
          <CrosIcon className={cls.crossIcon} />
        </button>
      </div>
    </div>
  );
};

import { ReactComponent as Logo } from 'assets/images/navigation/logo.svg';
import { ReactComponent as LogoEng } from 'assets/images/navigation/logo-eng.svg';
import clsx from 'clsx';
import { navigationConfig, PATHS } from 'constants/routes';
import Hamburger from 'hamburger-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { LanguageChange } from './language-change';
import { LoginComponent } from './login-component';
import cls from './navigation.module.scss';

export const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { t, i18n } = useTranslation();

  const clsNavigationItemsMobile = clsx(cls.navigationItemsMobile, {
    [cls.isMenuOpen]: isMenuOpen === true,
  });

  const { pathname } = useLocation();
  useEffect(() => {
    setIsMenuOpen(false);
  }, [pathname]);

  return (
    <>
      <div className={cls.navigationContainer}>
        <div className={cls.navigationContent}>
          <Link to={PATHS.HOME} reloadDocument={PATHS.HOME === pathname}>
            {i18n.language.includes('pl') && <Logo className={cls.logo} />}
            {i18n.language.includes('eng') && <LogoEng className={cls.logo} />}
          </Link>
          <nav className={cls.navigation}>
            {navigationConfig.map(({ path, name, disabled }) => (
              <React.Fragment key={path}>
                {disabled ? (
                  <> </>
                ) : (
                  <Link
                    key={path}
                    to={path}
                    className={cls.navigationItem}
                    reloadDocument={path === pathname}
                  >
                    {t(`navigation.${name}`)}
                  </Link>
                )}
              </React.Fragment>
            ))}
            <div className={cls.navigationLogin}>
              <LoginComponent />
            </div>
            <LanguageChange />
          </nav>
          <nav className={cls.navigationMobile}>
            <div className={cls.burgerWrapper}>
              <Hamburger
                toggled={isMenuOpen}
                onToggle={setIsMenuOpen}
                size={24}
                label={
                  isMenuOpen
                    ? t(`navigation.closeMenu`)
                    : t(`navigation.openMenu`)
                }
              />
            </div>
            <LoginComponent />
          </nav>
        </div>
      </div>
      <nav className={clsNavigationItemsMobile}>
        {navigationConfig.map(({ path, name, disabled }) => (
          <React.Fragment key={path}>
            {disabled ? (
              <> </>
            ) : (
              <Link
                key={path}
                to={path}
                className={cls.navigationItem}
                reloadDocument={path === pathname}
              >
                {t(`navigation.${name}`)}
              </Link>
            )}
          </React.Fragment>
        ))}
        <LanguageChange />
      </nav>
    </>
  );
};

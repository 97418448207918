import { yupResolver } from '@hookform/resolvers/yup';
import lockImg from 'assets/images/registration/lock.png';
import { AxiosError, AxiosResponse } from 'axios';
import { Button } from 'components/universal/button';
import Heading from 'components/universal/heading';
import { Input } from 'components/universal/input';
import Paragraph from 'components/universal/paragraph';
import { PATHS } from 'constants/routes';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { sendEmailForPasswordReset } from 'services/registration';
import { APIErrors } from 'types/api';
import { ResetPasswordDTO } from 'types/user';
import clsResetPassword from './reset-password.module.scss';
import cls from './../login/login.module.scss';
import { schema } from './validation';
import { GoBack } from 'components/universal/go-back';
import { useTranslation } from 'react-i18next';

export interface IResetPasswordValues {
  email: string;
}

export const ResetPasswordView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IResetPasswordValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = (DTO) => resetPasswordMutation.mutate(DTO);

  const resetPasswordMutation = useMutation<
    AxiosResponse,
    AxiosError<APIErrors>,
    ResetPasswordDTO
  >((data) => sendEmailForPasswordReset(data), {
    onSuccess: () => {
      navigate(PATHS.CONFIRM_RESET_PASSWORD, {
        state: {
          email: watch('email'),
        },
      });
    },
  });

  return (
    <div className={cls.container}>
      <div className={cls.columnLeft}>
        <Heading className={cls.heading} headingLevel="h1" headingStyle="h2">
          {t('resetPassword.title')}
        </Heading>
        <Paragraph className={clsResetPassword.paragraph}>
          {t('resetPassword.desc1')}
        </Paragraph>
        <form className={cls.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="mb-4"
            register={register}
            id="email"
            name="email"
            label={t('resetPassword.email')}
            error={t(errors.email?.message)}
            maxLength={255}
          />
          <p className="mb-10">{t('resetPassword.requiredFields')}</p>
          <Button className="mx-auto mb-5" type="submit">
            {t('resetPassword.buttonText')}
          </Button>
        </form>
        <GoBack to={PATHS.LOGIN} className="mb-4">
          {t('resetPassword.goBackButton')}
        </GoBack>
      </div>
      <img src={lockImg} className={cls.image} />
    </div>
  );
};

import clsx from 'clsx';
import React, { HTMLAttributes, useEffect } from 'react';
import cls from './modal.module.scss';
import { ReactComponent as CloseSvg } from 'assets/images/generate-certificate/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { hideModal } from 'store/modal/modalSlice';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const Modal = ({ className, children, ...props }: IProps) => {
  const clsModal = clsx(cls.modal, {
    [className]: Boolean(className),
  });

  const { isModalOpen } = useSelector((state: RootState) => state.modal);

  const dispatch = useDispatch();
  const closeModal = () => dispatch(hideModal());

  useEffect(() => {
    if (isModalOpen) document.body.style.overflowY = 'hidden';
    else document.body.style.overflowY = 'auto';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isModalOpen]);

  if (!isModalOpen) return null;
  return (
    <div className={clsModal}>
      <div className={cls.bg} onClick={closeModal} />
      <div className={cls.wrapper}>
        <CloseSvg className={cls.close} onClick={closeModal} />
        <div className={cls.content} {...props}>
          {children}
        </div>
      </div>
    </div>
  );
};

import { Layout } from 'components/universal/layout';
import {
  PATHS,
  PATHS_HAND_HYGIENE,
  PATHS_MICROBIOLOGY,
  PATHS_MOMENTS_OF_HAND_HYGIENE_ANIMATIONS,
  PATH_HAND_DISINFECTION_AND_WASHING,
} from 'constants/routes';
import PrivateRoute from 'features/private-route';
import { AboutAction } from 'pages/about-action';
import { ConfirmEmailAddress } from 'pages/confirm-email-address';
import { ConfirmResetPassword } from 'pages/confirm-reset-password';
import { Contact } from 'pages/contact';
import { DisinfectionTechniques } from 'pages/disinfection-techniques';
import { EmailVeryficationError } from 'pages/email-verification-error';
import { EmailVeryficationSuccess } from 'pages/email-verification-success';
import { GenerateCertificate } from 'pages/generate-certificate';
import { Home } from 'pages/home';
import { InternetExplorer } from 'pages/internet-explorer';
import { KnowledgeBase } from 'pages/knowledge-base';
import { Login } from 'pages/login';
// import { OnlineTrainings } from 'pages/online-trainings';
import { HandHygiene } from 'pages/online-trainings/hand-hygiene';
import { CourseLayout } from 'pages/online-trainings/hand-hygiene/course-layout';
import { DisinfectionAndWashing } from 'pages/online-trainings/hand-hygiene/hand-disinfection-and-washing/disinfection-and-washing';
import { HandCare } from 'pages/online-trainings/hand-hygiene/hand-disinfection-and-washing/hand-care';
import { HandHygieneAndMedicalGloves } from 'pages/online-trainings/hand-hygiene/hand-disinfection-and-washing/hand-hygiene-and-medical-gloves';
import { PreparingYourHandsToWork } from 'pages/online-trainings/hand-hygiene/hand-disinfection-and-washing/preparing-your-hands-to-work';
import { ProperHandDisinfection } from 'pages/online-trainings/hand-hygiene/hand-disinfection-and-washing/proper-hand-disinfection';
import { ProperHandWashing } from 'pages/online-trainings/hand-hygiene/hand-disinfection-and-washing/proper-hand-washing';
import { HandBorneMicroorganisms } from 'pages/online-trainings/hand-hygiene/microbiology/hand-borne-microorganisms';
import { HospitalInfections } from 'pages/online-trainings/hand-hygiene/microbiology/hospital-infections';
import { FiveMomentsHandHygiene } from 'pages/online-trainings/hand-hygiene/moments-of-hand-hygiene-animations/five-moments-hand-hygiene';
import { Scenario1 } from 'pages/online-trainings/hand-hygiene/moments-of-hand-hygiene-animations/scenario-1';
import { Scenario2 } from 'pages/online-trainings/hand-hygiene/moments-of-hand-hygiene-animations/scenario-2';
import { Scenario3 } from 'pages/online-trainings/hand-hygiene/moments-of-hand-hygiene-animations/scenario-3';
import { Scenario4 } from 'pages/online-trainings/hand-hygiene/moments-of-hand-hygiene-animations/scenario-4';
import { HandHygieneQuiz } from 'pages/online-trainings/hand-hygiene/quiz';
// import { PasswordUpdate } from 'pages/password-update';
import { Registration } from 'pages/registration';
import { ResetPassword } from 'pages/reset-password';
import { SetNewPassword } from 'pages/set-new-password';
import { Styleguide } from 'pages/styleguide';
import { Shop } from 'pages/shop';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Basket } from 'pages/basket';
import { SubmitYourOrder } from 'pages/submit-your-order';

function App() {
  const isIE = /*@cc_on!@*/ false || !!document['documentMode'];

  if (isIE) return <InternetExplorer />;

  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route path={PATHS.HOME} element={<Home />} />
          <Route path={PATHS.LOGIN} element={<Login />} />
          <Route path={PATHS.REGISTRATION} element={<Registration />} />
          {/* Not required yet */}
          {/* <Route
            path={PATHS.PASSWORD_UPDATE}
            element={
              <PrivateRoute>
                <PasswordUpdate />
              </PrivateRoute>
            }
          /> */}
          <Route path={PATHS.RESET_PASSWORD} element={<ResetPassword />} />
          <Route
            path={PATHS.CONFIRM_RESET_PASSWORD}
            element={<ConfirmResetPassword />}
          />
          <Route path={PATHS.SET_NEW_PASSWORD} element={<SetNewPassword />} />
          <Route
            path={PATHS.EMAIL_VERIFICATION_SUCCESS}
            element={<EmailVeryficationSuccess />}
          />
          <Route
            path={PATHS.EMAIL_VERIFICATION_ERROR}
            element={<EmailVeryficationError />}
          />
          <Route
            path={PATHS.CONFIRM_EMAIL_ADDRESS}
            element={<ConfirmEmailAddress />}
          />
          <Route path={PATHS.ABOUT_ACTION} element={<AboutAction />} />
          <Route path={PATHS.KNOWLEDGE_BASE} element={<KnowledgeBase />} />
          {process.env.NODE_ENV !== 'production' && (
            <Route path={PATHS.STYLEGUIDE} element={<Styleguide />} />
          )}
          <Route
            path={PATHS.DISINFECTION_TECHNIQUES}
            element={<DisinfectionTechniques />}
          />
          <Route path={PATHS.CONTACT} element={<Contact />} />
          <Route
            path={`${PATHS_HAND_HYGIENE.MICROBIOLOGY}`}
            element={<CourseLayout />}
          >
            <Route
              path={PATHS_MICROBIOLOGY.HOSPITAL_INFECTIONS}
              element={<HospitalInfections />}
            />
            <Route
              path={PATHS_MICROBIOLOGY.HAND_BORNE_MICROORGANISMS}
              element={<HandBorneMicroorganisms />}
            />
          </Route>
          <Route
            path={`${PATHS_HAND_HYGIENE.HAND_DISINFECTION_AND_WASHING}`}
            element={
              <PrivateRoute>
                <CourseLayout />
              </PrivateRoute>
            }
          >
            <Route
              path={
                PATH_HAND_DISINFECTION_AND_WASHING.PREPARING_YOUR_HANDS_TO_WORK
              }
              element={<PreparingYourHandsToWork />}
            />
            <Route
              path={PATH_HAND_DISINFECTION_AND_WASHING.PROPER_HAND_WASHING}
              element={<ProperHandWashing />}
            />
            <Route
              path={PATH_HAND_DISINFECTION_AND_WASHING.PROPER_HAND_DISINFECTION}
              element={<ProperHandDisinfection />}
            />
            <Route
              path={PATH_HAND_DISINFECTION_AND_WASHING.DISINFECTION_AND_WASHING}
              element={<DisinfectionAndWashing />}
            />
            <Route
              path={PATH_HAND_DISINFECTION_AND_WASHING.HAND_CARE}
              element={<HandCare />}
            />
            <Route
              path={
                PATH_HAND_DISINFECTION_AND_WASHING.HAND_HYGIENE_AND_MEDICAL_GLOVES
              }
              element={<HandHygieneAndMedicalGloves />}
            />
          </Route>
          <Route
            path={`${PATHS_HAND_HYGIENE.MOMENTS_OF_HAND_HYGIENE_ANIMATIONS}`}
            element={
              <PrivateRoute>
                <CourseLayout />
              </PrivateRoute>
            }
          >
            <Route
              path={
                PATHS_MOMENTS_OF_HAND_HYGIENE_ANIMATIONS.MOMENTS_OF_HAND_HYGIENE
              }
              element={<FiveMomentsHandHygiene />}
            />
            <Route
              path={PATHS_MOMENTS_OF_HAND_HYGIENE_ANIMATIONS.SCENARIO_1}
              element={<Scenario1 />}
            />
            <Route
              path={PATHS_MOMENTS_OF_HAND_HYGIENE_ANIMATIONS.SCENARIO_2}
              element={<Scenario2 />}
            />
            <Route
              path={PATHS_MOMENTS_OF_HAND_HYGIENE_ANIMATIONS.SCENARIO_3}
              element={<Scenario3 />}
            />
            <Route
              path={PATHS_MOMENTS_OF_HAND_HYGIENE_ANIMATIONS.SCENARIO_4}
              element={<Scenario4 />}
            />
          </Route>

          <Route
            path={PATHS_HAND_HYGIENE.QUIZ}
            element={
              <PrivateRoute>
                <CourseLayout />
              </PrivateRoute>
            }
          >
            <Route
              path={PATHS_HAND_HYGIENE.QUIZ}
              element={<HandHygieneQuiz />}
            />
          </Route>
          <Route path={PATHS.HAND_HYGIENE} element={<HandHygiene />} />
          {/* <Route path={PATHS.ONLINE_TRAININGS} element={<OnlineTrainings />} /> */}
          <Route
            path={PATHS.GENERATE_CERTIFICATE}
            element={<GenerateCertificate />}
          />
          <Route
            path={PATHS.SHOP}
            element={
              <PrivateRoute permission="premium">
                <Shop />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.BASKET}
            element={
              <PrivateRoute permission="premium">
                <Basket />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.SUBMIT_YOUR_ORDER}
            element={
              <PrivateRoute permission="premium">
                <SubmitYourOrder />
              </PrivateRoute>
            }
          />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;

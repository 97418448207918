import downloadableMaterialsTranslation from './downloadable-materials/index.json';
import howToDisinfectTranslation from './how-to-disinfect-hands/index.json';
import unauthorizedUserTranslation from './unauthorized-user/index.json';
import WhyIsHygieneImportantTranslation from './why-is-hygiene-important/index.json';

const disinfectionTechniqueTranslation = {
  downloadableMaterials: downloadableMaterialsTranslation,
  howToDisinfectHands: howToDisinfectTranslation,
  unauthorizedUser: unauthorizedUserTranslation,
  whyIsHygieneImportant: WhyIsHygieneImportantTranslation,
};

export default disinfectionTechniqueTranslation;

import React, { FC } from 'react';
import { TextDnd } from '../text-dnd';
import cls from './image-placeholder.module.scss';

interface IProps {
  id: string;
  text: string;
  isEmpty?: boolean;
}

export const ImagePlaceholder: FC<IProps> = ({ text, id, isEmpty }) => {
  return (
    <div className={cls.wrapper}>
      {isEmpty ? (
        <div className={cls.emptyBox} />
      ) : (
        <TextDnd id={id} text={text} source="start-area" />
      )}
    </div>
  );
};

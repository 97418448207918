import { CarouselItem } from 'components/universal/carousel';
import { VIDEOS } from 'constants/external-urls';

export const carouselData: CarouselItem[] = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
  {
    original:
      'https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8d2FsbCUyMGJhY2tncm91bmR8ZW58MHx8MHx8&w=1000&q=80',
    fullscreen:
      'https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8d2FsbCUyMGJhY2tncm91bmR8ZW58MHx8MHx8&w=1000&q=80',

    thumbnail:
      'https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8d2FsbCUyMGJhY2tncm91bmR8ZW58MHx8MHx8&w=1000&q=80',
  },
  {
    original: 'https://cdn.wallpapersafari.com/55/73/ghY4rc.jpg',
    fullscreen: 'https://cdn.wallpapersafari.com/55/73/ghY4rc.jpg',

    thumbnail: 'https://cdn.wallpapersafari.com/55/73/ghY4rc.jpg',
  },
  {
    original: VIDEOS.PL.HOME_ABOUT_ACTION,
    embedUrl: VIDEOS.PL.HOME_ABOUT_ACTION,
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
];

import aboutActionTranslation from './about-action/index.json';
import numbersTranslation from './numbers/index.json';
import patronageTranslation from './patronage/index.json';
import quizTranslation from './quiz/index.json';
import trustedUsTranslation from './trusted-us/index.json';

const homeTranslation = {
  aboutAction: aboutActionTranslation,
  trustedUs: trustedUsTranslation,
  numbers: numbersTranslation,
  patronage: patronageTranslation,
  quiz: quizTranslation,
};

export default homeTranslation;

import clsx from 'clsx';
import { FC } from 'react';
import { Categories } from 'types/cart';
import cls from './filters-desktop.module.scss';

interface IProps {
  categories: Categories;
  handleChangeCategory: (name: string) => void;
  currentCategory: string;
}

export const FiltersDesktop: FC<IProps> = ({
  categories,
  handleChangeCategory,
  currentCategory,
}) => {
  return (
    <div className={cls.filters}>
      <ul className={cls.list}>
        {categories?.map?.((category, id) => (
          <li
            className={clsx(cls.listItem, {
              [cls.listItemActive]: category?.name === currentCategory,
            })}
            key={`${category?.name}-${id}`}
          >
            <button
              className={cls.listItemButton}
              onClick={() => handleChangeCategory(category?.name)}
            >
              {category?.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

import navigationTranslation from './navigation';
import homeTranslation from './home';
import newsletterTranslation from './newsletter';
import radioConsentTranslation from './radio-consent';
import footerTranslation from './footer';
import aboutActionTranslation from './about-action';
import knowledgeBaseTranslation from './knowledge-base';
import disinfectionTechniqueTranslation from './disinfection-techniques';
import contactTranslation from './contact';
import loginTranslation from './login';
import confirmEmailAddressTranslation from './confirm-email-address';
import confirmResetPasswordTranslation from './confirm-reset-password';
import cookiesTranslation from './cookies';
import emailVerificationErrorTranslation from './email-verification-error';
import emailVerificationSuccessTranslation from './email-verification-success';
import generateCertificateTranslation from './generate-certificate';
import registrationTranslation from './registration';
import elearning from './elearning';
import components from './components';
import SEO from './SEO';
import setNewPassword from './set-new-password/index.json';
import resetPassword from './reset-password/index.json';
import basket from './basket/index.json';
import shop from './shop/index.json';
import submitYourOrder from './submit-your-order/index.json';
import internetExplorer from './internet-explorer/index.json';
import noCoursePermission from './no-course-permission';

const polish = {
  navigation: navigationTranslation,
  home: homeTranslation,
  newsletter: newsletterTranslation,
  radioConsent: radioConsentTranslation,
  footer: footerTranslation,
  aboutAction: aboutActionTranslation,
  knowledgeBase: knowledgeBaseTranslation,
  disinfectionTechnique: disinfectionTechniqueTranslation,
  contact: contactTranslation,
  logIn: loginTranslation,
  confirmEmailAddress: confirmEmailAddressTranslation,
  confirmResetPassword: confirmResetPasswordTranslation,
  cookies: cookiesTranslation,
  emailVerificationError: emailVerificationErrorTranslation,
  emailVerificationSuccess: emailVerificationSuccessTranslation,
  generateCertificate: generateCertificateTranslation,
  registration: registrationTranslation,
  elearning,
  components,
  SEO,
  setNewPassword,
  resetPassword,
  basket,
  shop,
  submitYourOrder,
  internetExplorer,
  noCoursePermission,
};

export default polish;

/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import { UseFormRegister } from 'react-hook-form';
import cls from './checkbox.module.scss';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  register: UseFormRegister<any>;
  label?: ReactNode;
}

export const Checkbox: FC<IProps> = ({
  label,
  id,
  error,
  register,
  name,
  className,
  checked,
  ...props
}) => {
  const clsCheckboxLabel = clsx(cls.checkboxLabel, {
    [cls.checkboxLabelChecked]: checked,
    [className]: Boolean(className),
  });

  const clsCheckbox = clsx(cls.checkbox, {
    [cls.checkboxError]: error,
  });

  return (
    <>
      <label className={clsCheckboxLabel} htmlFor={id}>
        <input
          className={clsCheckbox}
          type="checkbox"
          id={id}
          name={name}
          {...register(name)}
          {...props}
        />
        {label}
        {error && <p className={cls.errorBox}>{error}</p>}
      </label>
    </>
  );
};

import preparingYourHandsToWork from './preparing-your-hands-to-work/index.json';
import properHandDisinfection from './proper-hand-disinfection/index.json';
import properHandWashing from './proper-hand-washing/index.json';
import disinfectionAndWashing from './disinfection-and-washing/index.json';
import handCare from './hand-care/index.json';
import handHygieneAndMedicalGloves from './hand-hygiene-and-medical-gloves/index.json';

const handDisinfectionAndWashing = {
  preparingYourHandsToWork,
  properHandDisinfection,
  properHandWashing,
  disinfectionAndWashing,
  handCare,
  handHygieneAndMedicalGloves,
};

export default handDisinfectionAndWashing;

import React, { FC } from 'react';
import { useDrag } from 'react-dnd';
import { DnDTypes } from 'types/elearning';

export type DragSource = 'start-area' | 'drop-area';

interface IProps {
  id: string;
  image_url: string;
  source: DragSource;
}

export const ImageDnd: FC<IProps> = ({ image_url, id, source }) => {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: DnDTypes.MATCH_IMAGE_WITH_DESCRIPTION,
    item: {
      draggedElementId: id,
      source: source,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <figure
      ref={dragRef}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      <img src={image_url} />
    </figure>
  );
};

import { FC, useState } from 'react';
import { Categories } from 'types/cart';
import cls from './filters.module.scss';
import clsx from 'clsx';

interface IProps {
  categories: Categories;
  handleChangeCategory: (name: string) => void;
  currentCategory: string;
}

export const Filters: FC<IProps> = ({
  categories,
  handleChangeCategory,
  currentCategory,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToogleMenu = () => setIsMenuOpen((prevState) => !prevState);

  const clsFilters = clsx(cls.filters, { [cls.filtersOpen]: isMenuOpen });

  const handleChangeCategoryAndCloseMenu = (name: string) => {
    handleChangeCategory(name);
    setIsMenuOpen(false);
  };

  return (
    <div className={clsFilters}>
      <button className={cls.openButton} onClick={handleToogleMenu}>
        <div className={cls.buttonInner}>
          <div className={cls.openButtonText}>{currentCategory}</div>
          <div className={cls.arrow}>&#60;</div>
        </div>
      </button>
      <ul className={cls.list}>
        {categories?.map?.((category, id) => (
          <li
            className={clsx(cls.listItem, {
              [cls.listItemActive]: category?.name === currentCategory,
            })}
            key={`${category?.name}-${id}`}
          >
            <button
              className={cls.listItemButton}
              onClick={() => handleChangeCategoryAndCloseMenu(category?.name)}
            >
              {category?.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

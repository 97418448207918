import clsx from 'clsx';
import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import cls from './go-back.module.scss';

interface IProps extends LinkProps {
  className?: string;
}

export const GoBack: FC<IProps> = ({ className, children, ...props }) => {
  const clsButton = clsx(cls.link, {
    [className]: Boolean(className),
  });

  return (
    <Link {...props} className={clsButton}>
      <div className={cls.arrowBack} /> {children}
    </Link>
  );
};

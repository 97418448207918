import React, { FC } from 'react';
import { Answer, Task } from 'types/elearning';
import { MatchImageWithDescription } from '../match-image-with-description';
import { MatchTextWithDescription } from '../match-text-with-description';
import { PutInCorrectOrderImages } from '../put-in-correct-order-images';
import { QuestionsMultiple } from '../question/questions-multiple';
import { QuestionsSingle } from '../question/questions-single';

interface IProps {
  task: Task;
  isChecked: boolean;
  correctAnswer: Answer['correct_answer'];
}

export const TaskFactory: FC<IProps> = ({ task, isChecked, correctAnswer }) => {
  let TaskComponent = null;
  switch (task?.type) {
    case 'challenge_choose_one_answer':
      TaskComponent = QuestionsSingle;
      break;
    case 'challenge_choose_many_answers':
      TaskComponent = QuestionsMultiple;
      break;
    case 'challenge_put_in_correct_order_images':
      TaskComponent = PutInCorrectOrderImages;
      break;
    case 'challenge_match_image_with_description':
      TaskComponent = MatchImageWithDescription;
      break;
    case 'challenge_match_text_with_description':
      TaskComponent = MatchTextWithDescription;
      break;
    default:
      break;
  }

  return (
    <TaskComponent
      task={task}
      isChecked={isChecked}
      correctAnswer={correctAnswer}
    />
  );
};

import { TasksGenerator } from 'components/elearning/tasks/tasks-generator';
import { TopicStatus } from 'components/elearning/topic-status';
import { Button } from 'components/universal/button';
import Heading from 'components/universal/heading';
import { useTopic } from 'hooks/useTopic';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCanSubmitAnswer } from 'store/topic/topicSlice';

export const HandHygieneQuiz = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'elearning.handHygiene.quiz',
  });

  const {
    tasks,
    correctAnswer,
    isAnswerChecked,
    checkAnswers,
    isQuestionDone,
    isSuccessSendAnswer,
    totalPoints,
    obtainedPoints,
    handleTryAgainTask,
    goToNextTopic,
  } = useTopic();

  const [isQuizStart, setIsQuizStart] = useState(false);

  const canSubmitAnswer = useSelector(selectCanSubmitAnswer);

  const handleStartQuiz = () => setIsQuizStart(true);

  return (
    <div>
      {isQuizStart ? (
        <Fragment>
          <Heading className="mb-10" headingLevel="h1" headingStyle="h3">
            {t('title1')}
          </Heading>
          <TasksGenerator
            tasks={tasks}
            isChecked={isAnswerChecked}
            correctAnswer={correctAnswer}
          />
          {isSuccessSendAnswer ? (
            <TopicStatus
              title={isQuestionDone ? t('titleDone') : t('titleNotDone')}
              buttonText={
                isQuestionDone ? t('buttonTextDone') : t('buttonTextNotDone')
              }
              obtainedPoints={obtainedPoints}
              maxScore={totalPoints}
              isSuccess={isQuestionDone}
              onSubmit={isQuestionDone ? goToNextTopic : handleTryAgainTask}
            />
          ) : (
            <Button
              className="mx-auto"
              onClick={checkAnswers}
              disabled={!canSubmitAnswer}
            >
              {t('checkButtonText')}
            </Button>
          )}
        </Fragment>
      ) : (
        <TopicStatus
          title={t('topicStatusTitle')}
          description={t('topicStatusDescription')}
          buttonText={t('topicStatusButtonText')}
          isSuccess
          isStandalone
          onSubmit={handleStartQuiz}
        />
      )}
    </div>
  );
};

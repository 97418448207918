import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import { VideoEmbed } from 'components/universal/video-embed';
import { THUMBNAILS, VIDEOS } from 'constants/external-urls';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from './why-it-matters.module.scss';

export const WhyItMatters = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.container}>
      <div className={cls.left}>
        <Heading headingLevel="h1" className={cls.header}>
          {t('knowledgeBase.whyItMatters.title')}
        </Heading>
        <VideoEmbed
          src={VIDEOS.PL.KNOWLEDGE_BASE_INTERVIEW}
          className={cls.videoMobile}
          poster={THUMBNAILS.PL.KNOWLEDGE_BASE_INTERVIEW}
          controls
        />
        <Paragraph>{t('knowledgeBase.whyItMatters.text')}</Paragraph>
      </div>
      <div className={cls.right}>
        <VideoEmbed
          src={VIDEOS.PL.KNOWLEDGE_BASE_INTERVIEW}
          poster={THUMBNAILS.PL.KNOWLEDGE_BASE_INTERVIEW}
          controls
        />
      </div>
    </div>
  );
};

export const PATHS = {
  HOME: '/',
  ABOUT_ACTION: '/about-action',
  KNOWLEDGE_BASE: '/knowledge-base',
  DISINFECTION_TECHNIQUES: '/disinfection-technique',
  ONLINE_TRAININGS: '/online-trainings',
  CONTACT: '/contact',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  // not required yet
  // PASSWORD_UPDATE: '/password-update',
  RESET_PASSWORD: '/reset-password',
  CONFIRM_RESET_PASSWORD: '/confirm-reset-password',
  SET_NEW_PASSWORD: '/set-new-password',
  EMAIL_VERIFICATION_SUCCESS: '/email-verification-success',
  EMAIL_VERIFICATION_ERROR: '/email-verification-error',
  CONFIRM_EMAIL_ADDRESS: '/confirm-email-address',
  STYLEGUIDE: '/styleguide',
  HAND_HYGIENE: '/online-trainings/hand-hygiene',
  GENERATE_CERTIFICATE: '/certificate',
  SHOP: '/shop',
  BASKET: '/basket',
  SUBMIT_YOUR_ORDER: '/submit-your-order',
};

export const PATHS_HAND_HYGIENE = {
  MICROBIOLOGY: `${PATHS.HAND_HYGIENE}/microbiology`,
  HAND_DISINFECTION_AND_WASHING: `${PATHS.HAND_HYGIENE}/hand-disinfection-and-washing`,
  MOMENTS_OF_HAND_HYGIENE_ANIMATIONS: `${PATHS.HAND_HYGIENE}/moments-of-hand-hygiene-animations`,
  QUIZ: `${PATHS.HAND_HYGIENE}/q/quiz`,
};

export const PATHS_MICROBIOLOGY = {
  HOSPITAL_INFECTIONS: `${PATHS_HAND_HYGIENE.MICROBIOLOGY}/hospital-infections`,
  HAND_BORNE_MICROORGANISMS: `${PATHS_HAND_HYGIENE.MICROBIOLOGY}/hand-borne-microorganisms`,
};

export const PATH_HAND_DISINFECTION_AND_WASHING = {
  PREPARING_YOUR_HANDS_TO_WORK: `${PATHS_HAND_HYGIENE.HAND_DISINFECTION_AND_WASHING}/preparing-your-hands-to-work`,
  PROPER_HAND_DISINFECTION: `${PATHS_HAND_HYGIENE.HAND_DISINFECTION_AND_WASHING}/proper-hand-disinfection`,
  PROPER_HAND_WASHING: `${PATHS_HAND_HYGIENE.HAND_DISINFECTION_AND_WASHING}/proper-hand-washing`,
  DISINFECTION_AND_WASHING: `${PATHS_HAND_HYGIENE.HAND_DISINFECTION_AND_WASHING}/disinfection-and-washing`,
  HAND_CARE: `${PATHS_HAND_HYGIENE.HAND_DISINFECTION_AND_WASHING}/hand-care`,
  HAND_HYGIENE_AND_MEDICAL_GLOVES: `${PATHS_HAND_HYGIENE.HAND_DISINFECTION_AND_WASHING}/hand-hygiene-and-medical-gloves`,
};

export const PATHS_MOMENTS_OF_HAND_HYGIENE_ANIMATIONS = {
  MOMENTS_OF_HAND_HYGIENE: `${PATHS_HAND_HYGIENE.MOMENTS_OF_HAND_HYGIENE_ANIMATIONS}/moments-of-hand-hygiene`,
  SCENARIO_1: `${PATHS_HAND_HYGIENE.MOMENTS_OF_HAND_HYGIENE_ANIMATIONS}/scenario-1`,
  SCENARIO_2: `${PATHS_HAND_HYGIENE.MOMENTS_OF_HAND_HYGIENE_ANIMATIONS}/scenario-2`,
  SCENARIO_3: `${PATHS_HAND_HYGIENE.MOMENTS_OF_HAND_HYGIENE_ANIMATIONS}/scenario-3`,
  SCENARIO_4: `${PATHS_HAND_HYGIENE.MOMENTS_OF_HAND_HYGIENE_ANIMATIONS}/scenario-4`,
  SCENARIO_5: `${PATHS_HAND_HYGIENE.MOMENTS_OF_HAND_HYGIENE_ANIMATIONS}/scenario-5`,
};

export const ROUTES = {
  HOME: {
    path: PATHS.HOME,
    name: 'home',
    disabled: false,
  },
  ABOUT_ACTION: {
    path: PATHS.ABOUT_ACTION,
    name: 'aboutAction',
    disabled: false,
  },
  KNOWLEDGE_BASE: {
    path: PATHS.KNOWLEDGE_BASE,
    name: 'knowledgeBase',
    disabled: false,
  },
  DISINFECTION_TECHNIQUES: {
    path: PATHS.DISINFECTION_TECHNIQUES,
    name: 'disinfectionTechnique',
    disabled: false,
  },
  ONLINE_TRAININGS: {
    path: PATHS.ONLINE_TRAININGS,
    name: 'onlineTrainings',
    disabled: false,
  },
  HAND_HYGIENE: {
    path: PATHS.HAND_HYGIENE,
    name: 'onlineTrainings',
    disabled: false,
  },
  CONTACT: {
    path: PATHS.CONTACT,
    name: 'contact',
    disabled: false,
  },
  LOGIN: {
    path: PATHS.LOGIN,
    name: 'logIn',
    disabled: false,
  },
  REGISTRATION: {
    path: PATHS.REGISTRATION,
    name: 'register',
    disabled: false,
  },
  // not required yet
  // PASSWORD_UPDATE: {
  //   path: PATHS.PASSWORD_UPDATE,
  //   name: 'Zmień hasło',
  // },
  RESET_PASSWORD: {
    path: PATHS.RESET_PASSWORD,
    name: 'resetPassword',
    disabled: false,
  },
  SET_NEW_PASSWORD: {
    path: PATHS.RESET_PASSWORD,
    name: 'setNewPassword',
    disabled: false,
  },
};

export const navigationConfig = [
  ROUTES.ABOUT_ACTION,
  ROUTES.KNOWLEDGE_BASE,
  ROUTES.DISINFECTION_TECHNIQUES,
  ROUTES.HAND_HYGIENE,
  ROUTES.CONTACT,
];

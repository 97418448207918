import React, { FC } from 'react';
import { useDrag } from 'react-dnd';
import { DnDTypes } from 'types/elearning';
import cls from './text-dnd.module.scss';

export type DragSource = 'start-area' | 'drop-area';

interface IProps {
  id: string;
  text: string;
  source: DragSource;
}

export const TextDnd: FC<IProps> = ({ text, id, source }) => {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: DnDTypes.MATCH_IMAGE_WITH_DESCRIPTION,
    item: {
      draggedElementId: id,
      source: source,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      className={cls.textBox}
      ref={dragRef}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      {text}
    </div>
  );
};

import { Box } from 'components/universal/box';
import React, { HTMLAttributes, useState } from 'react';
import cls from './quiz.module.scss';
import { QuizData } from 'copy/home/hygiene-quiz';
import { getStep } from './get-step';
import { finishQuiz } from './finish-quiz';
import clsx from 'clsx';
import Questions from './Questions';
import Results from './Results';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  data: QuizData;
  QUIZ_POINTS_REPLACEMENT: string;
}

export const Quiz = ({
  data,
  className,
  QUIZ_POINTS_REPLACEMENT,
  ...props
}: IProps) => {
  const [checked, setChecked] = useState<string | null>(null);
  const [answers, setAnswers] = useState({});
  const [step, setStep] = useState(Object.keys(data.questions)[0]);
  const [numberOfCorrectAnswers, setNumberOfCorrectAnswers] = useState<
    number | null
  >(null);

  const clsHeading = clsx(cls.box, {
    [className]: Boolean(className),
  });

  const changeQuestion = (value: number) => {
    const nextStep = getStep(step, value, data.questions);
    if (nextStep === null) return submitAnswers();
    setStep(nextStep);
    if (value === -1) setChecked(answers[nextStep]);
    else {
      setAnswers({ ...answers, [step]: checked });
      setChecked(answers[nextStep] || null);
    }
  };

  const submitAnswers = () => {
    const correctAnswers = finishQuiz(
      { ...answers, [step]: checked },
      data.questions
    );
    setNumberOfCorrectAnswers(correctAnswers);
  };

  return (
    <Box className={clsHeading} {...props}>
      {numberOfCorrectAnswers !== null ? (
        <Results
          data={data}
          correctAnswers={numberOfCorrectAnswers}
          QUIZ_POINTS_REPLACEMENT={QUIZ_POINTS_REPLACEMENT}
        />
      ) : (
        <Questions
          data={data}
          step={step}
          checked={checked}
          setChecked={setChecked}
          changeQuestion={changeQuestion}
        />
      )}
    </Box>
  );
};

import { CustomLink } from 'components/universal/custom-link';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import { PATHS } from 'constants/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'views/disinfection-techniques/unauthorized-user/unauthorized-user.module.scss';
import doctorWorkingOnPcImg from 'assets/images/disinfection-techniques/doctor-working-on-pc.png';

export const NoCoursePermission = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.container}>
      <div className={cls.content}>
        <Heading headingLevel="h1" headingStyle="h3" className={cls.header}>
          {t('noCoursePermission.title')}
        </Heading>
        <Paragraph className={cls.desc}>
          {t('noCoursePermission.desc')}
        </Paragraph>
        <CustomLink to={PATHS.CONTACT} className={cls.btn}>
          {t('noCoursePermission.link')}
        </CustomLink>
      </div>

      <figure className={cls.image}>
        <img src={doctorWorkingOnPcImg} alt="" />
      </figure>
    </div>
  );
};

import React, { FC, HTMLAttributes } from 'react';
import cls from './how-to-disinfect-hands.module.scss';
import { VideoEmbed } from 'components/universal/video-embed';

interface Props extends HTMLAttributes<HTMLDivElement> {
  videoLeft?: boolean;
  poster?: string;
  src: string;
  children: JSX.Element | JSX.Element[];
}

export const Section: FC<Props> = ({
  videoLeft,
  src,
  children,
  poster,
  ...props
}) => {
  return (
    <div className={cls.section} {...props}>
      {videoLeft && (
        <div className={cls.videoWrapperLeft}>
          <VideoEmbed
            controls
            src={src}
            className={cls.videoLeft}
            poster={poster}
          />
        </div>
      )}
      <div className={videoLeft ? cls.contentRight : cls.contentLeft}>
        {children}
      </div>
      {!videoLeft && (
        <div className={cls.videoWrapperRight}>
          <VideoEmbed
            controls
            src={src}
            className={cls.videoRight}
            poster={poster}
          />
        </div>
      )}
    </div>
  );
};

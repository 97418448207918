import { ReactComponent as ArrowIcon } from 'assets/images/elearning/navigation/arrow_icon.svg';
import { ReactComponent as TopicIcon } from 'assets/images/elearning/navigation/topic_icon.svg';
import clsx from 'clsx';
import Heading from 'components/universal/heading';
import { BREAKPOINTS } from 'constants/breakpoints';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Issue } from 'types/elearning';
import cls from './accordion.module.scss';
import { ListItem } from './list-item';

interface IProps {
  issue: Issue;
  className?: string;
}

export const Accordion: FC<IProps> = ({ issue, className }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'elearning.handHygiene.courseLayout.issues',
  });
  const { pathname } = useLocation();
  const isLg = useMedia(BREAKPOINTS.LG);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const toggleAccordion = () => setIsAccordionOpen((prevState) => !prevState);

  useEffect(() => setIsAccordionOpen(false), [pathname]);

  const clsArrowIcon = clsx(cls.arrowIcon, {
    [cls.arrowIconRotated]: isAccordionOpen,
  });

  const clsAccordion = clsx(cls.accordion, {
    [className]: Boolean(className),
    [cls.accordionOpen]: isAccordionOpen,
  });

  const clsListWrapper = clsx(cls.listWrapper, {
    [cls.listWrapperOpen]: isAccordionOpen,
  });

  return (
    <div className={clsAccordion}>
      {isLg ? (
        <Heading className={cls.heading} headingLevel="h3">
          {issue?.name}
        </Heading>
      ) : (
        <button
          aria-label={isAccordionOpen ? t('collapseList') : t('expandList')}
          className={cls.headingWrapper}
          onClick={toggleAccordion}
        >
          <Heading className={cls.heading} headingLevel="h3">
            {issue?.name}
          </Heading>
          <ArrowIcon className={clsArrowIcon} />
        </button>
      )}
      {issue?.topics?.length > 1 && (
        <div className={cls.paragraphWrapper}>
          <TopicIcon />
          <p className={cls.paragraph}>
            {t('topics')} <b>{issue?.topics?.length}</b>
          </p>
        </div>
      )}
      <div className={cls.line}></div>
      <div className={clsListWrapper}>
        <ul className={cls.list}>
          {issue?.topics?.map?.((topic) => (
            <Fragment key={topic?.path}>
              <ListItem topic={topic} path={issue?.path} />
            </Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};

import { PATHS } from 'constants/routes';
import { Link } from 'react-router-dom';
import { ReactComponent as BasketIcon } from 'assets/images/shop/basket.svg';
import { ReactComponent as BasketWithProductsIcon } from 'assets/images/shop/basket_with_products.svg';
import cls from './basket-link.module.scss';
import { useCart } from 'hooks/useCart';
import { useTranslation } from 'react-i18next';

export const BasketLink = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'shop',
  });
  const { numberOfAddedProducts } = useCart();

  return (
    <div className={cls.container}>
      <Link className={cls.link} to={PATHS.BASKET}>
        {numberOfAddedProducts ? <BasketWithProductsIcon /> : <BasketIcon />}
        <p className={cls.text}>
          {t('basketLink')}{' '}
          {numberOfAddedProducts ? `(${numberOfAddedProducts})` : ''}
        </p>
      </Link>
    </div>
  );
};

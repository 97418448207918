import React, { FC, HTMLAttributes } from 'react';
import cls from './downloadable-materials.module.scss';
import { Button } from 'components/universal/button';
import { useTranslation } from 'react-i18next';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  img: string;
  download: string;
}

export const Material: FC<IProps> = ({ img, download, children, ...props }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'disinfectionTechnique.downloadableMaterials',
  });
  const downloadFile = (file: string) => {
    const anchor = document.createElement('a');
    anchor.href = file;
    anchor.download = file;
    anchor.click();
  };

  return (
    <div className={cls.material} {...props}>
      <img src={img} alt="5 momentow higieny rak v1" />
      {children}
      <Button className={cls.btn} onClick={() => downloadFile(download)}>
        {t('downloadButton')}
      </Button>
    </div>
  );
};

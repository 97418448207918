import { AxiosError, AxiosResponse } from 'axios';
import { CustomHeader } from 'components/universal/custom-header';
import { Newsletter } from 'components/universal/newsletter';
import { QUERY_KEYS } from 'constants/query-keys';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getDisinfectionTechniques } from 'services/disinfection-technique';
import { DisinfectionArticle } from 'types/disinfection-technique';
import { queryKeyWithLanguage } from 'utils/language';
import { DownloadableMaterials } from 'views/disinfection-techniques/downloadable-materials';
import { HowToDisinfectHands } from 'views/disinfection-techniques/how-to-disinfect-hands';
import { UnauthorizedUser } from 'views/disinfection-techniques/unauthorized-user';
import { WhyIsHygieneImportant } from 'views/disinfection-techniques/why-is-hygiene-important';

export const DisinfectionTechniques = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'SEO.disinfectionTechniques',
  });
  const { isAuthenticated } = useUser();

  return (
    <React.Fragment>
      <CustomHeader title={t('title')} />
      {isAuthenticated ? <DataWrapper /> : <UnauthorizedUser />}
    </React.Fragment>
  );
};

const DataWrapper = () => {
  const { data } = useQuery<AxiosResponse<DisinfectionArticle[]>, AxiosError>(
    queryKeyWithLanguage(QUERY_KEYS.GET_DISINFECTION_TECHNIQUES_ARTICLES),
    getDisinfectionTechniques
  );
  return (
    <>
      <WhyIsHygieneImportant />
      <HowToDisinfectHands articles={data?.data} />
      <DownloadableMaterials />
      <Newsletter />
    </>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as Logo } from 'assets/images/registration/logo.svg';
import { AxiosError, AxiosResponse } from 'axios';
import { Button } from 'components/universal/button';
import Heading from 'components/universal/heading';
import { Input } from 'components/universal/input';
import Paragraph from 'components/universal/paragraph';
import { QUERY_KEYS } from 'constants/query-keys';
import { PATHS, ROUTES } from 'constants/routes';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUserData, signIn } from 'services/registration';
import { APIErrors } from 'types/api';
import { SignInDTO } from 'types/user';
import { queryKeyWithLanguage } from 'utils/language';
import { setToken } from 'utils/local-storage-token';
import cls from './login.module.scss';
import { schema } from './validation';

interface LocationState {
  from: string;
}
export interface ILoginFormValues {
  email: string;
  password: string;
}

export const LoginView = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const navigate = useNavigate();
  const { setUserData } = useUser();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (DTO) => signInMutation.mutate(DTO);

  const { refetch: refetchUserData } = useQuery<AxiosResponse, AxiosError>(
    queryKeyWithLanguage(QUERY_KEYS.GET_USER_DATA),
    {
      queryFn: () => getUserData(),
      enabled: false,
      onSuccess: (res) => {
        setUserData(res?.data);
      },
    }
  );

  const signInMutation = useMutation<
    AxiosResponse<{ token: string }>,
    AxiosError<APIErrors>,
    SignInDTO
  >((data) => signIn(data), {
    onSuccess: async (data) => {
      setToken(data.data.token);
      await refetchUserData();
      navigate(state?.from || PATHS.HOME);
    },
  });

  return (
    <div className={cls.container}>
      <div className={cls.columnLeft}>
        <Heading className={cls.heading} headingLevel="h1" headingStyle="h2">
          {t('logIn.title')}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="mb-6"
            register={register}
            id="email"
            name="email"
            label={t('logIn.email')}
            error={t(errors.email?.message)}
            maxLength={255}
          />
          <Input
            register={register}
            id="password"
            name="password"
            label={t('logIn.password')}
            error={t(errors.password?.message)}
            type="password"
            maxLength={255}
          />
          <Link className={cls.linkNotRemember} to={ROUTES.RESET_PASSWORD.path}>
            {t('logIn.' + ROUTES.RESET_PASSWORD.name)}
          </Link>
          <p className="mb-10">{t('logIn.requiredFields')}</p>
          <Button className="mx-auto mb-5" type="submit">
            {t('logIn.logIn')}
          </Button>
          <Paragraph className="text-center">
            {t('logIn.registerText')}{' '}
            <Link className={cls.registerLink} to={ROUTES.REGISTRATION.path}>
              {t('logIn.' + ROUTES.REGISTRATION.name)}
            </Link>
          </Paragraph>
        </form>
      </div>
      <Logo className={cls.image} />
    </div>
  );
};

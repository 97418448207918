import React from 'react';
import { SetNewPasswordView } from 'views/set-new-password';

export const SetNewPassword = () => {
  return (
    <div>
      <SetNewPasswordView />
    </div>
  );
};

import Heading from 'components/universal/heading';
import React from 'react';
import cls from './patronage.module.scss';
import medicareLogo from 'assets/images/patronage/medicare.png';
import fzLogo from 'assets/images/patronage/fz.png';
import flrLogo from 'assets/images/patronage/flr.png';
import everethLogo from 'assets/images/patronage/evereth.png';
import { useTranslation } from 'react-i18next';
import { URLS } from 'constants/external-urls';

export const PatronageSection = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.container}>
      <Heading headingLevel="h2" className={cls.header}>
        {t('home.patronage.title')}
      </Heading>
      <div className={cls.logos}>
        <a href={URLS.FORUM_ZAKAZEN} target="_blank">
          <img src={fzLogo} alt="Forum Zakażeń" className={cls.logo} />
        </a>
        <a href={URLS.MEDICARE} target="_blank">
          <img src={medicareLogo} alt="MediCare" className={cls.logo} />
        </a>
        <a href={URLS.EVERETH_NEWS} target="_blank">
          <img src={everethLogo} alt="Evereth News" className={cls.logo} />
        </a>
        <a href={URLS.FORUM_LECZENIA_RAN} target="_blank">
          <img src={flrLogo} alt="Forum Leczenia Ran" className={cls.logo} />
        </a>
      </div>
    </div>
  );
};

import general from './general/index.json';
import issues from './issues/index.json';
import overview from './overview/index.json';

const courseLayout = {
  general,
  issues,
  overview,
};

export default courseLayout;

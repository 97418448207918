import { AxiosError, AxiosResponse } from 'axios';
import Heading from 'components/universal/heading';
import { Pagination } from 'components/universal/Pagination';
import { ScrollToTop } from 'components/universal/scroll-to-top/idex';
import { BREAKPOINTS } from 'constants/breakpoints';
import { QUERY_KEYS } from 'constants/query-keys';
import { useCart } from 'hooks/useCart';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useMedia } from 'react-use';
import { getCategories, getProducts } from 'services/shop';
import { Categories, IProduct } from 'types/cart';
import { IPagination } from 'types/pagination';
import { queryKeyWithLanguage } from 'utils/language';
import { BasketLink } from './basket-link';
import { Filters } from './filters';
import { FiltersDesktop } from './filters-desktop';
import { Product } from './product';
import cls from './shop.module.scss';

export const ShopView = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'shop',
  });
  const isLg = useMedia(BREAKPOINTS.LG);
  const isMd = useMedia(BREAKPOINTS.MD);
  const [page, setPage] = useState(1);
  const { cart, setCart } = useCart();
  const CATEGORY_ALL = t('categoryAll');
  const [currentCategory, setCurrentCategory] = useState(CATEGORY_ALL);

  const productsPerPage = () => {
    // desktop
    if (isMd && isLg) {
      return 16;
    }
    // tablet
    else if (isMd && !isLg) {
      return 15;
    }
    // mobile
    else {
      return 16;
    }
  };

  const { data: productsData } = useQuery<
    AxiosResponse<IPagination<IProduct[]>>,
    AxiosError
  >([queryKeyWithLanguage(QUERY_KEYS.PROCUCSTS), page, currentCategory], () =>
    getProducts({
      page,
      per_page: productsPerPage(),
      filter: {
        ...(currentCategory !== CATEGORY_ALL && {
          category_name: [currentCategory],
        }),
      },
    })
  );

  const { data: categoriesData } = useQuery<
    AxiosResponse<Categories>,
    AxiosError
  >(queryKeyWithLanguage(QUERY_KEYS.FILTERS), () => getCategories());

  const handleChangeCategory = (name: string) => {
    setCurrentCategory(name);
  };

  const handleSubmit = (quantity: number, product: IProduct) => {
    const index = cart.findIndex((item) => item?.product?.id === product?.id);

    if (quantity > 0) {
      if (index === -1) {
        setCart((previousCart) => {
          return [...previousCart, { product, quantity }];
        });
      } else {
        const newCart = [...cart];
        newCart[index].quantity =
          Number(newCart[index].quantity) + Number(quantity);
        setCart(newCart);
      }

      toast.info(t('addToCardSuccess'));
    } else {
      toast.error(t('addToCardError'));
    }
  };

  const categoriesDesktop = [
    { name: CATEGORY_ALL },
    ...(categoriesData?.data || []),
  ];

  const categories = categoriesDesktop?.filter?.(
    (category) => category?.name !== currentCategory
  );

  return (
    <div className={cls.content}>
      <Heading className={cls.heading} headingLevel="h1" headingStyle="h2">
        {t('title')}
      </Heading>

      <div className={cls.actionBar}>
        {isLg ? (
          <FiltersDesktop
            categories={categoriesDesktop}
            handleChangeCategory={handleChangeCategory}
            currentCategory={currentCategory}
          />
        ) : (
          <Filters
            categories={categories}
            handleChangeCategory={handleChangeCategory}
            currentCategory={currentCategory}
          />
        )}
        <div className="ml-2">
          <BasketLink />
        </div>
      </div>
      <div className={cls.shop}>
        {productsData?.data &&
          productsData?.data?.data?.map?.((product, id) => (
            <Fragment key={`${product?.id}-${id}`}>
              <Product
                product={product}
                handleSubmit={(quantity, product) =>
                  handleSubmit(quantity, product)
                }
              />
            </Fragment>
          ))}
      </div>
      <div className={cls.pagination}>
        <Pagination
          pageCount={productsData?.data?.last_page}
          onPageChange={({ selected }) => setPage(selected + 1)}
          forcePage={page - 1}
        />
      </div>
      <ScrollToTop className={cls.scrollToTop} />
    </div>
  );
};

const BACK = process.env.REACT_APP_API_URL;

export const VIDEOS = {
  PL: {
    HOME_ABOUT_ACTION: `${BACK}/file/stream/home/pl/about_video.mp4`,
    KNOWLEDGE_BASE_INTERVIEW: `${BACK}/file/stream/knowledge/interview/pl_video.mp4`,
    ELEARNING: {
      SCENARIO1: `${BACK}/file/stream/disinfection_technique/video_2.mp4`,
      SCENARIO2: `${BACK}/file/stream/disinfection_technique/video_3.mp4`,
      SCENARIO3: `${BACK}/file/stream/disinfection_technique/video_4.mp4`,
      SCENARIO4: `${BACK}/file/stream/disinfection_technique/video_5.mp4`,
      PROPER_HAND_DISINFECTION: `${BACK}/file/stream/elearning/task_4/animation.mp4`,
      PROPER_HAND_WASHING: `${BACK}/file/stream/elearning/task_5/animation.mp4`,
    },
  },
  ENG: {
    HOME_ABOUT_ACTION: `${BACK}/file/stream/home/en/about_video.mp4`,
    KNOWLEDGE_BASE_INTERVIEW: `${BACK}/file/stream/knowledge/interview/pl_video.mp4`,
    ELEARNING: {
      SCENARIO1: `${BACK}/file/stream/disinfection_technique/video_7.mp4`,
      SCENARIO2: `${BACK}/file/stream/disinfection_technique/video_8.mp4`,
      SCENARIO3: `${BACK}/file/stream/disinfection_technique/video_9.mp4`,
      SCENARIO4: `${BACK}/file/stream/disinfection_technique/video_10.mp4`,
      PROPER_HAND_DISINFECTION: `${BACK}/file/stream/elearning/task_18/animation.mp4`,
      PROPER_HAND_WASHING: `${BACK}/file/stream/elearning/task_19/animation.mp4`,
    },
  },
};

export const THUMBNAILS = {
  PL: {
    HOME_ABOUT_ACTION: `${BACK}/file/download/home/pl/about_thumbnail.png`,
    KNOWLEDGE_BASE_INTERVIEW: `${BACK}/file/stream/knowledge/interview/pl_thumbnail.png`,
    ELEARNING: {
      SCENARIO1: `${BACK}/file/download/disinfection_technique/thumbnail_2.png`,
      SCENARIO2: `${BACK}/file/download/disinfection_technique/thumbnail_3.png`,
      SCENARIO3: `${BACK}/file/download/disinfection_technique/thumbnail_4.png`,
      SCENARIO4: `${BACK}/file/download/disinfection_technique/thumbnail_5.png`,
      PROPER_HAND_DISINFECTION: `${BACK}/file/download/elearning/task_4/thumbnail.jpg`,
      PROPER_HAND_WASHING: `${BACK}/file/download/elearning/task_5/thumbnail.jpg`,
    },
  },
  ENG: {
    HOME_ABOUT_ACTION: `${BACK}/file/download/home/en/about_thumbnail.png`,
    KNOWLEDGE_BASE_INTERVIEW: `${BACK}/file/stream/knowledge/interview/pl_thumbnail.png`,
    ELEARNING: {
      SCENARIO1: `${BACK}/file/download/disinfection_technique/thumbnail_7.png`,
      SCENARIO2: `${BACK}/file/download/disinfection_technique/thumbnail_8.png`,
      SCENARIO3: `${BACK}/file/download/disinfection_technique/thumbnail_9.png`,
      SCENARIO4: `${BACK}/file/download/disinfection_technique/thumbnail_10.png`,
      PROPER_HAND_DISINFECTION: `${BACK}/file/download/elearning/task_18/thumbnail.jpg`,
      PROPER_HAND_WASHING: `${BACK}/file/download/elearning/task_19/thumbnail.jpg`,
    },
  },
};

export const URLS = {
  ECOLAB: 'https://pl-pl.ecolab.com/',
  SHL: 'https://shl.org.pl/',
  SES: 'http://www.ses.edu.pl/',
  PSPE: 'https://pspe.pl/',
  PSSM: 'http://sterylizacja.org.pl/',
  MEDICARE: 'http://www.medicare.waw.pl/#o-nas',
  FORUM_ZAKAZEN: 'https://evereth.pl/czasopisma/forum-zakazen/',
  EVERETH_NEWS: 'https://everethnews.pl/',
  FORUM_LECZENIA_RAN: 'https://forumleczeniaran.pl/',
  CZYSTE_LECZENIE: 'https://czysteleczenie.pl/',
};

import { ERROR_MESSAGES } from 'constants/validation-error-messages';
import * as yup from 'yup';

export const schema = yup.object({
  first_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  last_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  email: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.EMAIL),
  phone: yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(9, ERROR_MESSAGES.PHONE_LENGHT),
  delivery_workplace_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  delivery_address_street: yup.string().required(ERROR_MESSAGES.REQUIRED),
  delivery_address_number: yup.string().required(ERROR_MESSAGES.REQUIRED),
  delivery_address_city: yup.string().required(ERROR_MESSAGES.REQUIRED),
  delivery_address_postcode: yup.string().required(ERROR_MESSAGES.REQUIRED),
  checkbox_a: yup.boolean().oneOf([true], ERROR_MESSAGES.REQUIRED),
  checkbox_b: yup.boolean().oneOf([true], ERROR_MESSAGES.REQUIRED),
  checkbox_c: yup.boolean().oneOf([true], ERROR_MESSAGES.REQUIRED),
  checkbox_d: yup.boolean().oneOf([true], ERROR_MESSAGES.REQUIRED),
});

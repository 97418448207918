import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import variables from 'styles/_exports.module.scss';

type Props = {
  courseProgress: number;
};

export const Circle = ({ courseProgress }: Props) => {
  return (
    <CircularProgressbar
      value={courseProgress}
      minValue={0}
      maxValue={100}
      text={`${courseProgress}%`}
      styles={{
        text: {
          fill: variables['color-blue-pickled'],
          fontWeight: 900,
          fontSize: 16,
        },
        path: {
          strokeLinecap: 'butt',
          stroke:
            courseProgress === 100
              ? variables['color-green']
              : variables['color-blue'],
        },
        trail: {
          stroke: variables['color-blue-light'],
        },
      }}
    />
  );
};

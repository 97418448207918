import { ReactComponent as StatusFailedIcon } from 'assets/images/elearning/topic-status/status_failed.svg';
import { ReactComponent as StatusSuccessIcon } from 'assets/images/elearning/topic-status/status_success.svg';
import clsx from 'clsx';
import { Button } from 'components/universal/button';
import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cls from './topic-status.module.scss';

type Title =
  | 'Temat ukończony!'
  | 'Nie udało się'
  | 'Szkolenie ukończone!'
  | 'QUIZ ukończony!'
  | 'Ten temat jest jeszcze zablokowany';
type ButtonText =
  | 'PRZEJDŹ DO KOLEJNEGO TEMATU'
  | 'SPRÓBUJ PONOWNIE'
  | 'ROZPOCZNIJ QUIZ'
  | 'ZAKOŃCZ SZKOLENIE'
  | 'PRZEJDŹ DO AKTUALNEGO TEMATU';
interface IProps {
  title: Title;
  description?: string;
  buttonText: ButtonText;
  obtainedPoints?: number;
  maxScore?: number;
  isSuccess?: boolean;
  isStandalone?: boolean;
  isHideStatusIcon?: boolean;
  onSubmit: () => void;
  className?: string;
}

export const TopicStatus: FC<IProps> = ({
  title,
  description,
  buttonText,
  obtainedPoints,
  maxScore,
  isSuccess,
  isHideStatusIcon,
  isStandalone = false,
  onSubmit,
  className,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'components.topicStatusWithButton',
  });
  const clsWrapper = clsx(cls.wrapper, {
    [className]: Boolean(className),
  });

  const clsContainer = clsx(cls.container, {
    [cls.containerStandalone]: isStandalone,
  });

  return (
    <div className={clsContainer}>
      <div className={cls.line}></div>
      <div className={clsWrapper}>
        {!isHideStatusIcon && (
          <div className={cls.iconWrapper}>
            {isSuccess ? <StatusSuccessIcon /> : <StatusFailedIcon />}
          </div>
        )}
        <div className={cls.infoWrapper}>
          <Heading className={cls.heading} headingLevel="h3">
            {title}
          </Heading>
          <Paragraph className={cls.paragraph}>
            {description ? (
              description
            ) : (
              <>
                {t('correctAnswerText1')}{' '}
                <b>
                  {obtainedPoints}/{maxScore}
                </b>{' '}
                {t('correctAnswerText2')}
              </>
            )}
          </Paragraph>
          <Button onClick={onSubmit}>{buttonText}</Button>
        </div>
      </div>
    </div>
  );
};

import { TasksGenerator } from 'components/elearning/tasks/tasks-generator';
import { TopicStatusWithButton } from 'components/elearning/topic-status-with-button';
import Heading from 'components/universal/heading';
import { VideoEmbed } from 'components/universal/video-embed';
import { THUMBNAILS, VIDEOS } from 'constants/external-urls';
import { useTopic } from 'hooks/useTopic';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getMediaLanguage } from 'utils/language';

export const ProperHandWashing = () => {
  const { t, i18n } = useTranslation('', {
    keyPrefix:
      'elearning.handHygiene.handDisinfectionAndWashing.properHandWashing',
  });
  const { tasks, correctAnswer, isAnswerChecked } = useTopic();

  return (
    <div>
      <Heading className="mb-5" headingLevel="h1" headingStyle="h3">
        {t('title1')}
      </Heading>
      <VideoEmbed
        src={
          VIDEOS[getMediaLanguage(i18n.language)].ELEARNING.PROPER_HAND_WASHING
        }
        controls
        poster={
          THUMBNAILS[getMediaLanguage(i18n.language)].ELEARNING
            .PROPER_HAND_WASHING
        }
      />
      <div className="divider" />
      <Heading className="mb-10" headingLevel="h3">
        {t('title2')}
      </Heading>
      <TasksGenerator
        tasks={tasks}
        isChecked={isAnswerChecked}
        correctAnswer={correctAnswer}
      />
      <TopicStatusWithButton />
    </div>
  );
};

import React, { useRef, useState } from 'react';
import cls from './carousel.module.scss';
import ImageGallery, {
  ReactImageGalleryItem,
  ReactImageGalleryProps,
} from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Controls } from './Controls';
import { YoutubeEmbed } from '../youtube-embed';
import clsx from 'clsx';

export interface CarouselItem extends ReactImageGalleryItem {
  embedUrl?: string;
}

interface IProps extends ReactImageGalleryProps {
  items: CarouselItem[];
  className?: string;
}

export const Carousel = ({ className, ...props }: IProps) => {
  const [scrollY, setScrollY] = useState(0);

  const items = props.items.map((slide): ReactImageGalleryItem => {
    if (slide.embedUrl) {
      return {
        ...slide,
        renderItem: (el) => <YoutubeEmbed src={slide.embedUrl} {...el} />,
      };
    }
    return slide;
  });

  const galleryRef = useRef(null);

  const clsCarousel = clsx(cls.carousel, {
    [className]: Boolean(className),
  });

  const handleFullscreen = (e: boolean) => {
    if (e) {
      screen.orientation.lock('landscape');
      const posY = window.scrollY;
      setScrollY(posY);
    } else {
      screen.orientation.unlock();
      window.scrollTo(0, scrollY);
    }
  };

  return (
    <ImageGallery
      additionalClass={clsCarousel}
      ref={galleryRef}
      items={items}
      showThumbnails={false}
      showPlayButton={false}
      showFullscreenButton={false}
      showBullets={false}
      showIndex={false}
      showNav={false}
      onClick={() => galleryRef.current?.toggleFullScreen()}
      onScreenChange={handleFullscreen}
      renderCustomControls={() => (
        <Controls
          slideLeft={galleryRef.current?.slideLeft}
          slideRight={galleryRef.current?.slideRight}
          toggleFullScreen={galleryRef.current?.toggleFullScreen}
          state={galleryRef.current?.state}
          props={galleryRef.current?.props}
          showFullScreen={props.showFullscreenButton}
          showIndex={props.showIndex}
          showNav={props.showNav}
        />
      )}
    />
  );
};

import { AccordionGeneral } from 'components/elearning/navigation/accordion-general';
import { SingleCourseElement } from 'components/elearning/single-course-element';
import { Box } from 'components/universal/box';
import Heading from 'components/universal/heading';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Course } from 'types/elearning';
import cls from './issues.module.scss';

export interface IProps {
  courseData: Course;
}

export const Issues: FC<IProps> = ({ courseData }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'elearning.handHygiene.courseLayout.issues',
  });

  return (
    <div className={cls.wrapper}>
      <div className={cls.container}>
        <Heading headingLevel="h3" className="mb-5">
          {t('title')}
        </Heading>
        {courseData?.issues?.map?.((issue, id) => (
          <Fragment key={`${issue?.path}-${id}`}>
            {issue?.name ? (
              <Box className={cls.box}>
                <AccordionGeneral issue={issue} />
              </Box>
            ) : (
              <SingleCourseElement issue={issue} />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

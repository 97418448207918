import React, { useCallback, useEffect, useRef, useState } from 'react';
import cls from './map.module.scss';
import Heading from 'components/universal/heading';
import { HospitalMap } from '../hospital-map';
import { PrivatePlacesMap } from '../private-places-map';
import { Switch } from 'components/universal/switch';
import {
  hospitalMapData,
  hospitalAreas,
  defaultData,
} from '../hospital-map/areas';
import { privateAreas, privateMapData } from '../private-places-map/areas';
import { Tooltip } from 'views/contact/tooltip';
import Paragraph from 'components/universal/paragraph';
import { useTranslation } from 'react-i18next';

export const PlacesMap = () => {
  const [mapCheckbox, setMapCheckbox] = useState(true);
  const [active, setActive] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tolltipData, setTooltipData] = useState(defaultData);

  const activeStateRef = useRef({ active, fresh: false });
  const { t } = useTranslation();

  const getAreas = useCallback(() => {
    if (mapCheckbox)
      return privateAreas.map((area) => document.querySelectorAll(area));
    return hospitalAreas.map((area) => document.querySelectorAll(area));
  }, [mapCheckbox]);

  useEffect(() => {
    setActive('');
    activeStateRef.current.active = '';
    setTooltipData(defaultData);
    const areas = getAreas();
    areas.forEach((area) => {
      area.forEach((element: HTMLElement) => {
        const className = Array.from(element.classList)
          .filter((c) => c !== 'circle-pin' && c !== 'circle-pin-shadow')
          .toString();
        element.addEventListener('click', () => {
          if (activeStateRef.current.active === className) {
            setActive('');
            return (activeStateRef.current = { active: '', fresh: false });
          }
          setActive(className);
          activeStateRef.current = { active: className, fresh: true };
          if (mapCheckbox)
            setTooltipData(privateMapData[className] || defaultData);
          else setTooltipData(hospitalMapData[className] || defaultData);
        });
        element.childNodes.forEach(
          (c: HTMLElement) => (c.style.transition = '0.2s')
        );
        element.style.cursor = 'pointer';
      });
    });
  }, [mapCheckbox]);

  useEffect(() => {
    const resetActive = (e: MouseEvent) => {
      const el = e.target as HTMLElement;
      if (!activeStateRef.current.active) return;
      if (activeStateRef.current.fresh)
        return (activeStateRef.current.fresh = false);
      if (el.classList.contains('tooltip')) {
        return;
      }
      setActive('');
      activeStateRef.current = { active: '', fresh: false };
    };
    window.addEventListener('click', resetActive);
    return () => {
      window.removeEventListener('click', resetActive);
    };
  }, []);

  // deactivate tooltip when scrolling and resizing on desktop, ignore on mobile
  useEffect(() => {
    const resize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 1024) return;
      setActive('');
      activeStateRef.current = { active: '', fresh: false };
    };
    window.addEventListener('resize', resize);
    window.addEventListener('scroll', resize);
    return () => {
      window.removeEventListener('resize', resize);
      window.removeEventListener('scroll', resize);
    };
  }, []);

  useEffect(() => {
    const areas = getAreas();
    areas.forEach((area) => {
      const g = area.item(0);
      const elements = Array.from(g.childNodes);
      if (elements.length === 0) return;
      const circle = elements.find((el: HTMLElement) =>
        el.classList.contains('circle-pin')
      ) as SVGCircleElement;
      const shadow = elements.find((el: HTMLElement) =>
        el.classList.contains('circle-pin-shadow')
      ) as SVGCircleElement;
      if (g.classList.contains(active)) {
        circle.setAttribute('r', '12.0242');
        shadow.setAttribute('r', '17');

        // i use getBoundingClientRect(), because when map is being scaled down with browser width
        // circle.cx position isnt scaled down and i always get the same position - map scaled and max size(555px)
        const pos = circle.getBoundingClientRect();
        const offset = Math.floor((pos.width - 13) / 2);
        setTooltipPosition({
          x: Math.round(pos.x) + offset,
          y: Math.round(pos.y) + offset,
        });
      } else {
        circle.setAttribute('r', '6.55865');
        shadow.setAttribute('r', '12.0242');
      }
    });
  }, [active]);

  return (
    <div className={cls.container}>
      <Heading headingLevel="h1" headingStyle="h3" className={cls.header}>
        {t('contact.map.title')}
      </Heading>
      <Switch
        contentLeft={t('contact.map.forPrivatePlaces')}
        contentRight={t('contact.map.forHospitals')}
        onChange={() => setMapCheckbox(!mapCheckbox)}
        checked={mapCheckbox}
        className="my-5 lg:my-10"
      />
      <div className={cls.tooltipMobile}>
        <Tooltip
          x={tooltipPosition.x}
          y={tooltipPosition.y}
          visible={active !== ''}
          className="mb-5 mx-auto tooltip"
        >
          <Paragraph fontWeight={700} className="tooltip">
            {active ? tolltipData.title : defaultData.title}
          </Paragraph>
          <Paragraph className="tooltip">
            <a href={`tel:${tolltipData.phone}`}>
              {active ? tolltipData.phone : defaultData.phone}
            </a>
          </Paragraph>
          <Paragraph className="tooltip">
            <a
              className={`${cls.tooltipLink} tooltip`}
              href={`mailto:${tolltipData.email}`}
            >
              {active ? tolltipData.email : defaultData.email}
            </a>
          </Paragraph>
          <Paragraph className="tooltip">
            {!active && t(defaultData.tooltip)}
          </Paragraph>
        </Tooltip>
      </div>
      <div className={cls.tooltip}>
        <Tooltip
          x={tooltipPosition.x}
          y={tooltipPosition.y}
          visible={active !== ''}
          className="mb-5 mx-auto tooltip"
        >
          <Paragraph fontWeight={700} className="tooltip">
            {tolltipData.title}
          </Paragraph>
          <Paragraph className="tooltip">
            <a href={`tel:${tolltipData.phone}`}>{tolltipData.phone}</a>
          </Paragraph>
          <Paragraph className="tooltip">
            <a
              className={`${cls.tooltipLink} tooltip`}
              href={`mailto:${tolltipData.email}`}
            >
              {tolltipData.email}
            </a>
          </Paragraph>
        </Tooltip>
      </div>
      {mapCheckbox ? <PrivatePlacesMap /> : <HospitalMap />}
    </div>
  );
};

export const privateAreas = [
  '.gray-area',
  '.blue-top-area',
  '.purple-area',
  '.pink-top-area',
  '.green-left-area',
  '.dark-green-left-area',
  '.pink-middle-area',
  '.yellow-area',
  '.dark-green-middle-area',
  '.orange-middle-area',
  '.green-middle-area',
  '.brown-area',
  '.blue-left-area',
  '.orange-bottom-area',
  '.blue-bottom-area',
  '.aqua-area',
];

export const privateMapData = {
  'gray-area': {
    title: 'Paweł Jagoda',
    phone: '+48 885 563 348',
    email: 'pawel.jagoda@ecolab.com',
  },
  'blue-top-area': {
    title: 'Arkadiusz Sondej',
    phone: '+48 669 990 177',
    email: 'arkadiusz.sondej@ecolab.com',
  },
  'purple-area': {
    title: 'Jakub Ochendal',
    phone: '+48 509 462 174',
    email: 'jakub.ochendal@ecolab.com',
  },
  'pink-top-area': {
    title: 'Karol Krośniewski',
    phone: '+48 501 332 885',
    email: 'karol.krosniewski@ecolab.com',
  },
  'green-left-area': {
    title: 'Bartłomiej Metler',
    phone: '+48 885 552 804',
    email: 'bartlomiej.metler@ecolab.com',
  },
  'dark-green-left-area': {
    title: 'Maciej Jagoda',
    phone: '+48 509 601 559',
    email: 'maciej.jagoda@ecolab.com',
  },
  'pink-middle-area': {
    title: 'Paweł Wietrzych',
    phone: '+48 691 111 968',
    email: 'pawel.wietrzych@ecolab.com',
  },
  'yellow-area': {
    title: 'Paweł Markut',
    phone: '+48 501 332 737',
    email: 'pawel.markut@ecolab.com',
  },
  'dark-green-middle-area': {
    title: 'Rafał Olborski',
    phone: '+48 502 525 231',
    email: 'rafal.olborski@ecolab.com',
  },
  'orange-middle-area': {
    title: 'Mateusz Kaczmarczyk',
    phone: '+48 500 224 206',
    email: 'mateusz.kaczmarczyk@ecolab.com',
  },
  'green-middle-area': {
    title: 'Angelika Kołda',
    phone: '+48 885 563 731',
    email: 'angelika.kolda@ecolab.com',
  },
  'brown-area': {
    title: 'Anna Czajkowska',
    phone: '+48 501 332 870',
    email: 'anna.czajowska@ecolab.com',
  },
  'blue-left-area': {
    title: 'Michał Opioła',
    phone: '+48 507 142 992',
    email: 'michal.opiola@ecolab.com',
  },
  'orange-bottom-area': {
    title: 'Karolina Wardowska',
    phone: '+48 501 332 797',
    email: 'karolina.wardowska@ecolab.com',
  },
  'blue-bottom-area': {
    title: 'Szczepan Mistarz',
    phone: '+48 510 293 353',
    email: 'szczepan.mistarz@ecolab.com',
  },
  'aqua-area': {
    title: 'Ireneusz Kasia',
    phone: '+48 885 852 964',
    email: 'ireneusz.kasia@ecolab.com',
  },
};

import clsx from 'clsx';
import React, { FC, InputHTMLAttributes } from 'react';
import cls from './switch.module.scss';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  contentLeft?: string;
  contentRight?: string;
}

export const Switch: FC<IProps> = ({
  contentLeft,
  contentRight,
  checked,
  className,
  ...props
}) => {
  const clsSwitch = clsx(cls.switch, {
    [cls.switchChecked]: checked,
    [className]: Boolean(className),
  });

  return (
    <label className={clsSwitch}>
      <input className={cls.checkbox} type="checkbox" {...props} />
      <span className={cls.left}>{contentLeft}</span>
      <span className={cls.right}>{contentRight}</span>
    </label>
  );
};

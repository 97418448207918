import { Button } from 'components/universal/button';
import Paragraph from 'components/universal/paragraph';
import { Radio } from 'components/universal/radio';
import { QuizData } from 'copy/home/hygiene-quiz';
import React, { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import cls from './quiz.module.scss';

type IProps = {
  data: QuizData;
  step: string;
  checked: string;
  setChecked: React.Dispatch<SetStateAction<string>>;
  changeQuestion: (val: number) => void;
};

const Questions: React.FC<IProps> = ({
  data,
  step,
  checked,
  setChecked,
  changeQuestion,
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Paragraph className={cls.question}>
        {t(data.questions[step].question)}
      </Paragraph>
      {data.questions[step].answers.map((answer: string) => (
        <Radio
          id={answer}
          name="quiz-answer"
          value={answer}
          onChange={(e) => setChecked(e?.target?.value)}
          checked={checked === answer}
          key={answer}
          className={cls.answer}
        >
          {t(answer)}
        </Radio>
      ))}
      <Buttons
        step={step}
        changeQuestion={changeQuestion}
        checked={checked}
        data={data}
      />
    </React.Fragment>
  );
};

const Buttons = ({ step, changeQuestion, checked, data }) => {
  const { t } = useTranslation();
  return (
    <div className={cls.buttons}>
      {step !== Object.keys(data.questions)[0] && (
        <Button
          styleType="tertiary"
          className={cls.btn}
          onClick={() => changeQuestion(-1)}
        >
          {t('home.quiz.prev')}
        </Button>
      )}

      <Button
        className={cls.btn}
        onClick={() => changeQuestion(1)}
        disabled={checked === null}
      >
        {t('home.quiz.next')}
      </Button>
    </div>
  );
};

export default Questions;

import { CustomHeader } from 'components/universal/custom-header';
import PrivateComponent from 'features/private-component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GenerateCertificateView } from 'views/generate-certificate';

export const GenerateCertificate = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'SEO.generateCertificate',
  });

  return (
    <PrivateComponent permission="premium">
      <CustomHeader title={t('title')} />
      <GenerateCertificateView />
    </PrivateComponent>
  );
};

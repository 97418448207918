import clsx from 'clsx';
import React, { FC } from 'react';
import cls from './heading.module.scss';

export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4';
export type HeadingStyle = HeadingType;

type IProps = React.HTMLAttributes<HTMLHeadingElement> & {
  headingLevel: HeadingType;
  headingStyle?: HeadingType;
  isWhite?: boolean;
  fontWeight?: 700 | 800 | 900;
  className?: string;
};

const Heading: FC<IProps> = ({
  headingLevel,
  headingStyle,
  isWhite = false,
  fontWeight,
  children,
  className,
  ...props
}) => {
  const HeadingElement = headingLevel;
  const styleType = headingStyle || headingLevel;
  const clsHeading = clsx(cls.heading, cls[styleType], {
    [cls.headingWhite]: isWhite,
    [className]: Boolean(className),
  });

  return (
    <HeadingElement style={{ fontWeight }} className={clsHeading} {...props}>
      {children}
    </HeadingElement>
  );
};

export default Heading;

import Heading from 'components/universal/heading';
import Paragraph from 'components/universal/paragraph';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleType } from 'types/knowledge-base';
import { Article } from '../articles/article/Article';
import cls from './case-study.module.scss';

interface IProps {
  data?: ArticleType[];
}

export const CaseStudy = ({ data }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={cls.wrapper}>
      <div className={cls.container}>
        <Heading headingLevel="h2" className={cls.header}>
          {t('knowledgeBase.caseStudy.title')}
        </Heading>
        <Paragraph className={cls.desc}>
          {t('knowledgeBase.caseStudy.desc')}
        </Paragraph>
        {data?.map((a, i) => (
          <Article item={a} key={a.title + i} />
        ))}
      </div>
    </div>
  );
};
